import React from 'react';

export default React.createContext({
    user: {},
    customers: [],
    profile: {},
    tariffs: [],
    berths: [],
    pedestals: [],
    utilities: [],
    services: [],
    logout: () => {},
    customersLoad: (payload) => {},
    customerAdd: (payload) => {},
    customerUpdate: (payload) => {},
    toggleGlobalPanel: () => {},
    showMenu: () => {},
    hideMenu: () => {},
    showSnack: () => {},
})