import React, { useContext, useState } from 'react';
import {makeStyles, useTheme, Button, CircularProgress} from '@material-ui/core';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import globalContext from '../context/globalContext';
import PedestalAdd from '../content/pedestalAdd';
import SideSlidePanel from '../components/sideSlidePanel';
import PedestalEdit from '../content/pedestalEdit';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const Pedestals = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const context = useContext(globalContext);

  const [ showAdd, setShowAdd ] = useState(false);
  const [ edit, setEdit ] = useState(null);

  const headCells = [
    'id_pedestal',
    'pedestal_reference',
    'pedestal_type',
    'map_reference'
  ]

  const handleRowClick = row => {
    setEdit(row);
  }

  return (
    <div className={classes.container}>

      <PageToolbar label="Pedestals">
        <div style={{flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20}}>
          <div>
            <Button variant="contained" color="default" style={{alignSelf: 'flex-start'}} onClick={() => setShowAdd(true)}>Add Pedestal</Button>
          </div>
        </div>
      </PageToolbar>


      {context.pedestals.status === 'LOADING' && <CircularProgress />}
      <AutoTable rows={context.pedestals.data} orderBy="id_pedestal" handleRowClick={handleRowClick} headCells={headCells} />

      
      <SideSlidePanel title="Add Pedestal" side="left" show={showAdd} handleClose={() => {setShowAdd(false)}}><PedestalAdd handleClose={() => {setShowAdd(false)}} /></SideSlidePanel>
      <SideSlidePanel title="Manage Pedestal" side="right" show={edit !== null} handleClose={() => {setEdit(null)}}><PedestalEdit data={edit} handleClose={() => {setEdit(null)}} /></SideSlidePanel>
      

    </div>
  );
}

export default Pedestals;
