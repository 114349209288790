import { CircularProgress, IconButton, isWidthDown, makeStyles, useTheme } from '@material-ui/core';
import {useContext, useEffect, useRef, useState} from 'react';
import SvgMap from '../components/svgMap';
import { renderer } from '../global/marinaMapRenderer';
import GlobalContext from '../context/globalContext';
import clsx from 'clsx';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        width: '100%', 
        height: '100%',
        backgroundColor: '#e2e2e2',
        overflow: 'hidden',
        position: 'relative',
        border: '1px solid #a8a8a8'
    },
    fullScreen: {
        position: 'fixed',
        zIndex: 887,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    fullScreenIcon: {
        position: 'absolute',
        zIndex: 886,
        top: 20,
        right: 20
    },
    map: {
        position: 'absolute',
        zIndex: 700,
        minWidth: '100%',
        minHeight: '100%'
    },
    buttons: {
        position: 'absolute',
        right: 20,
        top: 20,
        zIndex: 800,
        fontSize: 20,
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        backgroundColor: '#444',
        cursor: 'pointer'
    },
    loading: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 701,
        backgroundColor: 'rgba(255,255,255,.4)',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dot: {
        display: 'block', 
        position: 'absolute', 
        zIndex: 750, 
        backgroundColor: 'red', 
        borderRadius: '50%', 
        width: 6, 
        height: 6,
        left: '50%', 
        top: '50%', 
        transform: 'translate(-50%, -50%)',
        opacity: .5
    },
    dotGreen: {
        display: 'block', 
        position: 'absolute', 
        zIndex: 750, 
        backgroundColor: 'green', 
        borderRadius: '50%', 
        width: 6, 
        height: 6,
        left: '50%', 
        top: '50%', 
        transform: 'translate(-50%, -50%)'
    }
}))


let instance = null;
let clickCountTimeout;

const MarinaMap = props => {

    const theme = useTheme();
    const classes = useStyles(theme);

    const context = useContext(GlobalContext);

    const [fullScreen, setFullScreen] = useState(false);

    const [pedestals, setPedestals] = useState([]);
    const [berths, setBerths] = useState([]);
    const [map, setMap] = useState(null);


    /*
    *
    *   All the changable properties
    *
    */   
    const mapContainer = useRef(null);
    const mapItem = useRef(null);

    let dragActive = false;
    let wasDragged = false;
    let currentX;
    let currentY;
    let initialX;
    let initialY;
    let pointerCache = [];
    let pointerPrevDiff = -1;
    let clickCount = [];





    /*
    *
    *   Detecting the pan and pinch gesture events
    *
    */

    useEffect(() => {

        //console.log('map item change');

        //
        //  The container the map sits in
        //
        const mc = mapContainer.current;

        //  
        //  The map contains an SVG with interactive elements handled elsewhere
        //
        const mi = mapItem.current;
        

        //
        //  If we have reference to the DOM element
        //
        if(typeof mc !== 'undefined'){

            /*
            *   Drag the map
            */
            mc.addEventListener('touchstart', handleDragStart, false);
            mc.addEventListener('touchend', handleDragEnd, false);
            mc.addEventListener('touchmove', handleDrag, false);

            mc.addEventListener('mousedown', handleDragStart, false);
            mc.addEventListener('mouseup', handleDragEnd, false);
            mc.addEventListener('mouseleave', handleDragEnd, false);
            mc.addEventListener('mousemove', handleDrag, false);

            /*
            *   Use the mouse wheel
            */
            mc.addEventListener('wheel', handleWheel, false);


            /*
            *   Pinch gesture
            */
            mc.addEventListener('pointerdown', handlePointerDown, false);
            mc.addEventListener('pointermove', handlePointerMove, false);
            mc.addEventListener('pointerup', handlePointerUp, false);
            mc.addEventListener('pointercancel', handlePointerUp, false);
            mc.addEventListener('pointerout', handlePointerUp, false);
            mc.addEventListener('pointerleave', handlePointerUp, false);
        }


        //
        //  Create an instance of a rendered map
        //
        if(typeof mi !== 'undefined' && mi !== null && map !== null) {

            // console.log(`Map is ${map}`);
            // console.log(`Map container: w: ${mc.offsetWidth} h: ${mc.offsetHeight}`);
            // console.log(`Map item: w: ${mi.offsetWidth} h: ${mi.offsetHeight}`);


            //
            //  The minimum scale is determined by the 
            //  size of the container to the map inside
            //  and the higher minimum will be determined
            //  by the orientation of the map or container 
            //
            const minScaleX = mc.offsetWidth/mi.offsetWidth;
            const minScaleY = mc.offsetHeight/mi.offsetHeight;
            const minScale = minScaleX < minScaleY ? minScaleY : minScaleX;
            //console.log(`minScale: ${minScale}`)

            //
            //  Delta Scale on load is 5, scaleSensitivity is 10
            //  So the Map will scale to 1.5 times is original
            //  size on load so to center the map on load we need the middle
            //  point of the newly scaled size
            //
            // const defaultOriginX = (mi.offsetWidth*1.5)/2;
            // const defaultOriginY = (mi.offsetHeight*1.5)/2;
            let defaultOriginX = mi.offsetWidth/2;
            let defaultOriginY = mi.offsetHeight/2;
            let translateX = -Math.abs((mi.offsetWidth-mc.offsetWidth)/2);
            let translateY = -Math.abs((mi.offsetHeight-mc.offsetHeight)/2);


            // console.log(`Default origin x: ${defaultOriginX} y: ${defaultOriginY}`);
            // console.log(`Translate x: ${translateX} y: ${translateY}`);

            const a = Math.floor(minScale * 10);
            let ds = a - (7 - (a - 3));


            

            if(context.profile.status === 'OK' && typeof context.profile.data.id_organisation !== 'undefined'){
                if(context.profile.data.map_boundary_top_x !== null && context.profile.data.map_boundary_top_y !== null && context.profile.data.map_boundary_bottom_x !== null && context.profile.data.map_boundary_bottom_y !== null){

                    const boundary = {
                        top: {
                            x: context.profile.data.map_boundary_top_x,
                            y: context.profile.data.map_boundary_top_y
                        },
                        bottom: {
                            x: context.profile.data.map_boundary_bottom_x,
                            y: context.profile.data.map_boundary_bottom_y
                        }
                    }

                    
                    defaultOriginX = boundary.top.x + ((boundary.bottom.x - boundary.top.x) / 2)
                    defaultOriginY = boundary.top.y + ((boundary.bottom.y - boundary.top.y) / 2);
                    translateX = -Math.abs(((boundary.bottom.x + boundary.top.x)-mc.offsetWidth)/2);
                    translateY = -Math.abs(((boundary.bottom.y + boundary.top.y)-mc.offsetHeight)/2);

                    var fxs = mc.offsetWidth / (boundary.bottom.x - boundary.top.x);
                    var fys = mc.offsetHeight / (boundary.bottom.y - boundary.top.y);
                    var fs = fxs < fys ? fxs : fys;

                    ds = (fs-1)*10;

                }
            }

            const { left, top } = mi.getBoundingClientRect();
            instance = renderer({ 
                minScale: minScale, 
                maxScale: 10, 
                element: mi, 
                scaleSensitivity: 10, 
                defaultOriginX: defaultOriginX + left, 
                defaultOriginY:  defaultOriginY + top, 
                translateX: translateX, 
                translateY: translateY,
                deltaScale: ds
            });
            if(typeof props.handleSetScale === 'function')
                props.handleSetScale(instance.getState().transformation.scale);    

        }

       return () => {

            if(typeof mc !== 'undefined' && mc !== null){

                /*
                *   Drag the map
                */
                mc.removeEventListener('touchstart', handleDragStart, false);
                mc.removeEventListener('touchend', handleDragEnd, false);
                mc.removeEventListener('touchmove', handleDrag, false);

                mc.removeEventListener('mousedown', handleDragStart, false);
                mc.removeEventListener('mouseup', handleDragEnd, false);
                mc.removeEventListener('mouseleave', handleDragEnd, false);
                mc.removeEventListener('mousemove', handleDrag, false);

                /*
                *   Use the mouse wheel
                */
                mc.removeEventListener('wheel', handleWheel, false);


                /*
                *   Pinch gesture
                */
                mc.removeEventListener('pointerdown', handlePointerDown, false);
                mc.removeEventListener('pointermove', handlePointerMove, false);
                mc.removeEventListener('pointerup', handlePointerUp, false);
                mc.removeEventListener('pointercancel', handlePointerUp, false);
                mc.removeEventListener('pointerout', handlePointerUp, false);
                mc.removeEventListener('pointerleave', handlePointerUp, false);

                instance = null;
            }
       }
    }, [map]);

   

    /*
    *
    *   Scaling functions
    *
    */

    const handleZoom = (up = true) => {
        if(instance !== null){
            instance.zoom({
                deltaScale: up ? 1 : -1,
                x: instance.getState().transformation.originX,
                y: instance.getState().transformation.originY
            });
            if(typeof props.handleSetScale === 'function')
                props.handleSetScale(instance.getState().transformation.scale);
        }
    }

    // const handleZoomDecrease = (val = .2) => {
    //     instance.zoom({
    //         deltaScale: -1,
    //         x: null,
    //         y: null
    //     });
    // }

    const handleWheel = (e) => {
        e.preventDefault();
        instance.zoom({
            deltaScale: Math.sign(e.deltaY) > 0 ? -1 : 1,
            x: e.pageX,
            y: e.pageY
        });
        if(typeof props.handleSetScale === 'function')
            props.handleSetScale(instance.getState().transformation.scale);
    }


    const handleDoubleClick = (e) => {
        e.preventDefault();

        handleDidEvent('doubleclick');

        instance.zoom({
            deltaScale: 20,
            x: clickCount[clickCount.length - 1].x,
            y: clickCount[clickCount.length - 1].y
        });

        if(typeof props.handleSetScale === 'function')
            props.handleSetScale(instance.getState().transformation.scale);
    }


    const handlePointerDown = e => {
        pointerCache.push(e);
    }

    const handlePointerMove = e => {

        /*
        *   Find the event in the cache and replace it with this one
        */
        for (let i=0; i<pointerCache.length; i++) {
            if (e.pointerId === pointerCache[i].pointerId) {
                pointerCache[i] = e;
                break;
            }
        }

        /*
        *   Check the cache length
        */
        if (pointerCache.length === 2) {

            var ydiff = Math.abs(pointerCache[0].clientY - pointerCache[1].clientY);
            var xdiff = Math.abs(pointerCache[0].clientX - pointerCache[1].clientX);

            //  What is the difference between the two fingers
            var curDiff = Math.sqrt((ydiff * ydiff) + (xdiff * xdiff));

            //  Determine the amount of movement
            let movementAmount = curDiff < pointerPrevDiff ? pointerPrevDiff-curDiff : curDiff-pointerPrevDiff;

            //  If the amount of movement was just a couple of pixel don't bother
            //  this could cause jank with fat fingers
            if(movementAmount > 4){
                if (pointerPrevDiff > 0) {
                    // The fingers are zooming in
                    instance.zoom({
                        deltaScale: curDiff > pointerPrevDiff ? 1 : -1,
                        x: e.pageX,
                        y: e.pageY
                    });
                    if(typeof props.handleSetScale === 'function')
                        props.handleSetScale(instance.getState().transformation.scale);
                    
                    handleDidEvent('pinchzoom');
                    wasDragged = true;

                }

                pointerPrevDiff = curDiff;
            }
        }

    }

    const handlePointerUp = e => {
        removePointerEvent(e);
        // If the number of fingers is less than two un cache the previous difference between the fingers
        if (pointerCache.length < 2)
            pointerPrevDiff = -1;

        //
        //  Check if the up is following an actual drag
        //
        if(wasDragged === true){
            handleDidEvent('drag');
            wasDragged = false;
        }
    }

    const removePointerEvent = e => {
        // Remove this event from the pointer cache
        for (let i=0;i<pointerCache.length;i++) {
            if (pointerCache[i].pointerId === e.pointerId) {
                pointerCache.splice(i, 1);
                break;
            }
        }
    }




    /*
    *
    *   Dragging functions
    *
    */


    const handleDragStart = e => {

        e.preventDefault();

        handleDetectClick(e);
        
        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX;
            initialY = e.touches[0].clientY;
        } else {
            initialX = e.clientX;
            initialY = e.clientY;
        }

        dragActive = true;

    }
  
    const handleDragEnd = e => {  

        initialX = currentX;
        initialY = currentY;
        dragActive = false;

        //
        //  Check for double clicks
        //
        if(clickCount.length >= 2)
            handleDoubleClick(e);

        //
        //  Check if the up is following an actual drag
        //
        if(wasDragged === true){
            handleDidEvent('drag');
            wasDragged = false;
        }
    }
  
    const handleDrag = e => {

        if (dragActive) { 
            
            e.preventDefault();

            /*
            *   Get the X, Y position of the current touch position minus where we started
            */
            const a = 20;
            let moveAmountX = 0;
            let moveAmountY = 0;
            let newInitialX = 0;
            let newInitialY = 0;

            if (e.type === "touchmove") {

                moveAmountX = e.touches[0].clientX - initialX;
                moveAmountY = e.touches[0].clientY - initialY;
                newInitialX = e.touches[0].clientX;
                newInitialY = e.touches[0].clientY;

            } else {

                moveAmountX = e.clientX - initialX;
                moveAmountY = e.clientY - initialY;
                newInitialX = e.clientX;
                newInitialY = e.clientY;

            }
            
            if((moveAmountX > a || moveAmountX < -a) || (moveAmountY > a || moveAmountY < -a)){

                handleDidEvent('drag');
                wasDragged = true;
                
                currentX = moveAmountX;
                currentY = moveAmountY;
                initialX = newInitialX;
                initialY = newInitialY;

                instance.panBy({
                    originX: currentX,
                    originY: currentY
                });

            }

        }

    }


    const handleDetectClick = (e) => {

        if(e.type === 'touchstart' && e.touches.length !== 1){
            // If this is touch screen and you are using more than one finger reset the clicks
            clickCount = [];
        } else {

            let click = {
                x: 0,
                y: 0
            }
            if(e.type === 'touchstart'){
                click.x = e.touches[0].clientX;
                click.y = e.touches[0].clientY;
            } else {
                click.x = e.pageX;
                click.y = e.pageY;
            }


            let distance = 0
            if(clickCount.length > 0){
                var xd = Math.abs(clickCount[clickCount.length - 1].x - click.x);
                var yd = Math.abs(clickCount[clickCount.length - 1].y - click.y);
                distance = xd > yd ? xd : yd;
            }

            if(distance < 50) {
                //
                // Monitoring clicks
                //
                clickCount.push(click);
    
                if(typeof clickCountTimeout !== undefined)
                    clearTimeout(clickCountTimeout);
    
                clickCountTimeout = setTimeout(() => {
                    clickCount = [];
                }, 300)
            }

        }

    }

    const handleDidEvent = () => {

        const mi = mapItem.current;
        const newEvent = new Event('mapevent');
        if(typeof mi !== 'undefined')
            mi.dispatchEvent(newEvent);

    }


    const handleFullScreenToggle = () => {
        setFullScreen(!fullScreen);
    }



    /*
    *
    *   Handle the click events coming from the map
    * 
    */
    const handlePedestalPress = (id) => {
        context.toggleGlobalPanel('pedestal', true, {id: id})
    }
    const handleBerthPress = (id) => {
        context.toggleGlobalPanel('berth', true, {id: id})
    }


    useEffect(() => {
        
        //
        // We need to pass some states into the map
        //
        const loadPedestals = () => {   
            
            // We need to determine what state the pedestal icon should show
            // Options
            //     Vacant
            //     Assigned
            //     Reserved
            //     Fault
            //     Offline
            let newPedestals = [];

            // Get all ongoing services and their pedestal map reference;
            // let assigned_services = [];
            // context.services.data.filter(s => s.id_session !== null && s.end_time === null).forEach(s => {
            //     const utility = context.utilities.data.find(u => parseInt(u.id_utility) === parseInt(s.id_utility));
            //     if(typeof utility !== 'undefined'){
            //         const pedestal = context.pedestals.data.find(p => parseInt(p.id_pedestal) === parseInt(utility.id_pedestal));

            //         if(typeof pedestal !== 'undefined' && typeof pedestal.map_reference !== 'undefined' && pedestal.map_reference !== null && assigned_services.indexOf(pedestal.map_reference) === -1)
            //             assigned_services.push(pedestal.map_reference)
            //     }
            // })

            context.pedestals.data.filter(p => p.map_reference !== null).forEach(p => {
                let state = 0;
                let status = 'VACANT';

                // if(assigned_services.indexOf(p.map_reference) > -1)
                //     status = 'ASSIGNED';

                const utilities = context.utilities.data.filter(u => parseInt(u.id_pedestal) === parseInt(p.id_pedestal));
                utilities.forEach(u => {

                    if(u.service_status.toUpperCase() ===  'RESERVED' && state < 1){
                        status = 'RESERVED';
                        state = 1;
                    }

                    if(u.service_status.toUpperCase() ===  'ASSIGNED' && state < 2){
                        status = 'ASSIGNED';
                        state = 2;
                    }

                    if(u.service_status.toUpperCase() ===  'FAULT' && state < 3){
                        status = 'FAULT';
                        state = 3;
                    }

                    if(u.network_status.toUpperCase() ===  'OFFLINE' && state < 4){
                        status = 'OFFLINE';
                        state = 4;
                    }
                });

                newPedestals.push({
                    pedestal_reference: p.pedestal_reference,
                    map_reference: p.map_reference,
                    status: status
                })
            })


            setPedestals(newPedestals);
        }
        loadPedestals();

    }, [context.pedestals, context.utilities, context.services])



    


    useEffect(() => {
        
        //
        // We need to pass some states into the map
        //
        const loadMap = () => {   
            
            if(context.profile.status === 'OK' && typeof context.profile.data.id_organisation !== 'undefined'){
                setMap(`Organisation_${context.profile.data.id_organisation}`);
            }
           
        }
        loadMap();

    }, [context.profile])

    


    useEffect(() => {
        
        //
        // We need to pass some states into the map
        //
        const loadBerths = () => {   
            
            // We need to determine what state the berths should show
            let newBerths = [];

            // Get all occupancies and their map reference;
            let assigned_berths = [];
            context.occupancies.data.filter(o => o.end_time === null).forEach(o => {
                const berth = context.berths.data.find(b => parseInt(b.id_berth) === parseInt(o.id_berth));
                if(typeof berth !== 'undefined')
                    assigned_berths.push(berth.map_reference)
            })

            context.berths.data.filter(b => b.map_reference !== null).forEach(b => {
                let status = 'EMPTY';

                if(assigned_berths.indexOf(b.map_reference) > -1)
                    status = 'ASSIGNED';

                newBerths.push({
                    map_reference: b.map_reference,
                    status: status
                })
            })

            setBerths(newBerths);
        }
        loadBerths();

    }, [context.occupancies])

    useEffect(() => {
        if(instance !== null)
            handleZoom();
    }, [props.upTicks])

    useEffect(() => {
        if(instance !== null)
            handleZoom(false);
    }, [props.downTicks])

    return (
        <div ref={mapContainer} className={clsx(classes.container, fullScreen && classes.fullScreen)}>
            <IconButton onClick={handleFullScreenToggle} onTouchEnd={handleFullScreenToggle} className={classes.fullScreenIcon}><FullscreenIcon /></IconButton>
            {context.utilities.status === 'LOADING' && <div className={classes.loading}><CircularProgress size={32} color="inherit" /></div>}
            <div ref={mapItem} className={classes.map}>
                {map !== null && 
                <SvgMap 
                    handlePedestalPress={handlePedestalPress} 
                    handleBerthPress={handleBerthPress} 
                    pedestals={pedestals} 
                    berths={berths} 
                    filters={props.filters} 
                    name={map}
                    parent={mapItem}
                    fontSize={context.profile.data.map_pedestal_font_size || '7'}
                    />
                }
            </div>
        </div>
    )

}

export default MarinaMap;