import { CircularProgress, Collapse, List, ListItem, ListItemText, Typography, useTheme, makeStyles, IconButton, FormControlLabel } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react'
import InputField from '../components/inputField';
import globalContext from '../context/globalContext'
import {makeValidation, isValidForm} from '../global/formHelper';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    hoverCollapse: {
        position: 'absolute', 
        zIndex:888, 
        width: 300,
        maxHeight: 300, 
        backgroundColor: '#ffffff',
        overflowY: 'scroll',
        boxShadow: '0 6px 6px rgba(0,0,0,.3)'
    }
}))

const GlobalSelectCustomer = props => {

    const context = useContext(globalContext);
    const theme = useTheme();
    const classes = useStyles(theme);

    const [ loading , setLoading ] = useState(false);
    const [ showResults, setShowResults ] = useState(false);
    const [ results, setResults ] = useState([]);
    const [ selected, setSelected ] = useState(null);

    const [form, setForm] = useState({
        search: ''
    });

    const [validation, setValidation] = useState(makeValidation([
        {
            name: 'search',
            display_name: 'Select a customer',
            required: false
        }
    ]));

    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleInputClear = () => {
        let newForm = {...form};
        newForm.search = '';
        setForm(newForm);
        setSelected(null);

        if(typeof props.handleClear === 'function')
            props.handleClear();
    }

    const handleSubmit = async () => {

        setLoading(false);

        if(form.search === ''){
            setResults([]);
            setShowResults(false);
        } else {
            
            const valid = isValidForm(form, validation);
            setValidation(valid.validation);
            if(valid.isValid){
                setLoading(true);

                try {

                    let arr = [];
                    context.customers.data.forEach(c => {
                        let idxs = []
                        idxs.push(c.id_customer.toString().indexOf(form.search));
                        idxs.push(c.your_ref.toString().indexOf(form.search));
                        idxs.push(`${c.first_name} ${c.last_name}`.toUpperCase().indexOf(form.search.toUpperCase()));
                        idxs.push(c.email.toUpperCase().indexOf(form.search.toUpperCase()));
                        idxs.push(c.boat_name.toUpperCase().indexOf(form.search.toUpperCase()));
                        idxs = idxs.filter(i => i > -1);
                        if(idxs.length > 0)
                            arr.push({
                                idx: Math.min(...idxs),
                                id: c.id_customer,
                                type: 'CUSTOMER',
                                reference: `${c.first_name} ${c.last_name}`,
                                description: c.boat_name
                            })
                    })

                    arr = arr.sort((a, b) => {
                        return a.idx < b.idx ? -1 : 1;
                    })

                    setResults(arr);

                } catch (error) {

                    setResults(null);
                    console.log(error);
                    
                } finally {

                    setShowResults(true);
                    setLoading(false);

                }
            }

        }

    }

    const handleSelect = item => {
        setSelected(item.reference);
        setShowResults(false);

        if(typeof props.handleSelect === 'function')
            props.handleSelect(item);
    }


    useEffect(() => {
        handleSubmit();
    }, [form])


    return (
        <div style={{marginLeft: 15, marginRight: 15}}>
            {selected === null && <React.Fragment>
                <InputField 
                    name='search' 
                    validation={validation.search} 
                    value={form.search} 
                    handleChange={handleInputChange} 
                    handleClear={handleInputClear}
                    hoverLabel={true} 
                    placeholder='Enter a customer name or boat name'
                    loading={loading}
                    variant="outlined"
                    size="small"
                />
                <Collapse in={showResults} className={classes.hoverCollapse}>
                    {loading && <CircularProgress size="18" color="primary" />}
                    {(results.length === 0 && loading === false) && <Typography variant="caption" color="textSecondary">No results found</Typography>}
                    {(results.length > 0 && loading === false) && 
                        <List dense={true}>
                            {results.map((r, idx) => (
                                <ListItem button key={idx} onClick={() => handleSelect(r)}><ListItemText primary={r.reference} secondary={r.description} /></ListItem>
                            ))}
                        </List>
                    }
                </Collapse>
            </React.Fragment>}
            {selected !== null &&
                <div style={{minWidth: 220}}>
                    <Typography variant="caption">Customer</Typography>
                    <div className="flex-row">
                        <Typography>{selected}</Typography>
                        <IconButton onClick={handleInputClear} size="small"><CloseIcon /></IconButton>
                    </div>
                </div>
            } 
        </div>
    )

}

export default GlobalSelectCustomer;