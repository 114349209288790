import Api from '../global/api';

export function login (fields) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'USER_LOGIN',
            status: 'loading',
            payload: null
        })

        Api.req('/user', 'POST', fields).then(
            async res => {

                //
                //  If there is not a current active profile stored in the local storage
                //  add one in, this will be the defined active profile when profile comes
                //  to load
                //
                let active_profile = await Api.getLocal('_bvap');
                if(active_profile === null && res.result.user.organisations.length > 0){
                    Api.setLocal('_bvap', res.result.user.organisations[0].id_organisation);
                }

                // Login successful
                dispatch({
                    type: 'USER_LOGIN',
                    status: 'in',
                    payload: res.result
                })
            },
            err => {
                // Login error
                dispatch({
                    type: 'USER_LOGIN',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to log you in'
                })
            }
        )
    }
}

export function userValidate () {
    return async dispatch => {

        dispatch({
            type: 'USER_VERIFYING',
            payload: true
        })

        Api.req('/user', 'GET').then(
            async res => {

                //
                //  If there is not a current active profile stored in the local storage
                //  add one in, this will be the defined active profile when profile comes
                //  to load
                //
                let active_profile = await Api.getLocal('_bvap');
                if(active_profile === null && res.result.user.organisations.length > 0){
                    Api.setLocal('_bvap', res.result.user.organisations[0].id_organisation);
                }

                // Login successful
                dispatch({
                    type: 'USER_LOGIN',
                    status: 'in',
                    payload: res.result
                })
            },
            err => {
                // Login error
                dispatch({
                    type: 'USER_LOGOUT',
                    payload: null
                })
            }
        )
    }
}

export function logout () {

    return async dispatch => {

        Api.req('/user', 'DELETE').then(
            async res => {

                //  Removed the current active profile, 
                //  this will save bugs when another person logs in
                await localStorage.removeItem('_bvap');

                // Logout successful
                dispatch({
                    type: 'USER_LOGOUT',
                    payload: null
                })

            },
            err => {
                console.log(typeof err.msg === 'string' ? err.msg : 'Unable to log you out')
            }
        )

    }

}



export function register (fields) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'USER_LOGIN',
            status: 'loading',
            payload: null
        })

        Api.req('/user/register', 'POST', fields).then(
            async res => {

                //
                //  If there is not a current active profile stored in the local storage
                //  add one in, this will be the defined active profile when profile comes
                //  to load
                //
                let active_profile = await Api.getLocal('_bvap');
                if(active_profile === null && res.result.user.organisations.length > 0){
                    Api.setLocal('_bvap', res.result.user.organisations[0].id_organisation);
                }

                // Login successful
                dispatch({
                    type: 'USER_LOGIN',
                    status: 'in',
                    payload: res.result
                })
            },
            err => {
                console.log(err)
                // Login error
                dispatch({
                    type: 'USER_LOGIN',
                    status: 'error',
                    payload: typeof err === 'string' ? err : 'Sorry we were unable to register your account'
                })
            }
        )
    }
}