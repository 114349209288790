import React, { useContext, useEffect, useState } from 'react';
import {makeStyles, Toolbar, useTheme, Typography, Avatar} from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import API from '../global/api';
import globalContext from '../context/globalContext';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ProgressButton from '../components/progressButton';
import DownloadButton from '../components/downloadButton';
import { localeCurrency } from '../global/helpers';
import GlobalSelectCustomer from '../content/globalSelectCustomer';
import { SQLtoUTCDate } from '../global/dateTimeHelpers';


const headCells = ["customer_ref", "transaction_ref","customer","boat_name", "date", "type", "currency", "tax_rate", "tax_rate_label", "subtotal", "tax_amount", "amount"]

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const ReportTransactions = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [data, setData] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [ startDate, setStartDate ] = useState(new Date(new Date().setTime(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))));
  const [ endDate, setEndDate ] = useState(new Date());

  const handleLoadReport = () => {

    if(typeof context.profile.data.id_organisation !== 'undefined'){
      setLoading(true);
  
      API.req(`/customer/transactions/all/${context.profile.data.id_organisation}`, 'GET', {
        start: startDate,
        end: endDate,
      }).then(
        async res => {
          setLoading(false);
          if(customer === null)
            setData(res.result);
          else
            setData(res.result.filter(t => parseInt(t.id_customer) === parseInt(customer)))
        },
        err => {
            setLoading(false);
            context.showSnack({message: typeof err === 'string' ? err : JSON.stringify(err)}, 'error')
        }
      )
    }
  }

  const getCurrency = (id) => {
    const record = data.find(d => d.id_transaction === id);
    if(typeof record !== 'undefined')
      return record.currency;
    else
      return context.profile.data.currency;
  }

  useEffect(() => {
    handleLoadReport();
  }, [context.profile])


  useEffect(() => {
    handleLoadReport();
  }, [customer])




  return (
    <div className={classes.container}>

      <PageToolbar label="Transactions"></PageToolbar>

      <Toolbar disableGutters={true}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Start"
            format="dd/MM/yyyy"
            inputVariant="standard"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            value={startDate}
            onChange={d => setStartDate(d)}
            style={{marginRight: 10}}
          />
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="End"
            format="dd/MM/yyyy"
            inputVariant="standard"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            value={endDate}
            onChange={d => setEndDate(d)}
          />
        </MuiPickersUtilsProvider>
        <GlobalSelectCustomer handleSelect={(item) => setCustomer(item.id)} handleClear={() => setCustomer(null)} />
        <ProgressButton waiting={loading} variant="contained" color="default" onClick={handleLoadReport}>Generate</ProgressButton>
        <div style={{flex: 1}}></div>

        <DownloadButton 
          waiting={downloading} 
          variant="contained" 
          color="default"
          data={data}
          idCol="id_transaction"
          orderBy="date" 
          headCells={headCells}
          fileName={`transactions`}
          cellReplacementProps={{
            'tax_rate': (val, id) => val !== null ? localeCurrency(val) : 'N/A',
            'subtotal': (val, id) => val !== null ? localeCurrency(val) : 'N/A' ,
            'tax_amount': (val, id) => val !== null ? localeCurrency(val) : 'N/A' ,
            'amount': (val, id) => <b>{localeCurrency(val)}</b>
          }}
          handleError={(error) => console.log(error)}
        />
        
        
      </Toolbar>

      <AutoTable 
        rows={data} 
        idCol="id_transaction"
        orderBy="date" 
        headCells={headCells}
        loading={loading}
        cellReplacementProps={{
          'tax_rate': (val, id) => val !== null ? localeCurrency(val) : 'N/A',
          'subtotal': (val, id) => val !== null ? localeCurrency(val) : 'N/A' ,
          'tax_amount': (val, id) => val !== null ? localeCurrency(val) : 'N/A' ,
          'amount': (val, id) => <Typography color={parseFloat(val) < 0 ? 'error' : 'default'} variant="inherit"><b>{localeCurrency(val)}</b></Typography>,
          'date': (val, id) => SQLtoUTCDate(val),
        }} />

    </div>
  );
}

export default ReportTransactions;
