import React, {useState} from 'react';
import {useTheme, makeStyles} from '@material-ui/core';
import ProgressButton from './progressButton';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { multiSort, cleanHeader} from '../global/helpers';

const useStyles = makeStyles((theme) => ({
  gutterBottom: {
    marginBottom: 10
  }
}))

const DownloadButton = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const [loading, setLoading] = useState(false)

  const {headCells, fileName, orderBy } = props;


  const cellReplacementProp = (row, col, idx) => {

      const id = props.idCol !== null ? row[props.idCol] : idx;

      if(typeof props.cellReplacementProps !== 'object')
          return row[col];
      
      const cellProp = props.cellReplacementProps[col];
      if(typeof cellProp === 'undefined')
          return row[col];
      else
          return typeof cellProp === 'function'? cellProp(row[col], id) : cellProp;

  }


  const handleDownload = () => {
    setLoading(true);

    try {

      let csvStr = '';

      // Add the headers
      headCells.map(h => {
          csvStr = csvStr + cleanHeader(h) + ',';
      })

      csvStr = csvStr.substring(0,csvStr.length - 1);
      csvStr = csvStr + "\n";

      // Add the data
      multiSort(props.data, [
        {
            order: 'desc',
            orderBy: orderBy
        }
      ]).map(row => {

          headCells.map((x, idx) => {
              csvStr = csvStr + '"' + cellReplacementProp(row, x, idx) + '",';
          })

          csvStr = csvStr.substring(0, csvStr.length - 1);
          csvStr = csvStr + "\n";

      })

      csvStr = csvStr.substring(0, csvStr.length - 1);

      const filename = `${fileName}-${new Date().getTime()}`;

      var blob = new Blob([csvStr], { type: 'text/csv' });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        console.log('using window navigator');
        window.navigator.msSaveOrOpenBlob(blob, filename+'.csv');
        this.setState({downloading: false});
      } else { // for Non-IE (chrome, firefox etc.)
        console.log('not using window navigator');
        let a = document.createElement('a');
        a.style.display = 'none';
        a.href = 'data:text/csv;charset=utf-8,'+encodeURIComponent("\uFEFF"+csvStr);
        a.download = filename+'.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      
    } catch (error) {
      if(typeof props.handleError === 'function')
        props.handleError(error);
    } finally {
      setLoading(false);
    }

}



  return (
    <ProgressButton waiting={loading} variant="contained" color="default" onClick={handleDownload}><ArrowDownward style={{marginRight: 10, marginLeft: -5}} />Download</ProgressButton>
  );
}

export default DownloadButton;
