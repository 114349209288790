import React, { useContext, useEffect, useState } from 'react';
import {makeStyles, useTheme} from '@material-ui/core';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import globalContext from '../context/globalContext';
import { localeCurrency, sanitizeMeter } from '../global/helpers';
import images from '../assets/imgs';
import StatusChip from '../components/statusChip';
import { SQLtoUTCDate, SQLtoUTCDateObj } from '../global/dateTimeHelpers';
import UtilityStatusLights from '../components/utilityStatusLights';
import GlobalSelectCustomer from '../content/globalSelectCustomer';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const headCells = ['identity', 'utility_type', 'service_status', 'pedestal_reference', 'berth_reference', 'utility_description', 'tariff', 'customer', 'boat', 'meter' , 'start_time']

const ServicesActive = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);
  
  const [data, setData] = useState([]);
  const [customer, setCustomer] = useState(null);

  const loadData = () => {
    
    let newData = [];
    if(context.orgLoaded){
      const services = context.services.data.filter(s => s.end_time === null && SQLtoUTCDateObj(s.start_time) <= new Date());
      services.map(s => {
        const utility = context.utilities.data.find(u => parseInt(u.id_utility) === parseInt(s.id_utility));
        const customer = context.customers.data.find(c => parseInt(c.id_customer) === parseInt(s.id_customer));
        const tariff = context.tariffs.data.find(t => parseInt(t.id_tariff) === parseInt(utility.id_tariff));
        const pedestal = context.pedestals.data.find(p => parseInt(p.id_pedestal) === parseInt(utility.id_pedestal));

        const occupancy = typeof customer !== 'undefined' ? context.occupancies.data.find(o => parseInt(o.id_customer) === parseInt(customer.id_customer) && o.end_time === null) : []
        const berth = typeof occupancy !== 'undefined' ? context.berths.data.find(b => parseInt(b.id_berth) === parseInt(occupancy.id_berth)) : null;

        let newRow = {
          ...s,
          ...utility,
          tariff: typeof tariff !== 'undefined' ? `${tariff.tariff_name} - (${localeCurrency(parseFloat(tariff.rate), tariff.currency)})` : '',
          pedestal_reference: typeof pedestal !== 'undefined' ? pedestal.pedestal_reference : '',
          boat: typeof customer !== 'undefined' ? customer.boat_name : s.boat_name !== null ? s.boat_name : '',
          customer: typeof customer !== 'undefined' ? `${customer.first_name} ${customer.last_name}` : s.id_app_user !== null ? 'App user' : 'External',
          berth_reference: typeof berth !== 'undefined' && berth !== null ? berth.berth_reference : null
        }

        newData.push(newRow);
      })

      if(customer === null)
        setData(newData);
      else
        setData(newData.filter(t => parseInt(t.id_customer) === parseInt(customer)))
      };

  }
  
  useEffect(() => {
    loadData();
  }, [context.orgLoaded, context.services, context.utilities, customer])
  
  const handleRowClick = row => {
    const utility = context.utilities.data.find(u => u.id_utility === row.id_utility);
    context.toggleGlobalPanel('utility', true, utility);
  }

  const getUtility = (id) => {
    const row = data.find(r => r.id_service === id);
    if(typeof row === 'undefined')
      return null;

    const utility = context.utilities.data.find(u => u.id_utility === row.id_utility);
    return utility;
  }

  const getSanitizedMeter = (id) => {
    const row = data.find(r => r.id_service === id);
    if(typeof row === 'undefined')
      return null;

    const meter = sanitizeMeter((row.meter-row.billed_meter), row.id_unit);
    return `${meter}${row.unit}`;
  }


  return (
    <div className={classes.container}>

      <PageToolbar label="Assigned Services">
        <GlobalSelectCustomer handleSelect={(item) => setCustomer(item.id)} handleClear={() => setCustomer(null)} />
      </PageToolbar>
      <AutoTable 
        rows={data} 
        orderBy="start_time" 
        idCol="id_service"
        headCells={headCells} 
        loading={!context.orgLoaded}
        handleRowClick={handleRowClick} 
        cellReplacementProps={{
          'utility_type': (val, id) => (
            <div className="flex-row-start gap-10"><img src={val === 'Water' ? images.Icons_Water_Icon : images.Icons_Electric_Icon} style={{height: 24}} alt={val} />{val}</div>
          ),
          'service_status': (val, id) => (
            <div className="flex-row-start">
              <StatusChip status={val} />
              <UtilityStatusLights utility={getUtility(id)} />
            </div>
          ),
          'start_time': (val, id) => (
            SQLtoUTCDate(val)
          ),
          'meter': (val, id) => (
            getSanitizedMeter(id)
          )
        }}
      />

    </div>
  );
}

export default ServicesActive;
