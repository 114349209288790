import React, { useEffect, useState } from 'react';
import { Button, FormControlLabel, DialogTitle, Dialog, DialogContent, Typography, DialogActions } from '@material-ui/core';
import BooleanSwitch from './booleanSwitch';
import { cleanHeader } from '../global/helpers';


const BooleanFilterDialog = props => {

  const { show, title } = props;

  const [items, setItems] = useState({});

  const handleClose = () => {
    if(typeof props.handleClose === 'function')
        props.handleClose(items);
  };

  const handleSwitchChange = e => {
    let newItems = {...items};
    newItems[e.target.name] = e.target.checked;
    setItems(newItems);
  }

  useEffect(() => {
    setItems(props.items);
  }, [props.items])

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={show}>
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <div className="flex-col">
                {Object.keys(items).map((item, idx) => (
                    <FormControlLabel
                        key={idx}
                        labelPlacement="end"
                        label={cleanHeader(item)} 
                        control={(
                            <BooleanSwitch checked={items[item]} handleSwitchChange={handleSwitchChange} name={item}  />
                        )}>
                    </FormControlLabel>
                    ))
                }
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Done</Button>
        </DialogActions>
    </Dialog>
  );
}

export default BooleanFilterDialog;