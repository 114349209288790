import Api from '../global/api';

export function prefsLoad () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'PREFS_LOAD',
            status: 'LOADING',
            payload: null
        })

        let active_prefs = await Api.getLocal('_bvpf');
        if(active_prefs !== null){
            dispatch({
                type: 'PREFS_LOAD',
                status: 'OK',
                payload: active_prefs
            })
        } else {
            const default_prefs = {
                berths: true,
                pedestals: true,
                pedestal_numbers: false
            }
            dispatch({
                type: 'PREFS_LOAD',
                status: 'OK',
                payload: default_prefs
            })
        }
        

    }
}

export function prefsUpdate (payload) {
    return async dispatch => {

        Api.setLocal('_bvpf', payload);
        // Loading
        dispatch({
            type: 'PREFS_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}