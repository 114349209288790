import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  }
}));

const ProfileContactAdd = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ form, setForm ] = useState({
    id_organisation_contact_type: props.type === 'contacts_accounts' ? 2 : 1,
    name: '',
    position: '',
    email: '',
    phone: ''
  });
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'name',
      display_name: 'Name',
      required: true
    },
    {
      name: 'position',
      display_name: 'Position',
      required: false
    },
    {
      name: 'email',
      display_name: 'Email',
      required: false
    },
    {
      name: 'phone',
      display_name: 'Phone Number',
      required: false
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = () => {

    setError(null);
    setLoading(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){

      //
      // Execute an api request and get the returned result
      //
      API.req(`/organisation/${context.profile.data.id_organisation}/contact`, 'POST', form).then(
        res => {
          setLoading(false);

          let newProfile = JSON.parse(JSON.stringify(context.profile.data));

          // What about the id??
          newProfile[props.type].push({
            ...form,
            id_organisation_contact: res.result.insertId
          });
          context.profileUpdate(newProfile);
          if(typeof props.handleClose === 'function')
            props.handleClose();

          context.showSnack({message: 'Contact added!'}, 'success')

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )

    }
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  return (
      <div className={classes.detailsWrapper}>

        {error && <Alert severity="error">{error}</Alert>}

        <div className={classes.form}>

          <div className="flex-row">
            <InputField name='name' validation={validation.name} value={form.name} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='position' validation={validation.position} value={form.position} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='email' validation={validation.email} value={form.email} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='phone' validation={validation.phone} value={form.phone} handleChange={handleInputChange} hoverLabel={true} />
          </div>

        </div>

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Save &amp; Close</ProgressButton>
        </div>

      </div>
  );
}

export default ProfileContactAdd;
