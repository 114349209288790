import Api from '../global/api';

export function berthPedestalsLoad () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'BERTH_PEDESTALS_LOAD',
            status: 'LOADING',
            payload: null
        })

        let active_profile = await Api.getLocal('_bvap');
        if(active_profile !== null){
            Api.req(`/berth/pedestals/${active_profile}`, 'GET').then(
                res => {

                    Api.setLocal('berth_pedestals', res.result);

                    dispatch({
                        type: 'BERTH_PEDESTALS_LOAD',
                        status: 'OK',
                        payload: res.result
                    })
                },
                err => {
                    // Login error
                    dispatch({
                        type: 'BERTH_PEDESTALS_LOAD',
                        status: 'error',
                        payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your berth pedestals'
                    })
                }
            )
        }

    }
}

export function berthPedestalAdd (payload) {
    return dispatch => {

        // Loading
        dispatch({
            type: 'BERTH_PEDESTAL_ADD',
            status: 'OK',
            payload: payload
        })

        return 'success';

    }
}

export function berthPedestalRemove (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'BERTH_PEDESTAL_REMOVE',
            status: 'OK',
            payload: payload
        })
        
    }
}