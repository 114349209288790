import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, Typography, useTheme, Slider, TextField, IconButton} from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import MarinaMap from '../content/marinaMap';
import PageToolbar from '../components/pageToolbar';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import BooleanFilterDialog from '../components/booleanFilterDialog';
import SearchGlobal from '../content/searchGlobal';
import globalContext from '../context/globalContext';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  sliderWrapper: {
    width: 160, 
    margin: '0 20px', 
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sliderLabel: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
}))

function valuetext(value) {
  return `${value}°C`;
}


const Home = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [filters, setFilters] = useState({
    berths: true,
    pedestals: true,
    pedestal_numbers: false
  })
  const [showFilter, setShowFilter] = useState(false);

  const handleFilterClose = (newFilters) => {
    context.prefsUpdate(newFilters);
    setShowFilter(false);
  }

  const [scale, setScale] = useState(0);
  const [upTicks, setUpTicks] = useState(0);
  const [downTicks, setDownTicks] = useState(0);

  const handleChangeScale = (e, newScale) => {
    if(newScale > scale)
      zoomIn();
    else
      zoomOut();
  };

  const zoomIn = e => {
    setUpTicks(upTicks+1);
  }

  const zoomOut = e => {
    setDownTicks(downTicks-1);
  }

  const handleSelectSearchResult = item => {
    switch(item.type.toUpperCase()){
      case 'BERTH':
        context.toggleGlobalPanel('berth', true, {id: item.id});
        break;
      case 'PEDESTAL':
        context.toggleGlobalPanel('pedestal', true, {id: item.id})
        break;
      case 'UTILITY':
        const selectedUtility = context.utilities.data.find(u => u.id_utility === item.id);
        if(typeof selectedUtility !== 'undefined')
          context.toggleGlobalPanel('utility', true, selectedUtility);
        break;
      default:
    }
  }

  useEffect(() => {

    if(context.prefs.data !== null && context.prefs.status !== 'LOADING')
      setFilters(context.prefs.data);

  }, [context.prefs.data])


  return (
    <div className={classes.container}>

      <PageToolbar label="Marina">
        <Typography variant='caption' className={classes.sliderLabel}>Zoom</Typography>
        <div className={classes.sliderWrapper}>
          <Slider
            value={scale}
            onChange={handleChangeScale}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-small-steps"
            min={0}
            max={10}
            valueLabelDisplay="off"
          />
        </div>
        <SearchGlobal label="Search" hover size="small" variant="outlined" handleSelect={handleSelectSearchResult} />
        <IconButton onClick={() => setShowFilter(true)}><TuneIcon /></IconButton>
      </PageToolbar>

      <MarinaMap handleSetScale={setScale} scale={scale} upTicks={upTicks} downTicks={downTicks} filters={filters} />

      <BooleanFilterDialog show={showFilter} handleClose={handleFilterClose} items={filters} title={<Typography variant="h5" className="flex-row-start"><TuneIcon />Filter map</Typography>} />

    </div>
  );
}

export default Home;
