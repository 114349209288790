import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box, useTheme, CircularProgress, Typography, makeStyles} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';


const useStyles = makeStyles((theme, mainColor) => ({ 
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round'
  },
  value: {
    fontWeight: 600,
    fontSize: 28,
    [theme.breakpoints.up('md')]: {
      fontSize: 38,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 54,
    }
  },
  label: {
    fontWeight: 300,
    color: mainColor,
    fontSize: 11,
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    }
  }
}))


const ProgressDonut = props => {

  const theme = useTheme();
  const classes = useStyles(theme, props.color);

  const [value, setValue] = useState(0);

  useEffect(() => {

    setTimeout(() => {
      setValue(Math.round((props.value / props.total) * 100))
    }, 300)

    return () => {
      setValue(0)
    }
  }, [])

  const getDonutSize = () => {
    switch(props.width){
      case 'lg':
      case 'xl':
        return 200
      case 'md':
        return 140
      default:
        return 100
    }
  }

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" className={classes.bottom} size={getDonutSize()} thickness={4} value={100} />
      <CircularProgress variant="determinate" value={value} className={classes.top} style={{color: props.color}} size={getDonutSize()}
        classes={{
          circle: classes.circle,
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography variant="h1" gutterBottom={false} className={classes.value} style={{color: props.color}}>{props.value}</Typography>
        <Typography className={classes.label}>{props.label}</Typography>
      </Box>
    </Box>
  );
}

ProgressDonut.propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
}

export default withWidth()(ProgressDonut);
