import Images from '../assets/imgs/';

export function comparator (a, b, orderBy, order = 'desc') {
    var x, y;

    if(Array.isArray(orderBy)){

        orderBy.forEach(z => {
            x = a[z]!=null?a[z]:'';
            y = b[z]!=null?b[z]:'';

            if(isNaN(x) === false)
                x = Number(x);

            if(isNaN(y) === false)
                y = Number(x);

            if(x !== y){
                if (y < x) return order === 'desc' ? -1 : 1;
                if (y > x) return order === 'desc' ? 1 : -1;
            }
        })

    } else {

        x = a[orderBy]!=null?a[orderBy]:'';
        y = b[orderBy]!=null?b[orderBy]:'';

        if (y < x) return order === 'desc' ? -1 : 1;
        if (y > x) return order === 'desc' ? 1 : -1;

    }
    return 0;
}


export function multiSort (data = [], orderBy = []){
    //
    //  Order by needs to be an array of objects with orderBy and order properties
    //  e.g. [{orderBy: id, order'desc'}, {orderBy: name, order'asc'}]
    //
    //  Initially reverse the order becuase you want to sort 
    //  from the last first so that the first property will
    //  become the main sorting factor 
    var revOrder = JSON.parse(JSON.stringify(orderBy)).reverse();

    // Make a deep copy of the data
    var arr = JSON.parse(JSON.stringify(data));

    // Loop through the properties to order by
    revOrder.forEach(o => {

        // Sort the data by that property
        arr = arr.sort((a, b) => {
            return comparator(a, b, o.orderBy, o.order)
        })
    })

    return arr;
}

// Makes the columns name more presentable
export function cleanHeader(header){
    let newHeader = header;
    if(header.indexOf('id_') > -1)
        newHeader = newHeader.replace('id_', '') + ' ID';

    newHeader = newHeader.replace(/_/gi, ' ');
    return newHeader.charAt(0).toUpperCase() + newHeader.slice(1)
}

export function localeCurrency(value, currency = null) {
    if(currency !== null)
        return parseFloat(value).toLocaleString(navigator.language, {style: 'currency', currency: currency})
    else
        return parseFloat(value).toLocaleString(navigator.language)
}


export function fixedLengthNumber(value, len) {
    let valStr = `${value}`;
    const diff = parseInt(len) - valStr.length;
    for(let i=0;i<diff;i++){
        valStr = `0${valStr}`;
    }
    return valStr;
}


export function getPedestalImage(pedestal_type) {
  switch(parseInt(pedestal_type)){
    case 4:
      return Images.Pedestal_Icons_Quantum;
    case 1:
      return Images.Pedestal_Icons_Classic;
    case 3:
      return Images.Pedestal_Icons_MegaMaster;
    case 2:
      return Images.Pedestal_Icons_Fin;
    default:
      return null;
  }
}

export function keyValueArray(arr, key, value) {
    let newArr = [];
    arr.forEach(i => {
        newArr.push({
            key: i[key],
            value: i[value]
        })
    })
    return newArr;
}


export function sanitizeMeter(meter, id_unit) {
    switch(id_unit){
        case 1:
        case 2:
            return parseFloat(meter / 1000).toFixed(2);
        default:
            return meter;
    }
}
