import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, Button} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import {localeCurrency, keyValueArray} from '../global/helpers';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import UtilityOverview from '../components/utilityOverview';
import { getPedestalImage } from '../global/helpers';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  utilityImage: {
    width: 300,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  utilityOverview: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 50
  },
  pedestalImage: {
    width: 220,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  pedestalUtilityDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  form: {
    paddingTop: 40
  }
}));

const UtilityModify = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [ pedestal, setPedestal ] = useState(null);

  const [ tariffs, setTariffs ] = useState([]);
  const [ form, setForm ] = useState(props.utility);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'id_tariff',
      display_name: 'Tariff',
      required: true
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    setError(null);
    setLoading(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){


      //
      // Execute an api request and get the returned result
      //
      API.req(`/tariff/apply/${context.profile.data.id_organisation}`, 'POST', form).then(
        async res => {
          setLoading(false);

          context.utilityUpdate(res.result);
          context.showSnack({message: 'Tariff updated!'}, 'success')

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )

    }
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])


  useEffect(() => {
    let tariffs = [];
    context.tariffs.data.filter(t => t.deleted === null).forEach(t => {
      tariffs.push({
        key: t.id_tariff,
        value: `${t.tariff_name} ${localeCurrency(parseFloat(t.rate), t.currency)} / ${t.unit}`
      })
    })
    setTariffs(tariffs);
  }, [context.tariffs])

  useEffect(() => {

    if(props.utility !== null){
      const newPedestal = context.pedestals.data.find(p => parseInt(p.id_pedestal) === parseInt(props.utility.id_pedestal));
      if(typeof newPedestal !== 'undefined')
        setPedestal(newPedestal);
    }

  }, [props.utility])


  return (
      <div className={classes.detailsWrapper}>
        {error && <Alert severity="error">{error}</Alert>}

        {(pedestal !== null || props.utility !== null) && 
          <div className={classes.utilityOverview}>
            <div className={classes.pedestalImage}>
              {pedestal !== null && <img src={getPedestalImage(pedestal.id_pedestal_type)} style={{height: '100%', width: 'auto'}} alt={pedestal.pedestal_type} />}
            </div>
            {props.utility !== null && 
              <div className={classes.pedestalUtilityDetails}>
                <UtilityOverview utility={props.utility} />
              </div>
            }
          </div>
        }

        <div className={classes.form}>

          <div className="flex-row">
            <InputField name='id_tariff' validation={validation.id_tariff} value={form.id_tariff} handleChange={handleInputChange} hoverLabel={true} type="select" items={tariffs} />
          </div>

          <div className="flex-row-end">
            <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Save</ProgressButton>
          </div>

        </div>

        <div className="flex"></div>

      </div>
  );
}

export default UtilityModify;
