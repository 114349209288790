import Api from '../global/api';

export function metaLoad () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'META_LOAD',
            status: 'LOADING',
            payload: null
        })

        Api.req(`/meta`, 'GET').then(
            res => {

                Api.setLocal('meta', res.result);

                dispatch({
                    type: 'META_LOAD',
                    status: 'OK',
                    payload: res.result
                })
            },
            err => {
                // Login error
                dispatch({
                    type: 'META_LOAD',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'We were unable to load some of the supporting meta data'
                })
            }
        )
        

    }
}