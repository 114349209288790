import React, { useContext, useState } from 'react';
import {withRouter} from 'react-router-dom';
import {useTheme, Toolbar, IconButton, makeStyles, Link, Popover, List, ListItem, ListItemText, Typography, Divider, Button} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Images from '../assets/imgs/index';
import AccountAvatar from './accountAvatar';
import GlobalContext from '../context/globalContext';
import PersonIcon from '@material-ui/icons/Person';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  fill: {
    flex: 1,
  },
  logo: {
    height: 40,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menu: {
    width: 300,
  },
  name: {
    margin: theme.spacing(2)
  },
  meta: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  organisations: {
    maxHeight: 200,
    overflowY: 'auto'
  }
}));


const MainToolbar = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(GlobalContext)

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const onChangeRoute = route => {
    props.history.push(route);
  } 


  const handleAvatarClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    if(typeof context.logout === 'function')
      context.logout();
  }

  return (
    <Toolbar>
      <IconButton edge="start" color="inherit" aria-label="menu" className={classes.menuButton} onClick={context.showMenu}>
        <MenuIcon />
      </IconButton>
      <Link onClick={() => {onChangeRoute('/')}}><img src={Images.logo} className={classes.logo} alt="BerthVend" /></Link>
      <div className={classes.fill}></div>
      <AccountAvatar user={context.user} organisation={context.profile} userLogout={context.userLogout} handleClick={handleAvatarClick}/>
      <Popover 
        id='avatar-menu'
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.menu}>
          <div className={classes.name}>
            <div className="flex-row align-center">
              <PersonIcon />
              <div className="flex">
                <Typography>{context.user.profile.user.first_name} {context.user.profile.user.last_name}</Typography>
                <Typography variant="caption" color="textSecondary" component="div">{context.user.profile.user.email}</Typography>
                <Link onClick={handleLogout} style={{cursor: 'pointer'}}><b>Sign out</b></Link>
              </div>
            </div>
          </div>
          {context.user.profile.user.organisations.length > 1 && 
            <React.Fragment>
              <Divider />
              <div className={classes.organisations}>
                <List dense={true}>
                  {context.user.profile.user.organisations.map((o, idx) => (
                    <ListItem key={idx} button onClick={() => context.switchOrganisation(o.id_organisation)}>
                      <ListItemText primary={o.organisation_name} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </React.Fragment>
          }
          <Divider />
          <div className={classes.meta}>
            <div className="flex-row align-center">
              {context.socketConnected && <WifiIcon style={{color: theme.palette.success.main, fontSize: 20}} />}
              {!context.socketConnected && <WifiOffIcon style={{color: theme.palette.fault.main, fontSize: 20}} />}
              <Typography variant="caption" color="textSecondary">v{context.version}</Typography>
            </div>
          </div>
        </div>
      </Popover>
    </Toolbar>
  );
}

export default withRouter(MainToolbar);
