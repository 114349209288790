import React, { useContext, useEffect, useState } from 'react';
import {useTheme, makeStyles, Typography} from '@material-ui/core';
import UtilityOverview from '../components/utilityOverview';
import { getPedestalImage } from '../global/helpers';
import globalContext from '../context/globalContext';
import Alert from '@material-ui/lab/Alert';
import API from '../global/api';
import ProgressiveButton from '../components/progressButton';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  wizard: {

  },
  selectUtility: {
    paddingTop: 15,
    paddingBottom: 15
  },
  utilityOverview: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 50
  },
  pedestalImage: {
    width: 220,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  pedestalUtilityDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));


const ServiceUtilityReservation = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ utility, setUtility ] = useState(null);
  const [ berth, setBerth ] = useState(null);
  const [ pedestal, setPedestal ] = useState(null);
  const [ service, setService ] = useState(null);
  const [ customer, setCustomer ] = useState(null);

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);


  const handleStopService = async () => {

    setError(null);
    setLoading(true);



    API.req(`/service/end/${context.profile.data.id_organisation}`, 'PUT', service).then(
      async res => {


        //
        //  This should cause a chain reaction to re-loading everything else
        //  and is in place should the REST api be operational but the socket
        //  not
        //
        context.utilityUpdate({
          ...props.utility,
          service_status: 'vacant'
        });
        context.serviceUpdate(res.result);
        context.showSnack({message: 'Service stopped!'}, 'success')
        setLoading(false);
        setService(null);
        setCustomer(null);

        if(typeof props.handleClose === 'function')
          setTimeout(props.handleClose, 1500)



      },
      err => {
          setLoading(false);
          setError(typeof err === 'string' ? err : JSON.stringify(err))
      }
    )

  }


  //
  //  If the utility changes in the background we need to update the curreny utility
  //
  useEffect(() => {
    
    if(utility !== null){
      const findUtility = context.utilities.data.find(u => u.id_utility === utility.id_utility)
      if(JSON.stringify(findUtility) !== JSON.stringify(utility))
        setUtility(findUtility);
    }

  }, [context.utilities])


  //
  //  If the service changes in the background we need to update the current service
  //
  useEffect(() => {

    if(utility !== null){
      const findService = context.services.data.find(s => parseInt(s.id_service) === parseInt(props.service.id_service) && s.end_time === null);
      if(typeof findService !== 'undefined')
        setService(findService);

      //
      //  Was this person looking at an active session that needs to drop off
      //
      if(typeof findService === 'undefined' && service !== null && service.id_service !== null){
        setService(null);
        setCustomer(null);
      }
    }

  }, [context.services])


  useEffect(() => {

    if(utility !== null){
  
      const loadPedestal = () => {
        const findPedestal = context.pedestals.data.find(p => p.id_pedestal === utility.id_pedestal);
        if(typeof findPedestal !== 'undefined')
          setPedestal(findPedestal);
      }
      loadPedestal();

    }

  }, [utility])

  // useEffect(() => {
  //   if(service !== null && customer !== null){
  //     let newService = JSON.parse(JSON.stringify(service));
  //     newService.id_customer = customer.id_customer;
  //     setService(newService);
  //   }
  // }, [customer])

  useEffect(() => {

    if(service !== null && service.id_customer !== null){
      const loadCustomer = () => {
        const findCustomer = context.customers.data.find(c => parseInt(c.id_customer) === parseInt(service.id_customer));
        if(typeof findCustomer !== 'undefined'){
          setCustomer(findCustomer);
        }
      }
      loadCustomer();

      const loadUtility = () => {
        const findUtility = context.utilities.data.find(u => parseInt(u.id_utility) === parseInt(service.id_utility));
        if(typeof findUtility !== 'undefined')
          setUtility(findUtility);
      }
      loadUtility();
    }
  }, [service])


  useEffect(() => {
    if(props.service !== null)
      setService(props.service);
  }, [props.service])


  return (
    <div className={classes.detailsWrapper}>

      {error && <Alert severity="error" style={{marginBottom: 20}}>{error}</Alert>}

      {service !== null && 
        <div style={{marginTop: 40}}>
            <Typography>Customer: <b>{customer && customer.first_name} {customer && customer.last_name}</b></Typography>
            <Typography>Boat: <b>{customer && customer.boat_name}</b></Typography>
            <Typography>Berth: <b>{berth && berth.berth_reference}</b></Typography>
            <Typography>Account type: <b>{customer && customer.account_type}</b></Typography>
            <Typography>Discount: <b>{customer && customer.discount_name}</b></Typography>
        </div>
      }

          

      {(pedestal !== null || utility !== null) && 
        <div className={classes.utilityOverview}>
          <div className={classes.pedestalImage}>
            {pedestal !== null && <img src={getPedestalImage(pedestal.id_pedestal_type)} style={{height: '100%', width: 'auto'}} alt={pedestal.pedestal_type} />}
          </div>
          {utility !== null && 
            <div className={classes.pedestalUtilityDetails}>
              <UtilityOverview utility={utility} />
            </div>
          }
          {utility === null && 
            <div className={classes.pedestalUtilityDetails}>
              <Typography variant="h6" color="textSecondary">Select a utility above</Typography>
            </div>
          }
        </div>
      }

      <div style={{flex: 1}}></div>

      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: 20}}>
        <ProgressiveButton waiting={loading} variant="contained" onClick={handleStopService}>&nbsp;&nbsp;&nbsp;&nbsp;Remove Service&nbsp;&nbsp;&nbsp;&nbsp;</ProgressiveButton>
      </div>

    </div>
  );
}

export default ServiceUtilityReservation;
