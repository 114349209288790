import React, { useContext, useEffect, useState } from 'react';
import {makeStyles, useTheme, TextField, IconButton, Tooltip} from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import globalContext from '../context/globalContext';
import { localeCurrency } from '../global/helpers';
import EmailIcon from '@material-ui/icons/Email';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SideSlidePanel from '../components/sideSlidePanel';
import CustomerPaymentReminder from '../content/customerPaymentReminder';
import CustomerPaymentClear from '../content/customerPaymentClear';
import Images from '../assets/imgs/index';
import GlobalSelectCustomer from '../content/globalSelectCustomer';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  iconButtonCard: {
    width: 'auto',
    height: 36
  },
  iconButtonEmail: {
    width: 'auto',
    height: 38
  }
}))

const CustomersBilling = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [action, setAction] = useState(null);
  const [actionCustomer, setActionCustomer] = useState(null);

  const handleRowClick = row => {
    context.toggleGlobalPanel('manage_customer', true, row);
  }

  const handleReminderClick = (e, id) => {
    e.stopPropagation();
    setAction('send_reminder');
    setActionCustomer(customers.find(c => c.id_customer === id));
  }

  const handlePaymentClick = (e, id) => {
    e.stopPropagation();
    setAction('clear_payment');
    setActionCustomer(customers.find(c => c.id_customer === id));
  }

  const handleClearClick = () => {
    setAction(null);
    setActionCustomer(null);
  }

  const headCells = [
    'id_customer',
    'your_ref',
    'first_name',
    'last_name',
    'email',
    'phone',
    'boat_name',
    'type',
    'account_type',
    'balance',
    'currency',
    'billing_frequency',
    'status',
    'action'
  ]

  const getCustomerById = (id) => {
    const customer = context.customers.data.find(c => parseInt(c.id_customer) === parseInt(id));
    return customer;
  }

  useEffect(() => {
    let overdueCustomers = [];
    if(customer === null)
      overdueCustomers = context.customers.data.filter(c => parseFloat(c.balance) < 0);
    else
      overdueCustomers = context.customers.data.filter(c => parseFloat(c.balance) < 0 && c.id_customer === parseInt(customer));
    
    setCustomers(overdueCustomers);
  }, [context.customers.data, customer])

  return (
    <div className={classes.container}>

      <PageToolbar label="Billing">
        {/* <TextField label="Search" variant="outlined" placeholder="Joe Bloggs" size="small" InputLabelProps={{
          shrink: true,
        }}/>
        <IconButton><TuneIcon /></IconButton> */}
        <GlobalSelectCustomer handleSelect={(item) => setCustomer(item.id)} handleClear={() => setCustomer(null)} />
      </PageToolbar>

      <AutoTable 
        loading={context.customers.status === 'LOADING'}
        rows={customers} 
        idCol="id_customer"
        orderBy="id_customer"  
        handleRowClick={handleRowClick} 
        headCells={headCells}
        cellReplacementProps={{
          'balance': (val, id) => <b>{localeCurrency(val)}</b>,
          'billing_frequency': (val, id) => val === null ? 'N/A' : val,
          'action': (val, id) => (
            <div className="flex-row">
              <Tooltip title="Send email reminder" aria-label="Send email reminder"><IconButton color="primary" onClick={e => handleReminderClick(e, id)}><img src={Images.Icons_Email_Sent_Icon} className={classes.iconButtonEmail} alt="Send Payment Reminder" /></IconButton></Tooltip>
              <Tooltip title="Clear balance" aria-label="Clear balance"><IconButton onClick={e => handlePaymentClick(e, id)}><img src={Images.Icons_Wallet_Grey_Icon} className={classes.iconButtonCard} alt="Clear balance" /></IconButton></Tooltip>
            </div>
          )
        }}
      />

      <SideSlidePanel show={action === 'send_reminder'} title="Send a Payment Reminder" side="right" handleClose={handleClearClick}><CustomerPaymentReminder customer={actionCustomer} handleClose={handleClearClick} /></SideSlidePanel>
      <SideSlidePanel show={action === 'clear_payment'} title="Clear Payment" side="right" handleClose={handleClearClick}><CustomerPaymentClear customer={actionCustomer} handleClose={handleClearClick} /></SideSlidePanel>

    </div>
  );
}

export default CustomersBilling;
