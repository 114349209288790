import React, { useContext, useEffect, useState } from 'react';
import {makeStyles, useTheme} from '@material-ui/core';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import globalContext from '../context/globalContext';
import { localeCurrency } from '../global/helpers';
import images from '../assets/imgs';
import StatusChip from '../components/statusChip';
import { SQLtoUTCDate, SQLtoUTCDateObj } from '../global/dateTimeHelpers';
import ServiceUtilityReservation from '../content/serviceUtilityReservation';
import SideSlidePanel from '../components/sideSlidePanel';

const headCells = ['identity', 'pedestal_reference', 'utility_type', 'utility_description', 'service_status', 'tariff', 'customer', 'boat', 'start_time']

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const ServicesUpcoming = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);
  
  const [data, setData] = useState([]);
  const [reservation, setReservation] = useState(null);
  
  useEffect(() => {
    let newData = [];
    if(context.orgLoaded){
      const services = context.services.data.filter(s => s.end_time === null && SQLtoUTCDateObj(s.start_time) > new Date() && s.id_session === null);
      services.map(s => {
        const utility = context.utilities.data.find(u => parseInt(u.id_utility) === parseInt(s.id_utility));
        const customer = context.customers.data.find(c => parseInt(c.id_customer) === parseInt(s.id_customer));
        const tariff = context.tariffs.data.find(t => parseInt(t.id_tariff) === parseInt(utility.id_tariff));
        const pedestal = context.pedestals.data.find(p => parseInt(p.id_pedestal) === parseInt(utility.id_pedestal));

        let newRow = {
          ...s,
          ...utility,
          tariff: typeof tariff !== 'undefined' ? `${tariff.tariff_name} - (${localeCurrency(parseFloat(tariff.rate), tariff.currency)})` : '',
          pedestal_reference: typeof pedestal !== 'undefined' ? pedestal.pedestal_reference : '',
          boat: customer.boat_name,
          customer: `${customer.first_name} ${customer.last_name}`
        }

        newData.push(newRow);
      })
    }
    setData(newData);
  }, [context.orgLoaded, context.services])
  
  const handleRowClick = row => {
    setReservation(row);
  }

  return (
    <div className={classes.container}>

      <PageToolbar label="Upcoming Services"></PageToolbar>
      <AutoTable 
        rows={data} 
        orderBy="start_time" 
        headCells={headCells} 
        loading={!context.orgLoaded}
        handleRowClick={handleRowClick} 
        cellReplacementProps={{
          'utility_type': (val, id) => (
            <div className="flex-row-start gap-10"><img src={val === 'Water' ? images.Icons_Water_Icon : images.Icons_Electric_Icon} style={{height: 24}} alt={val} />{val}</div>
          ),
          'service_status': (val, id) => (
            <StatusChip status={val} id={id} />
          ),
          'start_time': (val, id) => (
            SQLtoUTCDate(val)
          ),
        }}
      />

      <SideSlidePanel title="Upcoming Service" side="right" show={reservation !== null} handleClose={() => {setReservation(null)}}><ServiceUtilityReservation service={reservation} handleClose={() => {setReservation(null)}} /></SideSlidePanel>

    </div>
  );
}

export default ServicesUpcoming;
