import React, { useContext, useEffect, useState } from 'react';
import {useTheme, makeStyles, Typography, Button} from '@material-ui/core';
import Images from '../assets/imgs/';
import GlobalContext from '../context/globalContext';
import Alert from '@material-ui/lab/Alert';
import ServicePedestalUtilities from './servicePedestalUtilities';
import { fixedLengthNumber, getPedestalImage } from '../global/helpers';
import StatusChip from '../components/statusChip';
import UtilityOverview from '../components/utilityOverview';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  utilities: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  utility: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '3px 10px ',
    cursor: 'pointer',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  utilityIcon: {
    height: 32,
    width: 'auto',
    marginRight: 10
  },
  utilityOverview: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 50
  },
  pedestalImage: {
    width: 220,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  utilityName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '3px 10px '
  },
  utilityVacant: {
    backgroundColor: '#003c73',
    color: '#fff',
    borderRadius: 20,
    padding: '2px 20px',
    width: 100,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 5
  },
  utilityAssigned: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    borderRadius: 20,
    padding: '2px 20px',
    width: 120,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 5
  },
  utilityFault: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    borderRadius: 20,
    padding: '2px 20px',
    width: 100,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 5
  },
  utilityVacantSmall: {
    backgroundColor: '#003c73',
    color: '#fff',
    borderRadius: 20,
    padding: '2px',
    width: 60,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 5
  },
  utilityAssignedSmall: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    borderRadius: 20,
    padding: '2px',
    width: 80,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 5
  },
  utilityFaultSmall: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    borderRadius: 20,
    padding: '2px',
    width: 60,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 5
  },
  pedestalUtilityDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  pedestalUtilityName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonFooter: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'flex-end', 
    marginTop: 30
  }
}));


const ServicePedestal = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(GlobalContext);

  const [pedestal, setPedestal] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [utility, selectUtility] = useState(null);

  useEffect(() => {
    
    const loadPedestal = () => {
      setLoading(true);
      setError(false);
      try {
        let findPed = context.pedestals.data.find(p => p.map_reference === props.data.id);
        if(typeof findPed === 'undefined')
          findPed = context.pedestals.data.find(p => parseInt(p.id_pedestal) === parseInt(props.data.id));

        if(typeof findPed !== 'undefined')
          setPedestal(findPed);
        else {
          setPedestal(null);
          setError('NOT_FOUND');
        }
      } catch (error) {
        console.log(error);
        setPedestal(null);
      } finally{
        setLoading(false);
      }
    }
    loadPedestal();

  }, [props.data])

  //
  // When a utility is updated the data shown may need to be updated
  //
  useEffect(() => {

    if(utility !== null){
      const findUtility = context.utilities.data.find(u => parseInt(u.id_utility) === parseInt(utility.id_utility));
      if(typeof findUtility !== 'undefined')
        selectUtility(findUtility);
    }

  }, [context.utilities])

  return (

      <div className={classes.detailsWrapper}>
        {(!loading && error === 'NOT_FOUND') && 
          <Alert severity="error">Pedestal not found</Alert>
        }
        {(!loading && pedestal !== null) && 
        <React.Fragment>
          <Typography variant="h6">ID: {pedestal.pedestal_reference}</Typography>
          <div className={classes.utilities}>
            <Typography style={{marginBottom: 10}}>Available utilities:</Typography>
            <ServicePedestalUtilities id_pedestal={pedestal.id_pedestal} handleSelectUtility={selectUtility} selected={utility ? utility.id_utility : null} />
          </div>

          <div className={classes.utilityOverview}>
            <div className={classes.pedestalImage}>
              <img src={getPedestalImage(pedestal.id_pedestal_type)} style={{height: '100%', width: 'auto', maxWidth: '100%'}} alt={pedestal.pedestal_type} />
            </div>
            {utility !== null && 
              <div className={classes.pedestalUtilityDetails}>
                <UtilityOverview utility={utility} />
              </div>
            }
            {utility === null && 
              <div className={classes.pedestalUtilityDetails}>
                <Typography variant="h6" color="textSecondary">Select a utility above</Typography>
              </div>
            }
          </div>

          <div style={{flex: 1}}></div>

          <div className={classes.buttonFooter}>
            <Button variant="contained" onClick={() => context.toggleGlobalPanel('utility', true, utility)}>&nbsp;&nbsp;&nbsp;&nbsp;Select&nbsp;&nbsp;&nbsp;&nbsp;</Button>
          </div>
        </React.Fragment>
        }

      </div>
  );
}

export default ServicePedestal;
