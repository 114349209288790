import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import user from "../reducers/userReducer";
import customers from "../reducers/customerReducer";
import profile from "../reducers/profileReducer";
import tariffs from "../reducers/tariffReducer";
import berths from "../reducers/berthReducer";
import berthPedestals from "../reducers/berthPedestalReducer";
import pedestals from "../reducers/pedestalReducer";
import utilities from "../reducers/utilityReducer";
import services from "../reducers/serviceReducer";
import occupancies from "../reducers/occupancyReducer";
import bookings from "../reducers/bookingReducer";
import meta from "../reducers/metaReducer";
import discountGroups from "../reducers/discountGroupReducer";
import prefs from "../reducers/prefsReducer";

export default createStore(
    combineReducers({
        user,
        customers,
        profile,
        tariffs,
        berths,
        berthPedestals,
        pedestals,
        utilities,
        services,
        occupancies,
        bookings,
        meta,
        discountGroups,
        prefs
    }),
    {},
    applyMiddleware(thunk)
)