import React, { useContext, useEffect, useState } from 'react';
import {makeStyles, useTheme, TextField, IconButton, Button, CircularProgress} from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import globalContext from '../context/globalContext';
import { localeCurrency } from '../global/helpers';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const Customers = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const context = useContext(globalContext);

  const [search, setSearch] = useState('');
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRowClick = row => {
    context.toggleGlobalPanel('manage_customer', true, row);
  }

  const headCells = [
    'id_customer',
    'your_ref',
    'first_name',
    'last_name',
    'email',
    'phone',
    'boat_name',
    'type',
    'account_type',
    'balance',
    'currency',
    'billing_frequency',
    'status',
  ]

  const handleSearchChange = e => {
    setSearch(e.target.value);
  }

  const handleSearch = async () => {

      setLoading(false);

      if(search === ''){
        setResults(null);
      } else {
          
        setLoading(true);

        try {

          let arr = [];
          context.customers.data.forEach(c => {
              let idxs = []
              idxs.push(c.id_customer.toString().indexOf(search));
              idxs.push(c.your_ref.toString().indexOf(search));
              idxs.push(`${c.first_name} ${c.last_name}`.toUpperCase().indexOf(search.toUpperCase()));
              idxs.push(c.email.toUpperCase().indexOf(search.toUpperCase()));
              idxs.push(c.boat_name.toUpperCase().indexOf(search.toUpperCase()));
              idxs = idxs.filter(i => i > -1);
              if(idxs.length > 0)
                arr.push({
                    idx: Math.min(...idxs),
                    customer: c
                })
          })

          arr = arr.sort((a, b) => {
              return a.idx < b.idx ? -1 : 1;
          })

          const results = [];
          arr.forEach(c => results.push(c.customer));
          setResults(results);

        } catch (error) {

          setResults(null);
          console.log(error);
            
        } finally {

          setLoading(false);

        }

      }

  }

  useEffect(() => {
    handleSearch()
  }, [search])

  return (
    <div className={classes.container}>

      <PageToolbar label="Customers">
        <div style={{flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20}}>
          <div>
            <Button variant="contained" color="default" style={{alignSelf: 'flex-start'}} onClick={() => context.toggleGlobalPanel('add_customer', true)}>Add New</Button>
          </div>
          <div>
            <TextField label="Search" variant="outlined" placeholder="Joe Bloggs" size="small" InputLabelProps={{shrink: true,}} onChange={handleSearchChange}/>
            {/* <IconButton><TuneIcon /></IconButton> */}
          </div>
        </div>
      </PageToolbar>

      <AutoTable 
        waiting={context.customers.status === 'LOADING'}
        rows={results !== null ? results : context.customers.data} 
        orderBy="id_customer" 
        handleRowClick={handleRowClick} 
        headCells={headCells}
        cellReplacementProps={{
          'balance': (val, idx) => localeCurrency(val, typeof context.customers.data[idx] !== 'undefined' ? context.customers.data[idx].currency : context.profile.data.currency),
          'billing_frequency': (val, idx) => val === null ? 'N/A' : val
        }}
      />
      

    </div>
  );
}

export default Customers;
