import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';
import { makeStyles, useTheme, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useSnackbar  } from 'notistack';

import { connect } from 'react-redux';
import { logout, userValidate } from '../actions/userActions';
import { customersLoad, customerAdd, customerUpdate, customerUpdateBalance } from '../actions/customerActions';
import { berthsLoad, berthAdd, berthUpdate } from '../actions/berthActions';
import { berthPedestalsLoad, berthPedestalAdd, berthPedestalRemove } from '../actions/berthPedestalActions';
import { pedestalsLoad, pedestalAdd, pedestalUpdate } from '../actions/pedestalActions';
import { utilitiesLoad, utilityAdd, utilityUpdate, utilitiesUpdate } from '../actions/utilityActions';
import { profileLoad, profileUpdate } from '../actions/profileActions';
import { tariffsLoad, tariffAdd, tariffUpdate } from '../actions/tariffActions';
import { discountGroupsLoad, discountGroupAdd, discountGroupUpdate } from '../actions/discountGroupActions';
import { servicesLoad, serviceAdd, serviceUpdate } from '../actions/serviceActions';
import { occupanciesLoad, occupancyAdd, occupancyUpdate } from '../actions/occupancyActions';
import { bookingsLoad, bookingAdd, bookingUpdate } from '../actions/bookingActions';
import { prefsLoad, prefsUpdate } from '../actions/prefsActions';
import { metaLoad } from '../actions/metaActions';
import GlobalContext from './globalContext';

import SideSlidePanel from '../components/sideSlidePanel';

import MainToolbar from '../content/mainToolbar';
import JumboMenu from '../content/jumboMenu';
import ChangePassword from '../content/changePassword';
import CustomerAdd from '../content/customerAdd';
import ServiceUtility from '../content/serviceUtility';
import ServiceBerth from '../content/serviceBerth';
import ServicePedestal from '../content/servicePedestal';
import CustomerManage from '../content/customerManage';

import API from '../global/api';
import NetworkAlert from '../components/networkAlert';


//
//  Used for the silent refresh of the token
//
var silent_refresh;
const SOCKET_ENDPOINT = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/v1.0' : '/v1.0';
var SOCKET;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '2%',
    flex: 1
  },
  inner: {
    flex: 1,
    width: '96%',
    display: 'flex',
  }
}))


const Main = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ socketConnected, setSocketConnected ] = useState(false);
  const [ orgLoaded, setOrgLoaded] = useState(false);
  const version = process.env.REACT_APP_VERSION;

  const [showMenu, setShowMenu] = useState(false);

  const globalPanels = ['add_customer', 'manage_customer', 'utility', 'pedestal', 'berth', 'change_password'];

  const getDefaultGlobalPanelProps = () => {
    let panelProps = {};
    globalPanels.forEach(p => {
      panelProps[p] = {
        show: false,
        data: null
      };
    })
    return panelProps;
  }

  const [globalPanelProps, setGlobalPanelProps] = useState(getDefaultGlobalPanelProps());

  const handleShowMenu = () => {
    setShowMenu(true);
  }

  const handleHideMenu = () => {
    setShowMenu(false);
  }

  const toggleGlobalPanel = (panel, show = false, data = null) => {
    let panels = {...globalPanelProps};
    Object.keys(panels).forEach(p => {
      panels[p].show = false;
      panels[p].data = null;
    })
    panels[panel].show = show;
    panels[panel].data = data;
    setGlobalPanelProps(panels);
  }


  const switchOrganisation = (id_organisation) => {

    if(typeof props.user.profile.user.organisations.find(o => o.id_organisation === id_organisation) !== 'undefined'){
      API.setLocal('_bvap', id_organisation);
      props.profileLoad();
    }

  }


  useEffect(() => {
    _socketListeners(true);
  }, [props.user])


  //
  //  Load in the organisation before anything else
  //
  useEffect(() => {
    props.profileLoad();
    props.metaLoad();
  }, [])


  //
  //  Some pages may require acknowledgement that all supporting data
  //  has been loaded
  //
  useEffect(() => {

    if(
      props.services.status !== 'LOADING' &&
      props.tariffs.status !== 'LOADING' &&
      props.customers.status !== 'LOADING' &&
      props.pedestals.status !== 'LOADING' &&
      props.occupancies.status !== 'LOADING' &&
      props.berths.status !== 'LOADING' &&
      props.utilities.status !== 'LOADING'
    )
      setOrgLoaded(true);

  }, [props.services, props.tariffs, props.customers, props.pedestals, props.occupancies, props.berths, props.utilities])


  //
  //  Load in everything else once a profile has been loaded
  //
  useEffect(() => {

    //
    //  Let's load in everything else
    //
    if(props.profile.status === 'OK' && typeof props.profile.data.id_organisation !== 'undefined'){
      _joinRoom();
      setOrgLoaded(false);
      props.customersLoad();
      props.tariffsLoad();
      props.berthsLoad();
      props.berthPedestalsLoad();
      props.pedestalsLoad();
      props.utilitiesLoad();
      props.servicesLoad();
      props.occupanciesLoad();
      props.bookingsLoad();
      props.discountGroupsLoad();
    }
    
  }, [props.profile])

  

  useEffect(() => {
    if(typeof silent_refresh != 'undefined')
      clearTimeout(silent_refresh);

    if(props.user.status === 'in')
      silent_refresh = setTimeout(props.userValidate, Math.floor((props.user.profile.expires*.9)*1000));
  }, [props.user])


  const serviceTitle = () => {
    if(globalPanelProps.utility.show){

      if(globalPanelProps.utility.data === null)
        return 'Enable a Service';

      const service = props.services.data.find(s => s.id_utility === globalPanelProps.utility.data.id_utility && s.end_time === null);
      if(typeof service !== 'undefined')
        return 'Ongoing Service';
      else
        return 'Assign a Service';

    } else 
      return 'Service';
  }

  
  /**
   * 
   * @param {Object} snack The properties of the snack
   * @param {String} snack.messsage e.g. 'Customer saved successfully'
   * @param {String} snack.id The data id to be added to the end of a predfined route by type e.g. B012
   * @param {String} snack.type 1 = /tickets/monitor/:id
   * @param {*} variant info | success | error | warning
   * 
   */
  const _showSnack = (snack, variant = null) => {
    var action = null;

    if(snack.id)
      action = key => (
        <React.Fragment>
          <Button onClick={() => _clickSnack(snack)}>Open</Button>
        </React.Fragment>
      )
    else
        action = key => (
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => { closeSnackbar(key) }}>
                <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        )

    enqueueSnackbar(snack.message, {variant: variant, action});
    
  };

  const _clickSnack = snack => {
    let url = null;

    switch(parseInt(snack.type)){
        case 1:
            url = `/tickets/monitor/${snack.id}`;
            break;
    }

    if(url != null)
      props.history.push(url);
  }


  const _handleIncommingMessage = message => {
    switch(message.type){
      case 'service':
        props.serviceUpdate(message.payload);
        break;
      case 'berth':
        props.berthUpdate(message.payload);
        break;
      case 'booking':
        props.bookingUpdate(message.payload);
        break;
      case 'customer':
        props.customerUpdate(message.payload);
          break;
      case 'occupancy':
        props.occupancyUpdate(message.payload);
          break;
      case 'pedestal':
        props.pedestalUpdate(message.payload);
          break;
      case 'utility':
        props.utilityUpdate(message.payload);
          break;
      case 'utilities':
        props.utilitiesUpdate(message.payload);
          break;
      default:
        // Do nothing
    }
  }


  const _socketListeners = (forceNew = false) => {

    if(forceNew && typeof SOCKET != 'undefined' && SOCKET !== null){
      SOCKET.close();
      SOCKET = null;
    }


    if(!SOCKET)
      SOCKET = io(SOCKET_ENDPOINT, {query: {token: props.user.profile.token}, autoConnect: false});

    if(typeof SOCKET !== 'undefined' && SOCKET !== null){

      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$join'] === 'undefined')
        SOCKET.on('join', () => {
          _joinRoom();
        })
    
      if(typeof SOCKET._callbacks['$message'] == 'undefined')
        SOCKET.on('message', (message) => {
          _handleIncommingMessage(message);
        })
  
  
      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$disconnect'] === 'undefined')
        SOCKET.on('disconnect', () => {
          setSocketConnected(false);
        })

      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$connect'] === 'undefined')
        SOCKET.on('connect', () => {
          _joinRoom();
          setSocketConnected(true);
        });


      if(!SOCKET.connected)
        SOCKET.connect();

    }

  }

  const _joinRoom = () => {
    if(typeof SOCKET !== 'undefined' && SOCKET !== null && props.profile.status === 'OK' && typeof props.profile.data.id_organisation !== 'undefined')
      SOCKET.emit('join', props.profile.data.id_organisation)
    else
      props.profileLoad();
  }

  return (
    <GlobalContext.Provider value={{
      ...props,
      toggleGlobalPanel: toggleGlobalPanel,
      showMenu: handleShowMenu,
      hideMenu: handleHideMenu,
      showSnack: _showSnack,
      version: version,
      socketConnected: socketConnected,
      orgLoaded: orgLoaded,
      switchOrganisation: switchOrganisation
    }}>
      
      {/* Structural components making up the application's frame */}
      <NetworkAlert show={!socketConnected} />
      <JumboMenu show={showMenu}/>
      <MainToolbar />

      {/* Global content which can appear from anywhere anytime */}
      <SideSlidePanel title='Change Password' show={globalPanelProps.change_password.show} handleClose={() => toggleGlobalPanel('change_password', false)} ><ChangePassword /></SideSlidePanel>
      <SideSlidePanel side='right' title='Add a Customer' show={globalPanelProps.add_customer.show} handleClose={() => toggleGlobalPanel('add_customer', false)}><CustomerAdd /></SideSlidePanel>
      <SideSlidePanel side='right' title='Customer' show={globalPanelProps.manage_customer.show} handleClose={() => toggleGlobalPanel('manage_customer', false)}><CustomerManage data={globalPanelProps.manage_customer.data} /></SideSlidePanel>
      <SideSlidePanel side='right' title={serviceTitle()} show={globalPanelProps.utility.show} handleClose={() => toggleGlobalPanel('utility', false)} ><ServiceUtility utility={globalPanelProps.utility.data}/></SideSlidePanel>
      <SideSlidePanel side='right' title='Pedestal' show={globalPanelProps.pedestal.show} handleClose={() => toggleGlobalPanel('pedestal', false)} ><ServicePedestal data={globalPanelProps.pedestal.data} /></SideSlidePanel>
      <SideSlidePanel side='right' title='Berth' show={globalPanelProps.berth.show} handleClose={() => toggleGlobalPanel('berth', false)} ><ServiceBerth data={globalPanelProps.berth.data} handleClose={() => toggleGlobalPanel('berth', false)} /></SideSlidePanel>

      {/* Routing compnents */}
      <div className={classes.container}>
        <div className={classes.inner}>
          {props.children}
        </div>
      </div>
    </GlobalContext.Provider>
  );
}


const mapStateToProps = (state) => {
  return {
    user: state.user,
    customers: state.customers,
    profile: state.profile,
    tariffs: state.tariffs,
    berths: state.berths,
    berthPedestals: state.berthPedestals,
    pedestals: state.pedestals,
    utilities: state.utilities,
    services: state.services,
    occupancies: state.occupancies,
    bookings: state.bookings,
    meta: state.meta,
    discountGroups: state.discountGroups,
    prefs: state.prefs
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    userValidate: () => {
      dispatch(userValidate());
    },
    customersLoad: (payload) => {
      dispatch(customersLoad(payload));
    },
    customerAdd: (payload) => {
        dispatch(customerAdd(payload));
    },
    customerUpdate: (payload) => {
        dispatch(customerUpdate(payload));
    },
    customerUpdateBalance: (payload) => {
        dispatch(customerUpdateBalance(payload));
    },
    profileLoad: (payload) => {
      dispatch(profileLoad(payload));
    },
    profileUpdate: (payload) => {
        dispatch(profileUpdate(payload));
    },
    tariffsLoad: (payload) => {
      dispatch(tariffsLoad(payload));
    },
    tariffAdd: (payload) => {
        dispatch(tariffAdd(payload));
    },
    tariffUpdate: (payload) => {
        dispatch(tariffUpdate(payload));
    },
    berthsLoad: (payload) => {
      dispatch(berthsLoad(payload));
    },
    berthAdd: (payload) => {
        dispatch(berthAdd(payload));
    },
    berthUpdate: (payload) => {
        dispatch(berthUpdate(payload));
    },
    berthPedestalsLoad: (payload) => {
      dispatch(berthPedestalsLoad(payload));
    },
    berthPedestalAdd: (payload) => {
        dispatch(berthPedestalAdd(payload));
    },
    berthPedestalRemove: (payload) => {
        dispatch(berthPedestalRemove(payload));
    },
    pedestalsLoad: (payload) => {
      dispatch(pedestalsLoad(payload));
    },
    pedestalAdd: (payload) => {
        dispatch(pedestalAdd(payload));
    },
    pedestalUpdate: (payload) => {
        dispatch(pedestalUpdate(payload));
    },
    utilitiesLoad: (payload) => {
      dispatch(utilitiesLoad(payload));
    },
    utilityAdd: (payload) => {
        dispatch(utilityAdd(payload));
    },
    utilityUpdate: (payload) => {
        dispatch(utilityUpdate(payload));
    },
    utilitiesUpdate: (payload) => {
        dispatch(utilitiesUpdate(payload));
    },
    servicesLoad: (payload) => {
      dispatch(servicesLoad(payload));
    },
    serviceAdd: (payload) => {
        dispatch(serviceAdd(payload));
    },
    serviceUpdate: (payload) => {
        dispatch(serviceUpdate(payload));
    },
    occupanciesLoad: (payload) => {
      dispatch(occupanciesLoad(payload));
    },
    occupancyAdd: (payload) => {
        dispatch(occupancyAdd(payload));
    },
    occupancyUpdate: (payload) => {
        dispatch(occupancyUpdate(payload));
    },
    bookingsLoad: (payload) => {
      dispatch(bookingsLoad(payload));
    },
    bookingAdd: (payload) => {
        dispatch(bookingAdd(payload));
    },
    bookingUpdate: (payload) => {
        dispatch(bookingUpdate(payload));
    },
    metaLoad: () => {
      dispatch(metaLoad());
    },
    discountGroupsLoad: (payload) => {
      dispatch(discountGroupsLoad(payload));
    },
    discountGroupAdd: (payload) => {
        dispatch(discountGroupAdd(payload));
    },
    discountGroupUpdate: (payload) => {
        dispatch(discountGroupUpdate(payload));
    },
    prefsLoad: () => {
      dispatch(prefsLoad());
    },
    prefsUpdate: (payload) => {
        dispatch(prefsUpdate(payload));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);