import React, { useContext, useEffect, useState } from 'react';
import {makeStyles, Toolbar, useTheme} from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import { secsToTimeStr } from '../global/dateTimeHelpers';
import API from '../global/api';
import globalContext from '../context/globalContext';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ProgressButton from '../components/progressButton';
import DownloadButton from '../components/downloadButton';
import { localeCurrency } from '../global/helpers';
import { SQLtoUTCDate } from '../global/dateTimeHelpers';
import GlobalSelectCustomer from '../content/globalSelectCustomer';

const headCells = ["customer","boat_name","service_type","total_time","consumption","unit","revenue","currency"]

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const ReportUsage = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [data, setData] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [ startDate, setStartDate ] = useState(new Date(new Date().setTime(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))));
  const [ endDate, setEndDate ] = useState(new Date());

  const formatConsumption = (val, idx) => {
    console.log(idx);
    const row = data[idx];
    if(typeof row !== 'undefined')
      switch(parseInt(row.id_unit)){
        case 1:
        case 2:
            return parseFloat(parseInt(val) / 1000).toFixed(2);
        default:
      }

    return val;
  }

  const handleLoadReport = () => {

    if(typeof context.profile.data.id_organisation !== 'undefined'){
      setLoading(true);
  
      API.req(`/services/usage/${context.profile.data.id_organisation}`, 'GET', {
        start: startDate,
        end: endDate,
      }).then(
        async res => {
          setLoading(false);
          if(customer === null)
            setData(res.result);
          else
            setData(res.result.filter(t => parseInt(t.id_customer) === parseInt(customer)))
        },
        err => {
            setLoading(false);
            context.showSnack({message: typeof err === 'string' ? err : JSON.stringify(err)}, 'error')
        }
      )
    }

  }

  const getCurrency = (idx) => {
    const record = data[idx];
    if(typeof record !== 'undefined')
      return record.currency;
    else
      return context.profile.data.currency;
  }

  useEffect(() => {
    handleLoadReport();
  }, [context.profile])

  useEffect(() => {
    handleLoadReport();
  }, [customer])

  return (
    <div className={classes.container}>

      <PageToolbar label="Usage Report"></PageToolbar>
      
      <Toolbar disableGutters={true}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Start"
            format="dd/MM/yyyy"
            inputVariant="standard"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            value={startDate}
            onChange={d => setStartDate(d)}
            style={{marginRight: 10}}
          />
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="End"
            format="dd/MM/yyyy"
            inputVariant="standard"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            value={endDate}
            onChange={d => setEndDate(d)}
          />
        </MuiPickersUtilsProvider>
        <GlobalSelectCustomer handleSelect={(item) => setCustomer(item.id)} handleClear={() => setCustomer(null)} />

        <ProgressButton waiting={loading} variant="contained" color="default" onClick={handleLoadReport}>Generate</ProgressButton>
        <div style={{flex: 1}}></div>
        
        <DownloadButton 
          waiting={downloading} 
          variant="contained" 
          color="default"
          data={data}
          orderBy="customer" 
          headCells={headCells}
          fileName={`usage`}
          cellReplacementProps={{
            'total_time': (val, idx) => secsToTimeStr(val),
            'consumption': (val, idx) => formatConsumption(val, idx),
            'revenue': (val, idx) => <b>{localeCurrency(val)}</b>
          }}
          handleError={(error) => console.log(error)}
        />
        </Toolbar>

      <AutoTable 
        rows={data} 
        orderBy="customer" 
        headCells={headCells}
        loading={context.services.status === 'LOADING'}
        cellReplacementProps={{
          'total_time': (val, idx) => secsToTimeStr(val),
          'consumption': (val, idx) => formatConsumption(val, idx),
          'revenue': (val, idx) => <b>{localeCurrency(val)}</b>
        }} />

    </div>
  );
}

export default ReportUsage;
