import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'fixed',
        zIndex: 1400,
        background: theme.palette.warning.dark,
        display: 'flex',
        width: '100%',
        transition: 'all .5s ease',
        transform: 'translateY(-100%)',
    },
    show: {
        transform: 'translateY(0%)',
    },
    inner: {
        padding: '2px 8px',
        color: '#fff',
        display: 'flex',
        gap: '5px',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 12
    },
}));

const NetworkAlert = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (
        <React.Fragment>
            <div className={clsx([classes.container, props.show && classes.show])}>
                <div className={classes.inner}>
                    <WarningIcon style={{width: 16, color: '#fff'}} />
                    Your connection has been lost.
                </div>
            </div>
        </React.Fragment>
    )
}
export default NetworkAlert;