import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, Button, FormHelperText} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import Images from '../assets/imgs/';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import { keyValueArray } from '../global/helpers';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  boatImage: {
    width: 260,
    height: 130,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginBottom: 60
  },
}));

const BerthAdd = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [ mooringTypes, setMooringTypes ] = useState([]);

  const [ form, setForm ] = useState({
    location_prefix: '',
    location_start: '1',
    berths_count: '1',
    id_mooring_type: 1,
    loa_min: '1',
    loa_max: '10',
    loa_measurement: 'Metres',
    map_reference: ''
  });

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'location_prefix',
      display_name: 'Berth ID prefix',
      required: true
    },
    {
      name: 'location_start',
      display_name: 'Berth ID number',
      required: true
    },
    {
      name: 'berths_count',
      display_name: 'Berths to add',
      required: true
    },
    {
      name: 'id_mooring_type',
      display_name: 'Mooring Type',
      required: true
    },
    {
      name: 'loa_min',
      display_name: 'LOA Min',
      required: true
    },
    {
      name: 'loa_max',
      display_name: 'LOA Max',
      required: true
    },
    {
      name: 'loa_measurement',
      display_name: 'LOA Measurement',
      required: true
    },
    {
      name: 'map_reference',
      display_name: 'Map Reference',
      required: true
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    //
    //  Reset the error and start the waiting game
    //
    setError(null);

    //
    //  Ensure the form is valid first
    //
    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){

      setLoading(true);

      //
      //  Because we may be adding multiple berths
      //  we need to catch any errors that occur along
      //  the way and hault the process
      //      
      let failed = false;
      let err;

      //
      //  Loop through the number of berths that need adding
      //
      for(let i=0; i<parseInt(form.berths_count); i++){

        
        const map_ref_id = form.map_reference.replace('B-', '');

        const berth = {
          berth_reference: `${form.location_prefix}${parseInt(form.location_start) + i}`,
          loa_min: form.loa_min,
          loa_max: form.loa_max,
          loa_measurement: form.loa_measurement,
          map_reference: i===0?form.map_reference:parseInt(map_ref_id) !== NaN?`B-${parseInt(map_ref_id) + i}`:null,
          id_mooring_type: form.id_mooring_type
        }

        //
        // Execute an api request to create a berth and get the returned result
        //
        try {
          const res = await API.req(`/berth/${context.profile.data.id_organisation}`, 'POST', berth);
          await API.addToLocalArray('berths', res.result);
          context.berthAdd(res.result);
        } catch (error) {
          failed = true;
          err = error;
          break;
        }

      }

      //
      //  If the loop was broken because of a failure advise as such
      //
      if(failed){
        setLoading(false);
        setError(typeof err === 'string' ? err : JSON.stringify(err))
      } else {

        //
        //  Everything was added, move on
        //
        if(typeof props.handleClose === 'function')
            props.handleClose();

        context.showSnack({message: 'Berths created!'}, 'success')
      }

    }
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  useEffect(() => {
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.berth_mooring_types !== 'undefined')
      setMooringTypes(keyValueArray(context.meta.data.berth_mooring_types, 'id_mooring_type', 'mooring_type'));

  }, [context.meta])

  return (
      <div className={classes.detailsWrapper}>
        {error && <Alert severity="error">{error}</Alert>}

        <div className={classes.form}>
          
          <div className="flex-row justify-center">
            <div className={classes.boatImage}>
              <img src={Images.Boat_Icons_Blue} style={{width: '100%', height: 'auto'}} alt="Berth" />
            </div>
          </div>


          <div className="flex-row">
            <InputField name='location_prefix' validation={validation.location_prefix} value={form.location_prefix} handleChange={handleInputChange} hoverLabel={true} />
            <InputField name='location_start' validation={validation.location_start} value={form.location_start} handleChange={handleInputChange} hoverLabel={true} />
            <InputField name='berths_count' validation={validation.berths_count} value={form.berths_count} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='loa_min' validation={validation.loa_min} value={form.loa_min} handleChange={handleInputChange} hoverLabel={true} />
            <InputField name='loa_max' validation={validation.loa_max} value={form.loa_max} handleChange={handleInputChange} hoverLabel={true} />
            <InputField name='loa_measurement' validation={validation.loa_measurement} value={form.loa_measurement} handleChange={handleInputChange} hoverLabel={true} type="select" items={['Metres', 'Feet']} />
          </div>

          <div className="flex-row">
            <InputField name='id_mooring_type' validation={validation.id_mooring_type} value={form.id_mooring_type} handleChange={handleInputChange} hoverLabel={true} type="select" items={mooringTypes} />
            <div className="flex"></div>
          </div>

          <div className="flex-row">
            <div className="flex">
              <InputField name='map_reference' validation={validation.map_reference} value={form.map_reference} handleChange={handleInputChange} hoverLabel={true} />
              <FormHelperText>Format must me 'B-X' e.g. 'B-1', numbers will be incremented</FormHelperText>
            </div>
            <div className="flex"></div>
          </div>

        </div>

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Add</ProgressButton>
        </div>

      </div>
  );
}

export default BerthAdd;
