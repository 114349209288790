import React, { useContext, useEffect, useState } from 'react';
import {useTheme, makeStyles} from '@material-ui/core';
import AutoTable from '../components/autoTable';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import globalContext from '../context/globalContext';
import { timeSince, SQLtoUTCDateObj } from '../global/dateTimeHelpers';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40,
    paddingTop: 20
  }
}));

const CustomerLogs = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext)

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logs, setLogs] = useState([])

  const headCells = ['date', 'type']

  useEffect(() => {

    const loadLogs = () => {

      setLoading(true);
      setError(null);

      API.req(`/customer/logs/${context.profile.data.id_organisation}`, 'GET', {id_customer: props.customer.id_customer}).then(
        async res => {

          if(res.success === true){
            setLoading(false);
            setError(null);
            setLogs(res.result);
          } else {
            setLoading(false);
            setError(res.msg);
            setLogs([]);
          }

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
            setLogs([]);
        }
      )

    }
    loadLogs();

  }, [props.customer])

  return (
      <div className={classes.detailsWrapper}>

        {error && <Alert severity="error">{error}</Alert>}

        <AutoTable 
          loading={loading} 
          headCells={headCells}
          rows={logs}
          orderBy="date"
          cellReplacementProps={{
            'date': (val, idx) => timeSince(SQLtoUTCDateObj(val))
          }}
         />
      </div>
  );
}

export default CustomerLogs;
