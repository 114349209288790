import React from 'react';
import {useTheme, makeStyles, Button, Typography, Divider} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { toLocaleDateTime } from '../global/dateTimeHelpers';
import { localeCurrency } from '../global/helpers';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40,
    paddingTop: 20
  }
}));

const CustomerTransaction = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const {transaction, customer} = props

  const handleBack = () => {
    if(typeof props.handleBack === 'function')
      props.handleBack();
  }

  return (
      <div className={classes.detailsWrapper}>

        {!transaction && <Alert severity="error">Transaction not found</Alert>}

        {transaction && 
          <React.Fragment>
            <Typography>Transaction ID: <b>#{transaction.id_transaction}</b></Typography>
            <Typography>Transaction Date: <b>{toLocaleDateTime(transaction.date)}</b></Typography>
            <Typography>Amount: <Typography color={parseFloat(transaction.amount) < 0 ? 'error' : 'default'} variant="body1" component="span"><b>{localeCurrency(transaction.amount, transaction.currency)}</b></Typography></Typography>
            {transaction.tax_rate !== null &&
            <React.Fragment>
              <Typography variant="caption">Sub Total: <b>{transaction.cost_sub !== null ? localeCurrency(transaction.cost_sub, transaction.currency) : localeCurrency(transaction.amount, transaction.currency)}</b></Typography>
              <Typography variant="caption">Tax: <b>{transaction.cost_tax !== null ? localeCurrency(transaction.cost_tax, transaction.currency) : localeCurrency(0, transaction.currency)}</b></Typography>
              <Typography variant="caption">Tax Rate: <b>{transaction.tax_rate !== null ? (parseFloat(transaction.tax_rate)*100)+'%' : '0%'} {transaction.tax_rate_label !== null && `(${transaction.tax_rate_label})`}</b></Typography>
            </React.Fragment>
            }
            <Typography variant="caption">Currency: <b>{transaction.currency}</b></Typography>
            <Divider style={{marginTop: 20, marginBottom: 20}} />
            <Typography>Transaction Type: <b>{transaction.type}</b></Typography>
            <Typography>Your Reference: <b>{transaction.your_ref}</b></Typography>
            <Typography>Description: <b>{transaction.description}</b></Typography>
            <Typography>Balance Bought Forward: <b>{localeCurrency(transaction.customer_balance, transaction.currency)}</b></Typography>
          </React.Fragment>
        }

        <div className="flex"></div>

        <div className="flex-row-end">
          <Button variant="contained" onClick={handleBack}>Back</Button>
        </div>
      </div>
  );
}

export default CustomerTransaction;
