import React from 'react';
import {useTheme, makeStyles, Button, Typography} from '@material-ui/core';
import UtilitySnippet from '../components/utilitySnippet';

const useStyles = makeStyles((theme) => ({
  utilities: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    marginTop: 20,
    marginBottom: 20,
  }
}));

const PedestalManageUtilities = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const handleAddUtility = () => {
    if(typeof props.handleAddUtility === 'function')
      props.handleAddUtility();
  }


  return (
    <div className={classes.utilities}>
      <Typography style={{marginBottom: 10}}>Available utilities:</Typography>
    
      {props.data && 
        <div className={classes.list}>
          {props.data.map((u, idx) => (
              <UtilitySnippet utility={u} key={idx} />
          ))}
        </div>
      }

      
      <div className="flex-row-end">
        <Button variant="contained" onClick={handleAddUtility}>Add Utility</Button>
      </div>

    </div>
  );
}

export default PedestalManageUtilities;
