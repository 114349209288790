import React from 'react';
import { Switch, useTheme, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    root: {
        width: 62,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(36px)',
          color: theme.palette.primary.main,
          '& + $track': {
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.common.white,
            opacity: 1,
            '&::after': {
                content: '"Yes"',
                paddingLeft: 9
            }
          },
          '& $thumb': {
            color: theme.palette.primary.main,
          },
        }
      },
      thumb: {
        color: theme.palette.grey[400],
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[200],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
        '&::after': {
            content: '"No"',
            color: theme.palette.grey[800],
            display: 'block',
            paddingLeft: 32,
            paddingTop: 3
        }
      },
      checked: {},
      focusVisible: {},
}));


const BooleanSwitch = props => {

    const theme = useTheme();
    const classes = useStyles(theme);

    const handleSwitchChange = e => {
        if(typeof props.handleSwitchChange === 'function')
            props.handleSwitchChange(e);
    }

    return (
        <Switch
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            checked={props.checked}
            onChange={handleSwitchChange}
            name={props.name}
        />
    )
}

export default BooleanSwitch