import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import {keyValueArray} from '../global/helpers';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  }
}));

const DiscountGroupAdd = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ form, setForm ] = useState({
    discount_name: null,
    discount_rate: 0.00
  });

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'discount_name',
      display_name: 'Discount Group Name',
      required: true
    },
    {
      name: 'discount_rate',
      display_name: 'Discount Rate (%)',
      required: true,
      additional: [
        {
          type: 'above',
          value: 0,
          error: 'Tax rate must be a percentage between 0 and 100%'
        },
        {
          type: 'below',
          value: 100,
          error: 'Tax rate must be a percentage between 0 and 100%'
        }
      ]
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = () => {

    setError(null);
    setLoading(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){

      //
      // Execute an api request and get the returned result
      //
      API.req(`/discountgroup/${context.profile.data.id_organisation}`, 'POST', form).then(
        res => {
          setLoading(false);
          context.discountGroupAdd(res.result);

          if(typeof props.handleClose === 'function')
            props.handleClose();

          context.showSnack({message: 'Discount Group added!'}, 'success')

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )

    }
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  return (
      <div className={classes.detailsWrapper}>

        {error && <Alert severity="error">{error}</Alert>}

        <div className={classes.form}>

          
          <div className="flex-row">
            <InputField name='discount_name' validation={validation.discount_name} value={form.discount_name} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField type="number" name='discount_rate' validation={validation.discount_rate} value={form.discount_rate} handleChange={handleInputChange} hoverLabel={true} />
          </div>

        </div>

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Add</ProgressButton>
        </div>

      </div>
  );
}

export default DiscountGroupAdd;
