import React from 'react';
import {useTheme, makeStyles, Typography} from '@material-ui/core';

import Images from '../assets/imgs/index';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 20px',
    alignItems: 'center'
  },
  avatar: {
    marginLeft: 10,
    width: 30,
    height: 30,
    padding: 2,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`
  }
}));


const AccountAvatar = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const {user, organisation} = props;

  const handleClick = e => {
    if(typeof props.handleClick === 'function')
      props.handleClick(e);
  }

  return (
    <div className={classes.wrapper} onClick={handleClick}>
        <Typography component="span" style={{lineHeight: '1em'}}>{organisation.data.organisation_name}</Typography>
        <div className={classes.avatar}>
          <img src={Images.Icons_User_Icon} alt={organisation.data.organisation_name} />
        </div>
    </div>
  );
}

export default AccountAvatar;
