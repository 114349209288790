import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, Button, FormHelperText} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import { keyValueArray, getPedestalImage } from '../global/helpers';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  pedestalImage: {
    width: 300,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
}));

const PedestalAdd = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [ pedestalTypes, setPedestalTypes ] = useState([]);

  const [ form, setForm ] = useState({
    pedestal_prefix: '',
    pedestal_start: '1',
    pedestals_count: '1',
    id_pedestal_type: 4,
    map_reference: ''
  });

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'pedestal_prefix',
      display_name: 'Pedestal Ref prefix',
      required: true
    },
    {
      name: 'pedestal_start',
      display_name: 'Pedestal Ref number',
      required: true
    },
    {
      name: 'pedestals_count',
      display_name: 'Pedestals to add',
      required: true
    },
    {
      name: 'id_pedestal_type',
      display_name: 'Pedestal Type',
      required: true
    },
    {
      name: 'map_reference',
      display_name: 'Map Reference',
      required: false
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    //
    //  Reset the error and start the waiting game
    //
    setError(null);

    //
    //  Ensure the form is valid first
    //
    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){

      setLoading(true);

      //
      //  Because we may be adding multiple berths
      //  we need to catch any errors that occur along
      //  the way and hault the process
      //      
      let failed = false;
      let err;

      //
      //  Loop through the number of berths that need adding
      //
      for(let i=0; i<parseInt(form.pedestals_count); i++){

        
        const map_ref_id = form.map_reference.replace('P-', '');

        const pedestal = {
          pedestal_reference: `${form.pedestal_prefix}${parseInt(form.pedestal_start) + i}`,
          id_pedestal_type: form.id_pedestal_type,
          map_reference: i===0?form.map_reference:parseInt(map_ref_id) !== NaN?`P-${parseInt(map_ref_id) + i}`:null 
        }

        //
        // Execute an api request to create a berth and get the returned result
        //
        try {
          const res = await API.req(`/pedestal/${context.profile.data.id_organisation}`, 'POST', pedestal);
          await API.addToLocalArray('pedestal', res.result);
          context.pedestalAdd(res.result);
        } catch (error) {
          failed = true;
          err = error;
          break;
        }

      }

      //
      //  If the loop was broken because of a failure advise as such
      //
      if(failed){
        setLoading(false);
        setError(typeof err === 'string' ? err : JSON.stringify(err))
      } else {

        //
        //  Everything was added, move on
        //
        if(typeof props.handleClose === 'function')
            props.handleClose();

        context.showSnack({message: 'Pedestal(s) created!'}, 'success')
      }

    }
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  

  useEffect(() => {
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.pedestal_types !== 'undefined')
      setPedestalTypes(keyValueArray(context.meta.data.pedestal_types, 'id_pedestal_type', 'pedestal_type'));

  }, [context.meta])

  return (
      <div className={classes.detailsWrapper}>
        {error && <Alert severity="error">{error}</Alert>}

        <div className={classes.form}>

          <div className="flex-row">
            <InputField name='pedestal_prefix' validation={validation.pedestal_prefix} value={form.pedestal_prefix} handleChange={handleInputChange} hoverLabel={true} />
            <InputField name='pedestal_start' validation={validation.pedestal_start} value={form.pedestal_start} handleChange={handleInputChange} hoverLabel={true} />
            <InputField name='pedestals_count' validation={validation.pedestals_count} value={form.pedestals_count} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='id_pedestal_type' validation={validation.id_pedestal_type} value={form.id_pedestal_type} handleChange={handleInputChange} hoverLabel={true} type="select" items={pedestalTypes} />
            
            <div className="flex">
              <InputField name='map_reference' validation={validation.map_reference} value={form.map_reference} handleChange={handleInputChange} hoverLabel={true} />
              <FormHelperText>Format must me 'P-X' e.g. 'P-1', numbers will be incremented</FormHelperText>
            </div>
          </div>

          <div className="flex-row justify-center">
            <div className={classes.pedestalImage}>
              <img src={getPedestalImage(form.id_pedestal_type)} style={{height: '100%', width: 'auto'}} alt="Quantum" />
            </div>
          </div>

        </div>

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Add</ProgressButton>
        </div>

      </div>
  );
}

export default PedestalAdd;
