import React, { useContext } from 'react';
import {makeStyles, useTheme, Tooltip} from '@material-ui/core';
import globalContext from '../context/globalContext.js';
import clsx from 'clsx';
import PowerIcon from '@material-ui/icons/Power';
import images from '../assets/imgs/index.js';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 22,
    color: '#dddddd'
  },
  iconOn: {
    color: theme.palette.success.main
  },
  reserved: {
    color: theme.palette.reserved.main
  },
  imageIcon: {
    height: 22,
    width: 'auto'
  },
}))

const UtilityStatusLights = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  
  const service = (typeof props.utility !== 'undefined' && props.utility !== null) ? context.services.data.find(s => parseInt(s.id_utility) === parseInt(props.utility.id_utility) && s.end_time === null && s.id_session !== null) : null;  

  return (

    <React.Fragment>

      {props.utility &&

        <React.Fragment>

          {(props.utility.utility_type.toUpperCase() === 'ELECTRICITY' && (typeof service === 'undefined' || Boolean(service.paused))) && <Tooltip title="Service Off"><img src={images.Icons_Electric_Fill_Grey_Icon} alt="Service Off" className={classes.imageIcon} /></Tooltip>}
          {(props.utility.utility_type.toUpperCase() === 'ELECTRICITY' && (typeof service !== 'undefined' && !Boolean(service.paused))) && <Tooltip title="Service On"><img src={images.Icons_Electric_Fill_Green_Icon} alt="Service On" className={classes.imageIcon} /></Tooltip>}

          {(props.utility.utility_type.toUpperCase() === 'WATER' && (typeof service === 'undefined' || Boolean(service.paused))) && <Tooltip title="Service Off"><img src={images.Icons_Water_Fill_Grey_Icon} alt="Service Off" className={classes.imageIcon} /></Tooltip>}
          {(props.utility.utility_type.toUpperCase() === 'WATER' && (typeof service !== 'undefined' && !Boolean(service.paused))) && <Tooltip title="Service On"><img src={images.Icons_Water_Fill_Green_Icon} alt="Service On" className={classes.imageIcon} /></Tooltip>}
          
          {(props.utility.utility_type.toUpperCase() === 'ELECTRICITY' && props.utility.connected !== null) && <Tooltip title={`${!props.utility.connected ? 'Not ' : ''}Plugged in`}><PowerIcon className={clsx([classes.icon, props.utility.connected && classes.iconOn])}/></Tooltip>}

          {(props.utility.utility_type.toUpperCase() === 'ELECTRICITY' && props.utility.breakerOk !== null && props.utility.breakerOk) && <Tooltip title="Breaker OK"><img src={images.Icons_ElectricOff_Grey_Icon} alt="" className={classes.imageIcon} /></Tooltip>}
          {(props.utility.utility_type.toUpperCase() === 'ELECTRICITY' && props.utility.breakerOk !== null && !props.utility.breakerOk) && <Tooltip title="Breaker Tripped"><img src={images.Icons_ElectricOff_Red_Icon} alt="" className={classes.imageIcon} /></Tooltip>}
          
        </React.Fragment>

      }

    </React.Fragment>


  );
}

export default UtilityStatusLights;