import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, Button} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import Images from '../assets/imgs/';
import PedestalManageUtilities from './pedestalManageUtilities';
import StackSlidePanel from '../components/stackSlidePanel';
import UtilityAdd from '../content/utilityAdd';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import { keyValueArray, getPedestalImage } from '../global/helpers';
import API from '../global/api';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  pedestalImage: {
    width: 160,
    height: 160,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  utilities: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const PedestalEdit = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [ pedestalTypes, setPedestalTypes ] = useState([]);

  const [ form, setForm ] = useState(props.data);

  const [ addUtility, setAddUtility ] = useState(false);
  const [ utilities, setUtilities ] = useState([]);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'pedestal_reference',
      display_name: 'Pedestal Reference',
      required: true
    },
    {
      name: 'map_reference',
      display_name: 'Map Reference',
      required: false
    },
    {
      name: 'id_pedestal_type',
      display_name: 'Pedestal Type',
      required: true
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    setError(null);
    setLoading(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){


      //
      // Execute an api request and get the returned result
      //
      API.req(`/pedestal/${context.profile.data.id_organisation}`, 'PUT', form).then(
        async res => {
          setLoading(false);

          context.pedestalUpdate(res.result);

          if(typeof props.handleClose === 'function')
            props.handleClose();

          context.showSnack({message: 'Pedestal updated!'}, 'success')

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )

    }
  }

  const getUtilities = () => {
    const utils = context.utilities.data.filter(u => parseInt(u.id_pedestal) === parseInt(props.data.id_pedestal));
    setUtilities(JSON.parse(JSON.stringify(utils)));
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  useEffect(() => {
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.pedestal_types !== 'undefined')
      setPedestalTypes(keyValueArray(context.meta.data.pedestal_types, 'id_pedestal_type', 'pedestal_type'));

  }, [context.meta])

  useEffect(() => {
    getUtilities();
  }, [context.utilities.data])
  
  return (
      <React.Fragment>
        
        <div className={classes.detailsWrapper}>

          {error && <Alert severity="error">{error}</Alert>}

          <div className={classes.form}>

            <div className="flex-row justify-center">
              <div className={classes.pedestalImage}>
                <img src={getPedestalImage(form.id_pedestal_type)} style={{height: '100%', width: 'auto'}} alt="Quantum" />
              </div>

              <div className="flex">
                <div className="flex-row">
                  <InputField name='pedestal_reference' validation={validation.pedestal_reference} value={form.pedestal_reference} handleChange={handleInputChange} hoverLabel={true} />
                  <InputField name='map_reference' validation={validation.map_reference} value={form.map_reference} handleChange={handleInputChange} hoverLabel={true} />
                </div>

                <div className="flex-row">
                  <InputField name='id_pedestal_type' validation={validation.id_pedestal_type} value={form.id_pedestal_type} handleChange={handleInputChange} hoverLabel={true} type="select" items={pedestalTypes} />
                </div>

                <div className="flex-row-end">
                  <ProgressButton waiting={loading}  variant="contained" onClick={handleSubmit}>Save</ProgressButton >
                </div>
              </div>
            </div>

            <PedestalManageUtilities data={utilities} handleAddUtility={() => setAddUtility(true)} />

          </div>

        </div>

        {props.data !== null && 
          <StackSlidePanel title={'Add Utility'} show={addUtility} handleClose={() => setAddUtility(false)}>
            <UtilityAdd id_pedestal={props.data.id_pedestal} handleClose={() => setAddUtility(false)} />
          </StackSlidePanel>
        }

      </React.Fragment>
  );
}

export default PedestalEdit;
