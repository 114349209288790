import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme, Button, Typography, CircularProgress} from '@material-ui/core';
import PageToolbar from '../components/pageToolbar';
import globalContext from '../context/globalContext';
import API from '../global/api';
import { useParams } from 'react-router';
import ProgressButton from '../components/progressButton';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: 600,
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const InviteAccept = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const {token} = useParams();

  const [invite, setInvite] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);


  const handleAccept = () => {

    setWaiting(true);

    API.req(`/user/invite/accept/${token}`, 'POST').then(
      res => {
        setWaiting(false);

        if(res.success === true){
          context.showSnack({message: `Invite accepted! You have joined ${invite.organisation_name}`}, 'success')
          props.history.push('/');
        } else {
          setError(res.msg);
        }
      },
      err => {
        setWaiting(false);
        setError(err)
      }
    )

  }
  

  useEffect(() => {

    setWaiting(true);

    API.req(`/user/invite/${token}`, 'GET').then(
      res => {
        setWaiting(false);

        if(res.success === true){
          if(res.result === null)
            props.history.push('/');

          setInvite(res.result);
        } else
          setError(res.msg);
      },
      err => {
        setWaiting(false);
        setError(err)
      }
    )

  }, [context.user])

  return (
    <div className={classes.container}>

      {error && <Typography variant='caption' color='error'>{error}</Typography>}

      {waiting && <CircularProgress size={24} />}

      {(waiting === false && invite !== null) && 
        <div className={classes.container}>
          <PageToolbar label="Accept Invite"></PageToolbar>
          <Typography>You have been invited to manage {invite.organisation_name} and it looks like you are already signed in. You can either accept the invitation and attach this marina to this account or sign out and use or register another account.</Typography>
          <div style={{marginTop: 30}}>
            <ProgressButton variant="contained" color="primary" onClick={handleAccept}>Accept</ProgressButton>
            <ProgressButton onClick={context.logout}>Use another account</ProgressButton>
          </div>
        </div>
      }

      {(waiting === false && invite === null) && 
        <React.Fragment>
          <Typography>This invite is no longer valid.</Typography>
        </React.Fragment>
      }
    </div>
  );
}

export default InviteAccept;
