import React, { useContext, useState } from 'react';
import InputField from '../components/inputField';
import globalContext from '../context/globalContext';
import { makeValidation } from '../global/formHelper';

const ServiceUtilitySelectPedestal = props => {

    const context = useContext(globalContext);
    const [pedestals, setPedestals] = useState([]);
    const [pedestal, setPedestal] = useState(null);

    const [validation, setValidation] = useState(makeValidation([{
        name: 'pedestal',
        display_name: 'Select Pedestal',
        required: false
    }]))

    const handleSelectChange = e => {
        setPedestal(e.target.value);
        if(typeof props.handleSelect === 'function'){
            const selectedPedestal = context.pedestals.data.find(p => p.id_pedestal === e.target.value);
            if(typeof selectedPedestal !== 'undefined')
                props.handleSelect(selectedPedestal);
        }
    }

    useState(() => {
        const loadPedestals = () => {
            let newPedestals = [];
            context.pedestals.data.forEach(p => {
                newPedestals.push({
                    key: p.id_pedestal,
                    value: p.pedestal_reference
                })
            });
            setPedestals(newPedestals);
        }
        loadPedestals();
    }, [])
    
    return (
        <InputField 
            name='pedestal'  
            validation={validation.pedestal}
            value={pedestal}
            handleChange={handleSelectChange} 
            hoverLabel={true} 
            placeholder='Select pedestal'
            type="select"
            items={pedestals}
        />
    )

}

export default ServiceUtilitySelectPedestal;