import React from 'react';
import {InputLabel, makeStyles, TextField, useTheme, Select, MenuItem, InputAdornment, CircularProgress, IconButton} from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  large: {
    padding: '30px 22px 14px'
  },
  largeLabel: {
    transform: 'translate(22px, 26px) scale(1)'
  },
  small: {
    padding: '18px 18px 6px'
  },
  smallLabel: {
    transform: 'translate(22px, 18px) scale(1)'
  },
  inputLabel: {
    fontSize: '.8rem',
    color: theme.palette.text.primary,
    marginBottom: 4,
    marginTop: -8,
    fontWeight: 600
  },
  inputNoLabel: {
    padding: '19px 12px 18px'
  },
  inputLargeNoLabel: {
    padding: '22px 22px 22px'
  },
  inputSmallNoLabel: {
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
  },
  select: {
    width: '100%'
  }
}))

const InputField = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {validation, name, value, handleChange, variant, size} = props;
  const type = props.type || 'text';
  const hoverLabel = props.hoverLabel || false;
  const items = props.items || [];
  const placeholder = props.placeholder || null;
  const autoFocus = props.autoFocus || false;
  const inputRef = props.inputRef || null;

  const isText = (type === 'textfield' || type === 'select') ? false: true;
  
  const inputProps = () => {
    let inputClasses = [];
    if(typeof size !== 'undefined' && size.toUpperCase() === 'LARGE')
      inputClasses.push(classes.large);
    if(typeof size !== 'undefined' && size.toUpperCase() === 'SMALL')
      inputClasses.push(classes.small);

    if(typeof hoverLabel !== 'undefined' && hoverLabel === true)
      if(typeof size !== 'undefined' && size.toUpperCase() === 'LARGE')
        inputClasses.push(classes.inputLargeNoLabel);
      else if(typeof size !== 'undefined' && size.toUpperCase() === 'SMALL')
        inputClasses.push(classes.inputSmallNoLabel);
      else
        inputClasses.push(classes.inputNoLabel);
      

    let inputPs = {classes: {}};
    if(!isText && type === 'select')
      inputPs.classes.select = clsx(inputClasses);
    else
      inputPs.classes.input = clsx(inputClasses);


    //
    //  Adornments
    //


    if(typeof props.handleClear === 'function' && value !== '')
      inputPs['endAdornment'] = <InputAdornment position="end"><IconButton onClick={props.handleClear} size="small"><CloseIcon style={{fontSize: 18}}/></IconButton></InputAdornment>;

    
    if(typeof props.loading !== 'undefined' && props.loading === true)
      inputPs['endAdornment'] = <InputAdornment position="end"><CircularProgress color='inherit' size={18} /></InputAdornment>;
      

    return inputPs;
  }
  
  const inputLabelProps = () => {
    let props = {};
    if(typeof size !== 'undefined' && size.toUpperCase() === 'LARGE')
      props = {
        classes: {
          root: classes.largeLabel
        }
      }

    return props;
  }

  const getLabel = () => {
    let label = '';
    if(typeof validation === 'undefined' || typeof validation.display_name === 'undefined')
      label = name;
    else
      label = validation.display_name;
      
    if(typeof validation !== 'undefined' && validation.required === true)
      label += ' *';

    return label
  }

  const handleKeyDown = e => {
    if(typeof props.handleKeyDown === 'function')
      props.handleKeyDown(e);
  }

  return (
        <div className="input-control">
          {hoverLabel && <InputLabel className={classes.inputLabel}>{getLabel()}</InputLabel>}
          {isText && 
            <TextField 
              type={type} 
              name={name} 
              label={hoverLabel ? false : getLabel()} 
              variant={typeof variant !== 'undefined' ? variant : 'filled'} 
              fullWidth 
              value={value} 
              onChange={handleChange} 
              onKeyDown={handleKeyDown} 
              error={validation.dirty && validation.error} 
              helperText={validation.dirty && validation.error ? validation.msg : ''}
              InputProps={inputProps()}
              InputLabelProps={inputLabelProps()}
              placeholder={placeholder}
              autoComplete="off"
              autoFocus={autoFocus}
              inputRef={inputRef}
            />
          }
          {type === 'select' && 
            <Select  
              name={name} 
              label={hoverLabel ? false : getLabel()} 
              variant={typeof variant !== 'undefined' ? variant : 'filled'} 
              value={value} 
              onChange={handleChange} 
              error={validation.dirty && validation.error} 
              className={classes.select}
              inputProps={inputProps()}
            >
              {
                items.map((item, idx) => {
                  if(typeof item === 'object')
                    return (<MenuItem value={item.key} key={idx}>{item.value}</MenuItem>)
                  else
                    return (<MenuItem value={item} key={idx}>{item}</MenuItem>)
                })
              }
            </Select>
          }
        </div>
  );
}

export default InputField;
