import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {useTheme, makeStyles, IconButton, Link, Typography, Grid, Paper} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Images from '../assets/imgs/index';
import ProgressDonut from '../components/progressDonut';
import globalContext from '../context/globalContext';

const useStyles = makeStyles((theme) => ({
  containerOut: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: 999,
    backgroundColor: '#f2f2f2',
    overflow: 'auto',
    transform: 'translateX(-100%)',
    visibility: 'hidden',
    left: 0,
    top: 0,
    transition: 'all .3s ease',
    flexDirection: 'row',
  },
  containerIn: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: 999,
    backgroundColor: '#f2f2f2',
    overflow: 'auto',
    transform: 'translateX(0)',
    left: 0,
    top: 0,
    transition: 'all .3s ease',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: theme.palette.action.disabled,
    zIndex: 300
  },
  leftPanel: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      flex: 1,
      minHeight: '100%',
      overflow: 'auto',
    },
  },
  leftInner: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    paddingTop: '10%',
    maxWidth: 600,
    [theme.breakpoints.up('md')]: {
      maxWidth: 720
    },
  },
  rightPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '50%',
    [theme.breakpoints.up('md')]: {
      flex: 1,
      minHeight: '100%',
      overflow: 'auto',
      justifyContent: 'center',
    },
  },
  rightInner: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    paddingTop: '10%',
    paddingBottom: '10%',
  },
  logo: {
    marginTop: 20,
    marginBottom: 40,
    height: 46
  },
  donuts: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    [theme.breakpoints.up('md')]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
    [theme.breakpoints.up('lg')]: { 
      paddingTop: 40,
      paddingBottom: 40,
    }
  },
  boxLinks: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
      marginBottom: 20,
    },
    [theme.breakpoints.up('lg')]: { 
      marginTop: 40,
      marginBottom: 40,
    }
  },
  boxLink: {
    width: "28%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: 'pointer',
    marginTop: 10,
    marginBottom: 10,
    height: 100,
    maxWidth: 160,
    [theme.breakpoints.up('md')]: {
      height: 140
    },
    [theme.breakpoints.up('lg')]: { 
      height: 200,
      maxWidth: 200,
    }
  },
  boxLinkInner: {
    display: 'flex',
    flex: 1,
    width: '100%',
    maxWidth: 150,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  boxLinkText: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('lg')]: { 
      fontSize: 24,
    }
  },
  boxLinkIcon: {
    width: 'auto',
    marginBottom: 10,
    height: 40,
    [theme.breakpoints.up('md')]: {
      height: 50,
    },
    [theme.breakpoints.up('lg')]: { 
      height: 80,
    }
  },
  boxLinkLabel: {
    lineHeight: '1em',
    fontWeight: 600,
    fontSize: 20,
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
      padding: 4,
      marginBottom: 4,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 60,
      padding: 10,
      marginBottom: 10,
    }
  },
  menuLink: {
    paddingTop: 8,
    paddingBottom: 8,
    cursor: 'pointer'
  },
  menuLinkBold: {
    paddingTop: 8,
    paddingBottom: 8,
    cursor: 'pointer',
    fontWeight: 600
  }
}));


const JumboMenu = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const {show} = props;

  const [stats, setStats] = useState({
    utilitiesTotal: 0,
    utilitiesVacant: 0,
    utilitiesAssigned: 0,
    utilitiesFault: 0,
    upcomingServices: 0,
    outstandingBalances: 0
  })

  const handleClose = () => {
    if(typeof context.hideMenu === 'function')
      context.hideMenu();
  }

  const handleChangeRoute = route => {
    handleClose();
    if(typeof props.history !== 'undefined')
      props.history.push(route);
  } 

  const handleOpenPanel = panel => {
    handleClose();
    if(typeof context.toggleGlobalPanel === 'function')
      context.toggleGlobalPanel(panel, true);
  }

  const handleLogout = () => {
    handleClose();
    if(typeof context.logout === 'function')
      context.logout();
  }

  useEffect(() => {

    let newStats = {
      utilitiesTotal: 0,
      utilitiesVacant: 0,
      utilitiesAssigned: 0,
      utilitiesFault: 0,
      upcomingServices: 0,
      outstandingBalances: 0
    }

    newStats.utilitiesTotal = context.utilities.data.length;
    newStats.utilitiesFault = context.utilities.data.filter(u => u.network_status === 'offline' || u.service_status === 'fault').length;
    newStats.utilitiesAssigned = context.utilities.data.filter(u => u.network_status === 'online' && u.service_status === 'assigned').length;
    newStats.utilitiesVacant = context.utilities.data.filter(u => u.network_status === 'online' && u.service_status === 'vacant').length;
    newStats.upcomingServices = context.services.data.filter(s => new Date(s.start_time) > new Date() && s.end_time === null).length;
    newStats.outstandingBalances = context.customers.data.filter(c => parseFloat(c.balance) < 0).length;

    setStats(newStats);

  }, [context.utilities, context.services, context.customers])

  return (
    <div className={show ? classes.containerIn : classes.containerOut}>
        <IconButton onClick={handleClose} className={classes.close}><CloseIcon style={{fontSize: 40}} /></IconButton>
        <div className={classes.leftPanel}>
          <div className={classes.leftInner}>
            <Link onClick={() => {handleChangeRoute('/')}}><img src={Images.logo} className={classes.logo} alt="BerthVend" /></Link>

            {show && 
            <div className={classes.donuts}>
              <ProgressDonut value={stats.utilitiesVacant} total={stats.utilitiesTotal} color="#003C73" label="Vacant" />
              <ProgressDonut value={stats.utilitiesAssigned} total={stats.utilitiesTotal} color="#6CB33F" label="Assigned" />
              <ProgressDonut value={stats.utilitiesFault} total={stats.utilitiesTotal} color="#FF0000" label="Out of service" />
            </div>
            }

            <div className={classes.boxLinks}>
              <Paper className={classes.boxLink} onClick={() => {handleOpenPanel('utility')}}>
                <div className={classes.boxLinkInner}>
                  <img src={Images.Icons_Power_Icon} className={classes.boxLinkIcon} alt="Enable a Utility" />
                  <Typography variant="h5" className={classes.boxLinkText}>Enable a<br />Service</Typography>
                </div>
              </Paper>
              <Paper className={classes.boxLink} onClick={() => {handleOpenPanel('add_customer')}}>
                <div className={classes.boxLinkInner}>
                  <img src={Images.Icons_Add_Customer_Icon} className={classes.boxLinkIcon} alt="Add a Customer" />
                  <Typography variant="h5" className={classes.boxLinkText}>Add a<br />Customer</Typography>
                </div>
              </Paper>
              <Paper className={classes.boxLink} onClick={() => {handleOpenPanel('berth')}}>
                <div className={classes.boxLinkInner}>
                  <img src={Images.Icons_Berth_Icon} className={classes.boxLinkIcon} alt="Assign a Berth" />
                  <Typography variant="h5" className={classes.boxLinkText}>Assign a<br />Berth</Typography>
                </div>
              </Paper>
              <Paper className={classes.boxLink} onClick={() => {handleChangeRoute('/services/upcoming')}}>
                <div className={classes.boxLinkInner}>
                  <Typography variant="h2" className={classes.boxLinkLabel} color="primary">{stats.upcomingServices}</Typography>
                  <Typography variant="h5" className={classes.boxLinkText}>Service<br />Reservations</Typography>
                </div>
              </Paper>
              <Paper className={classes.boxLink} onClick={() => {handleChangeRoute('/customers/billing')}}>
                <div className={classes.boxLinkInner}>
                  <Typography variant="h2" className={classes.boxLinkLabel} color="primary">{stats.outstandingBalances}</Typography>
                  <Typography variant="h5" className={classes.boxLinkText}>Outstanding<br />Payments</Typography>
                </div>
              </Paper>
              {/* <Paper className={classes.boxLink} onClick={() => {handleChangeRoute('/services/upcoming')}}>
                <div className={classes.boxLinkInner}>
                  <Typography variant="h2" className={classes.boxLinkLabel} color="primary">{stats.upcomingServices}</Typography>
                  <Typography variant="h5" className={classes.boxLinkText}>Berth Reservations</Typography>
                </div>
              </Paper> */}
            </div>

          </div>
        </div>
        <div className={classes.rightPanel}>
          <div className={classes.rightInner}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={5}>
                <Typography variant="h6" color="primary">Services</Typography>
                <Link onClick={() => {handleChangeRoute('/')}}><Typography className={classes.menuLink}>Marina</Typography></Link>
                <Link onClick={() => {handleOpenPanel('utility')}}><Typography className={classes.menuLink}>Enable a Service</Typography></Link>
                <Link onClick={() => {handleChangeRoute('/services/assigned')}}><Typography className={classes.menuLink}>Assigned Services</Typography></Link>
                <Link onClick={() => {handleChangeRoute('/services/upcoming')}}><Typography className={classes.menuLink}>Upcoming Services</Typography></Link>
              </Grid>
              <Grid item xs={12} sm={6} lg={5}>
                <Typography variant="h6" color="primary">Customers</Typography>
                <Link onClick={() => {handleOpenPanel('add_customer')}}><Typography className={classes.menuLink}>New Customer</Typography></Link>
                <Link onClick={() => {handleChangeRoute('/customers')}}><Typography className={classes.menuLink}>Manage Customers</Typography></Link>
                <Link onClick={() => {handleChangeRoute('/customers/billing')}}><Typography className={classes.menuLink}>Billing</Typography></Link>
              </Grid>
              <Grid item xs={12} sm={6} lg={5}>
                <Typography variant="h6" color="primary">Manage</Typography>
                <Link onClick={() => {handleChangeRoute('/profile/tariffs')}}><Typography className={classes.menuLink}>Manage Tariffs</Typography></Link>
                <Link onClick={() => {handleChangeRoute('/profile')}}><Typography className={classes.menuLink}>Marina Profile</Typography></Link>
                <Link onClick={() => {handleChangeRoute('/services/utilities_overview')}}><Typography className={classes.menuLink}>Manage Utilities</Typography></Link>
              </Grid>
              <Grid item xs={12} sm={6} lg={5}>
                <Typography variant="h6" color="primary">Reporting</Typography>
                <Link onClick={() => {handleChangeRoute('/reports/usage')}}><Typography className={classes.menuLink}>Usage Report</Typography></Link>
                <Link onClick={() => {handleChangeRoute('/reports/transactions')}}><Typography className={classes.menuLink}>Transaction Report</Typography></Link>
                <Link onClick={() => {handleChangeRoute('/reports/revenue')}}><Typography className={classes.menuLink}>Revenue Report</Typography></Link>
              </Grid>
              <Grid item xs={12} sm={6} lg={5}>
                <Typography variant="h6" color="primary">Admin</Typography>
                <Link onClick={() => {handleOpenPanel('change_password')}}><Typography className={classes.menuLink}>Change Password</Typography></Link>
                <Link onClick={handleLogout}><Typography className={classes.menuLinkBold}>Sign Out</Typography></Link>
              </Grid>
              {parseInt(context.user.profile.user.role) === 1 && 
                <Grid item xs={12} sm={6} lg={5}>
                  <Typography variant="h6" color="primary">Settings</Typography>
                  <Link onClick={() => {handleChangeRoute('/services/utilities')}}><Typography className={classes.menuLink}>Utilities</Typography></Link>
                  <Link onClick={() => {handleChangeRoute('/services/pedestals')}}><Typography className={classes.menuLink}>Pedestals</Typography></Link>
                  <Link onClick={() => {handleChangeRoute('/services/berths')}}><Typography className={classes.menuLink}>Berths</Typography></Link>
                  <Link onClick={() => {handleChangeRoute('/users/invites')}}><Typography className={classes.menuLink}>User Invites</Typography></Link>
                </Grid>
              }
            </Grid>
          </div>
        </div>
    </div>
  );
}

export default withRouter(JumboMenu);
