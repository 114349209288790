import React from 'react';
import PropTypes from 'prop-types';
import {useTheme, Typography, makeStyles, Toolbar} from '@material-ui/core';


const useStyles = makeStyles((theme, mainColor) => ({ 
  rightPanel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  toolbar: {
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      fontSize: 20,
      marginBottom: 10
    },
  }
}))


const PageToolbar = props => {

  const theme = useTheme();
  const classes = useStyles(theme, props.color);

  return (
    <Toolbar disableGutters={true} className={classes.toolbar}>
      <Typography variant="h4" color="primary" className={classes.title}>{props.label}</Typography>
      <div className={classes.rightPanel}>
        {props.children}
      </div>
    </Toolbar>
  );
}

PageToolbar.propTypes = {
  label: PropTypes.string.isRequired
}

export default PageToolbar;
