import React, { useContext, useEffect, useState } from 'react';
import {useTheme, makeStyles, Button, Typography} from '@material-ui/core';
import AutoTable from '../components/autoTable';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import globalContext from '../context/globalContext';
import StackSlidePanel from '../components/stackSlidePanel';
import CustomerTransaction from './customerTransaction';
import { timeSince, SQLtoUTCDateObj } from '../global/dateTimeHelpers';
import { localeCurrency } from '../global/helpers';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40,
    paddingTop: 20
  }
}));

const CustomerTransactions = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext)

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [transactions, setTransactions] = useState([])
  const [customer, setCustomer] = useState(null)
  const [transaction, setTransaction] = useState(null)

  const headCells = ['date', 'type', 'currency', 'amount']

  const handleBack = () => {
    if(typeof props.handleBack === 'function')
      props.handleBack();
  }

  useEffect(() => {

    const loadTransactions = () => {

      setLoading(true);
      setError(null);

      API.req(`/customer/transactions/${context.profile.data.id_organisation}`, 'GET', {id_customer: props.customer.id_customer}).then(
        async res => {

          if(res.success === true){
            setLoading(false);
            setError(null);
            setTransactions(res.result);
          } else {
            setLoading(false);
            setError(res.msg);
            setTransactions([]);
          }

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
            setTransactions([]);
        }
      )

    }
    setCustomer(props.customer);
    loadTransactions();

  }, [props.customer])

  const handleRowClick = row => {
    setTransaction(row);
  }

  return (
      <div className={classes.detailsWrapper}>

        {customer && 
          <React.Fragment>
            <Typography variant="subtitle2">#{customer.id_customer} - {customer.first_name} {customer.last_name}</Typography>
            <Typography variant="subtitle1">Balance: <b>{localeCurrency(customer.balance, customer.currency)}</b></Typography>
          </React.Fragment>
        }

        {error && <Alert severity="error">{error}</Alert>}

        <AutoTable 
          loading={loading} 
          headCells={headCells}
          rows={transactions}
          orderBy="date"
          handleRowClick={handleRowClick}
          cellReplacementProps={{
            'date': (val, idx) => timeSince(SQLtoUTCDateObj(val)),
            'amount': (val, idx) => (<Typography color={parseFloat(val) < 0 ? 'error' : 'default'} variant="subtitle2">{localeCurrency(val, transactions[idx].currency)}</Typography>)
          }}
         />

        <StackSlidePanel title="Transaction" show={transaction !== null} handleClose={() => setTransaction(null)}><CustomerTransaction customer={customer} transaction={transaction} handleBack={() => setTransaction(null)} /></StackSlidePanel>
      </div>
  );
}

export default CustomerTransactions;
