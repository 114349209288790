import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import { keyValueArray } from '../global/helpers';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  }
}));

const TariffAdd = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [ serviceTypes, setServiceTypes ] = useState([]);
  const [ units, setUnits ] = useState([]);



  const [ form, setForm ] = useState({
    tariff_name: '',
    tariff_description: '',
    rate: '0.00',
    id_unit: 1,
    currency: context.profile.data.currency,
    id_service_type: 1,
  });

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'tariff_name',
      display_name: 'Tariff Name',
      required: true
    },
    {
      name: 'tariff_description',
      display_name: 'Description',
      required: false
    },
    {
      name: 'id_service_type',
      display_name: 'Service Type',
      required: false
    },
    {
      name: 'rate',
      display_name: 'Rate',
      required: false
    },
    {
      name: 'id_unit',
      display_name: 'Unit',
      required: false
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    setError(null);
    setLoading(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){


      //
      // Execute an api request and get the returned result
      //
      API.req(`/tariff/${context.profile.data.id_organisation}`, 'POST', form).then(
        async res => {
          setLoading(false);

          await API.addToLocalArray('tariffs', res.result);
          context.tariffAdd(res.result);

          if(typeof props.handleClose === 'function')
            props.handleClose();

          context.showSnack({message: 'Tariff created!'}, 'success')

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )

    }
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  useEffect(() => {
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.service_types !== 'undefined')
      setServiceTypes(keyValueArray(context.meta.data.service_types, 'id_service_type', 'service_type'));
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.units !== 'undefined')
      setUnits(keyValueArray(context.meta.data.units, 'id_unit', 'unit'));

  }, [context.meta])

  return (
      <div className={classes.detailsWrapper}>
        {error && <Alert severity="error">{error}</Alert>}

        <div className={classes.form}>

          <div className="flex-row">
            <InputField name='tariff_name' validation={validation.tariff_name} value={form.tariff_name} handleChange={handleInputChange} hoverLabel={true} />
            <div className="flex"></div>
          </div>

          <div className="flex-row">
            <InputField name='tariff_description' validation={validation.tariff_description} value={form.tariff_description} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='id_service_type' validation={validation.id_service_type} value={form.id_service_type} handleChange={handleInputChange} hoverLabel={true} type="select" items={serviceTypes}/>
            <div className="flex"></div>
          </div>

          <div className="flex-row align-center">
            <InputField name='rate' validation={validation.rate} value={form.rate} handleChange={handleInputChange} hoverLabel={true} />
            <div className="flex">{context.profile.data.currency}</div>
          </div>

          <div className="flex-row">
            <InputField name='id_unit' validation={validation.id_unit} value={form.id_unit} handleChange={handleInputChange} hoverLabel={true} type="select" items={units} />
            <div className="flex"></div>
          </div>

        </div>

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Add</ProgressButton>
        </div>

      </div>
  );
}

export default TariffAdd;
