import React, { useEffect, useRef, useState } from "react";
import "./svgMap.css";
import { ReactComponent as Organisation_1 } from "../assets/imgs/ports/Organisation_1.svg";
import { ReactComponent as Organisation_2 } from "../assets/imgs/ports/Organisation_2.svg";
import { ReactComponent as Organisation_3 } from "../assets/imgs/ports/Organisation_3.svg";
import { ReactComponent as Organisation_4 } from "../assets/imgs/ports/Organisation_4.svg";
import { ReactComponent as Organisation_5 } from "../assets/imgs/ports/Organisation_5.svg";
import { ReactComponent as Organisation_6 } from "../assets/imgs/ports/Organisation_6.svg";
import { ReactComponent as Organisation_7 } from "../assets/imgs/ports/Organisation_7.svg";
import { ReactComponent as Organisation_8 } from "../assets/imgs/ports/Organisation_8.svg";

let hasMapEvent = false;
let hasMapEventTimeout;

const SvgMap = (props) => {
  const mapItem = useRef();

  useEffect(() => {
    const mi = mapItem.current;
    const parent =
      typeof props.parent !== "undefined" ? props.parent.current : null;

    if (typeof mi !== "undefined" && mi !== null) {
      const pressablePedestals = mi.querySelectorAll("[id^='P-']");
      pressablePedestals.forEach((pObj) => {
        pObj.addEventListener(
          "mouseup",
          (e) => handlePedestalPress(e, pObj.id),
          false
        );
        pObj.addEventListener(
          "touchend",
          (e) => handlePedestalPress(e, pObj.id),
          false
        );
      });

      const pressableBerths = mi.querySelectorAll("[id^='B-']");
      pressableBerths.forEach((pObj) => {
        pObj.addEventListener(
          "mouseup",
          (e) => handleBerthPress(e, pObj.id),
          false
        );
        pObj.addEventListener(
          "touchend",
          (e) => handleBerthPress(e, pObj.id),
          false
        );
        pObj.setAttribute("class", "interactive berth empty");
      });

      parent.addEventListener("mapevent", (e) => handleMapEvent(e), false);
    }

    return () => {
      if (typeof mi !== "undefined") {
        const pressablePedestals = mi.querySelectorAll("[id^='P-']");
        pressablePedestals.forEach((pObj) => {
          pObj.removeEventListener(
            "mouseup",
            (e) => handlePedestalPress(e, pObj.id),
            false
          );
          pObj.removeEventListener(
            "touchend",
            (e) => handlePedestalPress(e, pObj.id),
            false
          );
        });

        const pressableBerths = mi.querySelectorAll("[id^='B-']");
        pressableBerths.forEach((pObj) => {
          pObj.removeEventListener(
            "mouseup",
            (e) => handleBerthPress(e, pObj.id),
            false
          );
          pObj.removeEventListener(
            "touchend",
            (e) => handleBerthPress(e, pObj.id),
            false
          );
        });

        parent.removeEventListener("mapevent", (e) => handleMapEvent(e), false);
      }
    };
  }, [mapItem.current]);

  useEffect(() => {
    const mi = mapItem.current;

    if (typeof mi !== "undefined" && mi !== null) {
      const show =
        typeof props.filters.pedestals !== "undefined"
          ? props.filters.pedestals
          : true;
      const showNumbers =
        typeof props.filters.pedestal_numbers !== "undefined"
          ? props.filters.pedestal_numbers
          : false;
      const pedestals = mi.querySelectorAll("[id^='P-']");
      pedestals.forEach((pObj, idx) => {
        const pedStatus = props.pedestals.find(
          (p) => p.map_reference === pObj.id
        );
        if (typeof pedStatus !== "undefined") {
          pObj.setAttribute(
            "class",
            `interactive pedestal ${pedStatus.status.toLowerCase()} ${
              !show && "hidden"
            } ${showNumbers && "show-numbers"}`
          );

          const textLabels = pedestals[idx].querySelectorAll("text");
          if (textLabels.length === 0) {
            let text = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "text"
            );
            text.setAttribute("x", pedestals[idx].getBBox().x + 1);
            text.setAttribute("y", pedestals[idx].getBBox().y - 1);
            text.classList = `pedestal-id`;
            text.style = `font-size: ${
              props.fontSize || "7"
            }px; stroke-width: ${parseFloat(
              parseInt(props.fontSize || "7") * 0.058
            ).toFixed(1)}px;`;
            text.textContent = pedStatus.pedestal_reference;
            pedestals[idx].append(text);
          } else {
            textLabels.forEach((text) => {
              if (pedStatus.pedestal_reference !== text.textContent) {
                text.textContent = pedStatus.pedestal_reference;
                text.style = `font-size: ${
                  props.fontSize || "7"
                }px; stroke-width: ${parseFloat(
                  parseInt(props.fontSize || "7") * 0.058
                ).toFixed(1)}px;`;
              }
            });
          }
        } else
          pObj.setAttribute(
            "class",
            `interactive pedestal offline ${!show && "hidden"}`
          );
      });
    }
  }, [props.pedestals, props.filters]);

  useEffect(() => {
    const mi = mapItem.current;

    if (typeof mi !== "undefined" && mi !== null) {
      const show =
        typeof props.filters.berths !== "undefined"
          ? props.filters.berths
          : true;
      const berths = mi.querySelectorAll("[id^='B-']");
      berths.forEach((pObj) => {
        const pedStatus = props.berths.find((b) => b.map_reference === pObj.id);
        if (typeof pedStatus !== "undefined")
          pObj.setAttribute(
            "class",
            `interactive berth ${pedStatus.status.toLowerCase()} ${
              !show && "hidden"
            }`
          );
        else
          pObj.setAttribute(
            "class",
            `interactive berth empty ${!show && "hidden"}`
          );
      });
    }
  }, [props.berths, props.filters]);

  const handleMapEvent = (e) => {
    if (typeof hasMapEventTimeout !== "undefined")
      clearTimeout(hasMapEventTimeout);

    hasMapEvent = true;
    hasMapEventTimeout = setTimeout(() => {
      hasMapEvent = false;
    }, 500);
  };

  const handlePedestalPress = (e, id) => {
    e.preventDefault();
    setTimeout(() => {
      if (
        typeof props.handlePedestalPress === "function" &&
        hasMapEvent === false
      )
        props.handlePedestalPress(id);
    }, 350);
  };

  const handleBerthPress = (e, id) => {
    e.preventDefault();
    setTimeout(() => {
      console.log(hasMapEvent);
      if (typeof props.handleBerthPress === "function" && hasMapEvent === false)
        props.handleBerthPress(id);
    }, 350);
  };

  switch (props.name.toUpperCase()) {
    case "ORGANISATION_1":
      return <Organisation_1 ref={mapItem} />;
      break;
    case "ORGANISATION_2":
      return <Organisation_2 ref={mapItem} />;
      break;
    case "ORGANISATION_3":
      return <Organisation_3 ref={mapItem} />;
      break;
    case "ORGANISATION_4":
      return <Organisation_4 ref={mapItem} />;
      break;
    case "ORGANISATION_5":
      return <Organisation_5 ref={mapItem} />;
      break;
    case "ORGANISATION_6":
      return <Organisation_6 ref={mapItem} />;
      break;
    case "ORGANISATION_7":
      return <Organisation_7 ref={mapItem} />;
      break;
    case "ORGANISATION_8":
      return <Organisation_8 ref={mapItem} />;
      break;
    default:
      return <Organisation_1 ref={mapItem} />;
  }
};

export default SvgMap;
