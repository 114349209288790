import React, { useContext, useEffect, useState } from 'react';
import {useTheme, makeStyles, Typography, Paper, CircularProgress} from '@material-ui/core';
import { localeCurrency, sanitizeMeter } from '../global/helpers';
import globalContext from '../context/globalContext';
import API from '../global/api';
import clsx from 'clsx';
import PowerSwitch from '../components/powerSwitch';
import HelpfulAlert from '../components/helpfulAlert';

const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex', 
        flexDirection: 'row', 
        gap: '20px',
        alignItems: 'center', 
        marginTop: 20, 
        marginBottom: 40,
        flexWrap: 'wrap'
    },
    powerButton: {
        height: 48,
        width: 48,
        cursor: 'pointer'
    },
    textButton: {
        fontSize: 20,
        cursor: 'pointer',
        paddingTop: 6
    },
    textButtonOn: {
        color: theme.palette.success.main
    },
    textButtonLoading: {
        color: theme.palette.primary.main
    },
    powerButtonLoader: {
        height: 48,
        width: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    customerDetail: {
        fontSize: '.9rem'
    },
    usage: {
        flex: 1,
        minWidth: 300,
        display: 'flex', 
        alignItems: 'center', 
        flexDirection: 'row',
        gap: '20px'
    }
}));


const ServiceUtilitySessionOverview = props => {
    
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const {customer, service} = props;
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ berths, setBerths ] = useState('');

    const mode = customer ? 'CUSTOMER' : service.id_app_user !== null ? 'APP' : 'EXTERNAL'; 


    const handleTogglePaused = () => {

        if(service !== null) {
            setLoading(true);
            setError(null);

            if((Boolean(service.paused) === false && service.paused !== null)){

                API.req(`/service/pause/${context.profile.data.id_organisation}`, 'POST', {id_service: service.id_service}).then(
                    async res => {
                
                        setLoading(false);
                        if(res.success === true)
                            context.serviceUpdate(res.result);
                
                    },
                    err => {
                        setLoading(false);
                        setError(err)
                    }
                )

            } else {


                API.req(`/service/unpause/${context.profile.data.id_organisation}`, 'POST', {id_service: service.id_service}).then(
                    async res => {
                
                        setLoading(false);
                        if(res.success === true)
                            context.serviceUpdate(res.result);
                
                    },
                    err => {
                        setLoading(false);
                        setError(err)
                    }
                )

            }

        }

    }

    useEffect(() => {

        let newBerths = '';
        if(typeof props.customer !== 'undefined' && props.customer !== null){
            const findBerths = context.occupancies.data.filter(o => o.id_customer === customer.id_customer && o.end_time === null);
            findBerths.map(b => {
                newBerths += `${b.berth_reference}, `;
            })
        }
        if(newBerths.length > 2)
            newBerths = newBerths.slice(0, (newBerths.length-2));

        if(newBerths.length > 16)
            newBerths = newBerths.slice(0, 16) + '...';

        setBerths(newBerths);

    }, [context.occupancies, props.customer])

    useEffect(() => {

        if(props.service !== null){
            setError(null);
            setLoading(true);
        
            API.req(`/service/live/${context.profile.data.id_organisation}`, 'GET', {id_service: props.service.id_service}).then(
              async res => {
                setLoading(false);
        
                if(res.success === true)
                    context.serviceUpdate(res.result);
        
              },
              err => {
                  setLoading(false);
                  setError(typeof err === 'string' ? err : JSON.stringify(err))
              }
            )

        }

    }, [])

    const isPowerOn = () => {
        return (Boolean(service.paused) === false && service.paused !== null);
    }

    return (
        <React.Fragment>
            {error && <HelpfulAlert severity="error" message={error} />}

            {service !== null && 
                <div className="flex-row-start align-center gap-10" style={{marginTop: 20}}>
                    <PowerSwitch handleSwitchChange={handleTogglePaused} checked={isPowerOn()} name="active_service" />
                    {loading && <div className={classes.powerButtonLoader}><CircularProgress size={32} /></div>}
                    <Typography className={clsx(classes.textButton, isPowerOn() && classes.textButtonOn, loading && classes.textButtonLoading)} onClick={handleTogglePaused}>Service {isPowerOn() ? 'On' : 'Off'}</Typography>
                </div>
            }
            <div className={classes.details}>
                {service !== null && 
                    <div className='flex'>
                        <Typography className={classes.customerDetail}>Customer: <b>{customer && customer.first_name} {customer && customer.last_name} {mode === 'APP' && 'App user'} {mode === 'EXTERNAL' && 'External Customer'}</b></Typography>
                        <Typography className={classes.customerDetail}>Boat: <b>{customer ? customer.boat_name : service.boat_name !== null ? service.boat_name : ''}</b></Typography>
                        <Typography className={classes.customerDetail}>Berth: <b>{berths} {mode !== 'CUSTOMER' && 'N/A'}</b></Typography>
                        <Typography className={classes.customerDetail}>Account type: <b>{customer && customer.account_type} {mode !== 'CUSTOMER' && 'N/A'}</b></Typography>
                        <Typography className={classes.customerDetail}>Discount: <br /><b>{customer && customer.discount_name} {mode !== 'CUSTOMER' && 'N/A'}</b></Typography>
                    </div>
                }

                <div className={classes.usage}>
                    {service !== null && 
                        <Paper style={{textAlign: 'center', flex: 1}}>
                            <div style={{padding: '20px 10px'}}>
                            <Typography>Current Usage</Typography>
                            <Typography variant="h4" color="primary" style={{fontWeight: 600}}>{sanitizeMeter((service.meter-service.billed_meter), service.id_unit)}</Typography>
                            <Typography variant="caption">{service.unit}</Typography>
                            </div>
                        </Paper>
                    }

                    {service !== null && 
                        <Paper style={{textAlign: 'center', flex: 1}}>
                            <div style={{padding: '20px 10px'}}>
                            <Typography>Current cost</Typography>
                            <Typography variant="h4" color="primary" style={{fontWeight: 600}}>{localeCurrency((service.cost-service.billed_cost), service.currency)}</Typography>
                            <Typography variant="caption">{localeCurrency(service.rate, service.currency)} / {service.unit}</Typography>
                            </div>
                        </Paper>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default ServiceUtilitySessionOverview