import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, CircularProgress} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import { keyValueArray } from '../global/helpers';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  }
}));

const UserInviteAdd = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [ loadingOrganisations, setLoadingOrganisations ] = useState(false);
  const [ organisations, setOrganisations ] = useState([]);



  const [ form, setForm ] = useState({
    email: '',
    name: '',
    id_organisation: null,
  });

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'name',
      display_name: 'Name',
      required: true
    },
    {
      name: 'email',
      display_name: 'Email Address',
      required: false
    },
    {
      name: 'id_organisation',
      display_name: 'Marina',
      required: false
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    setError(null);
    setLoading(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){


      //
      // Execute an api request and get the returned result
      //
      API.req(`/user/invite`, 'POST', form).then(
        async res => {
          setLoading(false);

          if(typeof props.handleSuccess === 'function')
            props.handleSuccess(res.result);

          context.showSnack({message: 'Invite sent!'}, 'success')

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )

    }
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])


  useEffect(() => {

    setLoadingOrganisations(true);

    try {
      
      API.req(`/organisations`, 'GET').then(
        async res => {

          setLoadingOrganisations(false);
          
          if(res.success === true)
            setOrganisations(keyValueArray(res.result, 'id_organisation', 'organisation_name'));
          else
            throw res.msg;

        },
        err => {

            setLoadingOrganisations(false);
            throw err;
        }
      )

    } catch (error) {
      

      setLoadingOrganisations(false);
      setError('Unable to load organisations')

    }

  }, [])

  return (
      <div className={classes.detailsWrapper}>
        {error && <Alert severity="error">{error}</Alert>}

        <div className={classes.form}>

          <div className="flex-row">
            <InputField name='name' validation={validation.name} value={form.name} handleChange={handleInputChange} hoverLabel={true} />
            <div className="flex"></div>
          </div>

          <div className="flex-row">
            <InputField name='email' validation={validation.email} value={form.email} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row align-center">
            <InputField name='id_organisation' validation={validation.id_organisation} value={form.id_organisation} handleChange={handleInputChange} hoverLabel={true} type="select" items={organisations}/>
            <div className="flex">{loadingOrganisations && <CircularProgress size={20} />}</div>
          </div>

        </div>

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Send</ProgressButton>
        </div>

      </div>
  );
}

export default UserInviteAdd;
