import React, {useEffect, useState} from 'react';
import './styles.css';
import {makeStyles, useTheme, Typography, Button, Divider} from '@material-ui/core';
import { makeValidation, validateForm, isValidForm } from '../../global/formHelper';

import InputField from '../../components/inputField';
import ProgressButton from '../../components/progressButton';

import { useParams } from 'react-router';

import API from '../../global/api';

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 10
  },
  footer: {
    marginTop: 40,
    textAlign: 'center'
  },
}))


const Register = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const {token} = useParams();

  const [form, setForm] = useState({
    username: '',
    password: '',
    confirm_password: '',
    first_name: '',
    last_name: '',
    email: '',
    token: token
  })

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'username',
      display_name: 'Username',
      required: true
    },
    {
      name: 'password',
      display_name: 'Password',
      required: true,
      additional: [
        {
          type: 'password',
          error: 'Password must be at least 8 characters and include letters and numbers'
        }
      ]
    },
    {
      name: 'confirm_password',
      display_name: 'Confirm Password',
      required: true,
      additional: [
        {
          type: 'match',
          field: 'password',
          error: 'Your passwords do not match'
        }
      ]
    },
    {
      name: 'first_name',
      display_name: 'First Name',
      required: true
    },
    {
      name: 'last_name',
      display_name: 'Last Name',
      required: true
    },
    {
      name: 'email',
      display_name: 'Email Address',
      required: true
    },
  ]));

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = () => {
    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid)
      props.handleRegister(form);
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter')
        handleSubmit();
  }


  useEffect(() => {

    setWaiting(true);

    API.req(`/user/invite/${token}`, 'GET').then(
      res => {
        setWaiting(false);
        if(res.success === true){
          const first_name = res.result.invite_name.split(' ')[0];
          const last_name = res.result.invite_name.slice(first_name.length, res.result.invite_name.length).trim();

          let newForm = JSON.parse(JSON.stringify(form));
          newForm.first_name = first_name;
          newForm.last_name = last_name;
          newForm.email = res.result.invite_email;
          setForm(newForm);
        } else
          setError(res.msg);
      },
      err => {
        setWaiting(false);
        setError(err)
      }
    )

  }, [])


  return (
    <div>

      {error && <Typography variant='caption' color='error'>{error}</Typography>}

      <div className={classes.form}>
        <InputField name='username' size='large' validation={validation.username} value={form.username} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
        <InputField name='password' size='large' type='password' validation={validation.password} value={form.password} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
        <InputField name='confirm_password' size='large' type='password' validation={validation.confirm_password} value={form.confirm_password} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
        <Divider style={{marginTop: 20, marginBottom: 20}} />
        <InputField name='first_name' size='large' validation={validation.first_name} value={form.first_name} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
        <InputField name='last_name' size='large' validation={validation.last_name} value={form.last_name} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
        <InputField name='email' size='large' validation={validation.email} value={form.email} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
      </div>

      <div className={classes.footer}>
        <ProgressButton variant="contained" size="large" color="primary" fullWidth waiting={waiting} onClick={handleSubmit} gutterBottom={true}>Create Account</ProgressButton>
        <Button onClick={props.handleBack}><b>Back to sign in</b></Button>
      </div>

    </div>
  );
}

export default Register;
