import Api from '../global/api';

export function discountGroupsLoad () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'DISCOUNTGROUPS_LOAD',
            status: 'LOADING',
            payload: null
        })


        let active_profile = await Api.getLocal('_bvap');
        if(active_profile !== null){
            Api.req(`/discountgroups/${active_profile}`, 'GET').then(
                res => {

                    Api.setLocal('discountgroups', res.result);

                    dispatch({
                        type: 'DISCOUNTGROUPS_LOAD',
                        status: 'OK',
                        payload: res.result
                    })
                },
                err => {
                    // Login error
                    dispatch({
                        type: 'DISCOUNTGROUPS_LOAD',
                        status: 'error',
                        payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your tariffs'
                    })
                }
            )
        }

    }
}

export function discountGroupAdd (payload) {
    return dispatch => {

        // Loading
        dispatch({
            type: 'DISCOUNTGROUP_ADD',
            status: 'OK',
            payload: payload
        })

        return 'success';

    }
}

export function discountGroupUpdate (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'DISCOUNTGROUP_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}