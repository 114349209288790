import React, { useContext, useEffect, useState } from 'react';
import {useTheme, makeStyles, Typography, Link, IconButton, Collapse, Button} from '@material-ui/core';
import UtilityOverview from '../components/utilityOverview';
import { getPedestalImage, localeCurrency } from '../global/helpers';
import globalContext from '../context/globalContext';
import SearchGlobal from './searchGlobal';
import CloseIcon from '@material-ui/icons/Close';
import ServiceUtilitySelectPedestal from './serviceUtilitySelectPedestal';
import ServiceSelectBerth from './serviceSelectBerth';
import ServicePedestalUtilities from './servicePedestalUtilities';
import ServiceUtilitySelectStart from './serviceUtilitySelectStart';
import ServiceSelectCustomer from './serviceSelectCustomer';
import API from '../global/api';
import ProgressiveButton from '../components/progressButton';
import ServiceUtilitySessionOverview from './serviceUtilitySessionOverview';
import HelpfulAlert from '../components/helpfulAlert';
import ServiceUtilityBillCurrentUsage from './serviceUtilityBillCurrentUsage';
import CreditCardIcon from '@material-ui/icons/CreditCard';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  wizard: {

  },
  selectUtility: {
    paddingTop: 15,
    paddingBottom: 15
  },
  utilityOverview: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 50
  },
  pedestalImage: {
    width: 220,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  pedestalUtilityDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  buttonBill: {
      display: 'flex', 
      alignItems: 'center', 
      flexDirection: 'row',
      color: theme.palette.grey[400],
      '&:hover': {
          color: theme.palette.error.main,
      }
  },
}));


const ServiceUtility = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ utility, setUtility ] = useState(null);
  const [ berth, setBerth ] = useState(null);
  const [ pedestal, setPedestal ] = useState(null);
  const [ service, setService ] = useState(null);
  const [ customer, setCustomer ] = useState(null);

  const [selectBerth, setSelectBerth] = useState(false);
  const [ confirmBill, setConfirmBill ] = useState(false);

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);


  const handleSelectSearchResult = item => {
    switch(item.type.toUpperCase()){
      case 'BERTH':
        const selectedBerth = context.berths.data.find(b => b.id_berth === item.id);
        if(typeof selectedBerth !== 'undefined')
          setBerth(selectedBerth);
        break;
      case 'PEDESTAL':
        const selectedPedestal = context.pedestals.data.find(p => p.id_pedestal === item.id);
        if(typeof selectedPedestal !== 'undefined')
          setPedestal(selectedPedestal);
        break;
      case 'UTILITY':
        const selectedUtility = context.utilities.data.find(u => u.id_utility === item.id);
        if(typeof selectedUtility !== 'undefined')
          setUtility(selectedUtility);
        break;
      default:
    }
  }


  const handleSelectService = () => {
    setError(null);
    if(validSelection()){
      const tariff = context.tariffs.data.find(t => t.id_tariff === utility.id_tariff);
      
      if(typeof tariff === 'undefined')
        setError('A tariff has not been set for this utility!');


      
      let id_customer = null;
      if(berth !== null){
        console.log('finding existing occupancy')
        const findOccupancy = context.occupancies.data.find(o => parseInt(o.id_berth) === parseInt(berth.id_berth) && o.end_time === null);
        console.log(findOccupancy)
        if(typeof findOccupancy !== 'undefined')
          id_customer = findOccupancy.id_customer
      }

      setService({
        id_service: null,
        id_customer: id_customer,
        id_utility: utility.id_utility,
        start_time: null,
        end_time: null,
        meter: 0,
        unit: typeof tariff !== 'undefined' ? tariff.unit : '',
        rate: typeof tariff !== 'undefined' ? tariff.rate : 0,
        currency: context.profile.data.currency,
        cost: 0
      })
    } else {
      setError('Select a utility before your proceed.')
    }
  }

  const handleSetServiceStartTime = start_time => {
    let newService = JSON.parse(JSON.stringify(service));
    newService.start_time = start_time;
    setService(newService);
  }

  const handleSetServiceCustomer = item => {
    let newService = JSON.parse(JSON.stringify(service));
    if(item !== null)
      newService.id_customer = item.id;
    else{
      setCustomer(null);
      newService.id_customer = null;
    }
    setService(newService);
  }

  const resetPedestal = () => {
    setPedestal(null);
    setUtility(null);
    if(service !== null){
      let newService = JSON.parse(JSON.stringify(service));
      newService.id_utility = null;
      setService(newService);
    }
  }

  const serviceStatus = () => {
    if(service !== null && service.id_service !== null)
      return 'ONGOING';
    
    if(service !== null && service.id_utility !== null)
      return 'SELECTED';
    
    return 'NOT_SELECTED';
  }

  const validSelection = () => {
    if(utility !== null && pedestal !== null)
      return true;
    
    return false;
  }

  const validService = () => {
    if(service !== null && service.id_service === null && service.id_customer !== null && service.id_utility !== null){
      if(customer !== null && (parseFloat(customer.balance) <= 0 && customer.id_customer_account_type === 1))
        return false;
      else
        return true;
    }
    
    return false;
  }



  const handleStartService = async () => {

    setError(null);
    if(validService()){

      setLoading(true);


      API.req(`/service/start/${context.profile.data.id_organisation}`, 'POST', service).then(
        async res => {

          await API.addToLocalArray('services', res.result);
          context.serviceAdd(res.result);
          setService(res.result);

          //
          //  Set the utility to assigned should the socket
          //  not send it through
          //
          if(res.result.id_session !== null)
            context.utilityUpdate({
              ...props.utility,
              service_status: 'assigned'
            });

          
          //
          //  We need to see if the berth has been set and doesn't have an occupancy already set
          //  If the occupancy is not set we need to create it
          //
          if(berth !== null){
            const existing_occupancy = context.occupancies.data.find(o => parseInt(o.id_berth) === parseInt(berth.id_berth) && o.end_time === null);
            if(typeof existing_occupancy === 'undefined' || existing_occupancy === null){

              
              // Create a new occupancy
              const newOccupancy = {
                  id_berth: berth.id_berth,
                  id_customer: customer.id_customer,
                  start_time: service.start_time
              };

              API.req(`/occupancy/start/${context.profile.data.id_organisation}`, 'POST', newOccupancy).then(
                async res => {

                  API.addToLocalArray('occupancies', res.result)
                  context.occupancyAdd(res.result);
                  context.showSnack({message: 'Service assigned!'}, 'success')
                  setLoading(false);

                },
                err => {
                    setLoading(false);
                    setError(err)
                }
              )

            } else {
              context.showSnack({message: 'Service assigned!'}, 'success')
              setLoading(false);
            }
          } else {
            context.showSnack({message: 'Service assigned!'}, 'success')
            setLoading(false);
          }



        },
        err => {
            setLoading(false);
            setError(err)
        }
      )


    }
  }


  const handleStopService = async () => {

    setError(null);
    setLoading(true);



    API.req(`/service/end/${context.profile.data.id_organisation}`, 'PUT', service).then(
      async res => {


        //
        //  This should cause a chain reaction to re-loading everything else
        //  and is in place should the REST api be operational but the socket
        //  not
        //
        context.utilityUpdate({
          ...props.utility,
          service_status: 'vacant'
        });
        context.serviceUpdate(res.result);
        context.showSnack({message: 'Service stopped!'}, 'success')
        setLoading(false);
        setService(null);
        setCustomer(null);

      },
      err => {
          setLoading(false);
          setError(err)
      }
    )

  }


  useEffect(() => {

    setUtility(props.utility);
    setPedestal(null);
    setBerth(null);

  }, [props.utility])


  //
  //  If the utility changes in the background we need to update the curreny utility
  //
  useEffect(() => {
    
    if(utility !== null){
      const findUtility = context.utilities.data.find(u => u.id_utility === utility.id_utility)
      if(JSON.stringify(findUtility) !== JSON.stringify(utility))
        setUtility(findUtility);
    }

  }, [context.utilities])


  //
  //  If the service changes in the background we need to update the curreny service
  //
  useEffect(() => {

    if(utility !== null){
      const findService = context.services.data.find(s => parseInt(s.id_utility) === parseInt(utility.id_utility) && s.end_time === null && s.id_session !== null);
      if(typeof findService !== 'undefined')
        setService(findService);

      //
      //  Was this person looking at an active session that needs to drop off
      //
      if(typeof findService === 'undefined' && service !== null && service.id_service !== null){
        setService(null);
        setCustomer(null);
      }
    }

  }, [context.services])


  useEffect(() => {

    if(utility !== null){

      const loadService = () => {
        const findService = context.services.data.find(s => parseInt(s.id_utility) === parseInt(utility.id_utility) && s.end_time === null && s.id_session !== null);
        if(typeof findService !== 'undefined')
          setService(findService);
        else
          handleSelectService();
      }
      loadService();
  
      const loadPedestal = () => {
        const findPedestal = context.pedestals.data.find(p => p.id_pedestal === utility.id_pedestal);
        if(typeof findPedestal !== 'undefined')
          setPedestal(findPedestal);
      }
      loadPedestal();

    }

  }, [utility])

  useEffect(() => {
    if(utility !== null && pedestal !== null && service == null )
      handleSelectService();
  }, [pedestal])

  useEffect(() => {
    if(berth !== null && service !== null){
      setSelectBerth(false);
      const findOccupancy = context.occupancies.data.find(o => parseInt(o.id_berth) === parseInt(berth.id_berth) && o.end_time === null);
      if(typeof findOccupancy !== 'undefined'){
        const id_customer = findOccupancy.id_customer
        let newService = JSON.parse(JSON.stringify(service));
        newService.id_customer = id_customer;
        setService(newService);
      }
    }
  }, [berth])

  useEffect(() => {
    
    if(berth !== null && customer === null){
      const findOccupancy = context.occupancies.data.find(o => parseInt(o.id_berth) === parseInt(berth.id_berth) && o.end_time === null);
      if(typeof findOccupancy !== 'undefined')
        setBerth(null);
    }

    if(service !== null && customer !== null){
      let newService = JSON.parse(JSON.stringify(service));
      newService.id_customer = customer.id_customer;
      setService(newService);
    }
  }, [customer])

  useEffect(() => {

    if(service !== null && service.id_customer !== null){
      const loadCustomer = () => {
        const findCustomer = context.customers.data.find(c => parseInt(c.id_customer) === parseInt(service.id_customer));
        if(typeof findCustomer !== 'undefined')
          setCustomer(findCustomer);
      }
      loadCustomer();
    }
  }, [service])


  return (
        <div className={classes.detailsWrapper}>

          {error && <HelpfulAlert severity="error" message={error} />}

          {(service === null || service.id_service === null) &&
            <div className={classes.wizard}>
              {/* 
              We are looking for a wizard style that guides you 
              through various paths to getting a session started.
              First step is selecting a utility, but you may or may
              not start with a berth.

              The berth may or may not already be assigned by a 
              customer and so you may or may not need to select
              a customer a berth.

              We can't show everything all at once so the first 
              steps are getting a utility selected and progressing
              to customer and start times next.

              This is a balance of what has and hasn't already been 
              set to determine if a component is required to be
              visible. 
              */}

              {/* GLOBAL SEARCH */}
              {(utility === null && pedestal === null && berth === null) && <SearchGlobal handleSelect={handleSelectSearchResult} />}
              
              {(service !== null || utility !== null || pedestal !== null || berth !== null) && <Typography variant="body1" color="primary">Summary</Typography>}

              {/* SELECT PEDESTAL */}
              <Collapse in={(utility === null && pedestal === null && berth !== null)}><ServiceUtilitySelectPedestal handleSelect={setPedestal} /></Collapse>
              {pedestal !== null && <Typography variant="h6">Pedestal: <b>{pedestal.pedestal_reference}</b> <IconButton size="small" onClick={() => resetPedestal()}><CloseIcon style={{fontSize: 18}} /></IconButton></Typography>}


              {/* SELECT BERTH */}
              {berth !== null && <Typography variant="body1">Berth: <b>{berth.berth_reference}</b> <IconButton size="small" onClick={() => setBerth(null)}><CloseIcon style={{fontSize: 18}} /></IconButton></Typography>}
              {(berth === null && !(utility === null && pedestal === null) && selectBerth === false) && <Typography variant="body1">Berth: <Link onClick={() => setSelectBerth(true)} style={{cursor: 'pointer'}}>Select Berth</Link></Typography>}
              <Collapse in={((utility !== null || pedestal !== null) && berth === null && selectBerth === true)}><ServiceSelectBerth pedestal={pedestal} handleSelect={setBerth} handleClose={() => setSelectBerth(false)} /></Collapse>
              
              
              
              {/* SELECT UTILITY */}
              <Collapse in={(pedestal !== null && (service === null || service.id_utility === null))} className={classes.selectUtility}>
                <Typography variant="subtitle2">Available utilities</Typography>
                {pedestal && <ServicePedestalUtilities id_pedestal={pedestal.id_pedestal} handleSelectUtility={setUtility} selected={utility ? utility.id_utility : null} />}
              </Collapse>

              {/* SELECT CUSTOMER */}
              <Collapse in={(service !== null && service.id_service === null && customer === null)}>
                <ServiceSelectCustomer handleSelect={handleSetServiceCustomer} />
              </Collapse>
              <Collapse in={customer}>
                {customer && 
                  <React.Fragment>
                    <Typography>Customer: <b>{customer.first_name} {customer.last_name}</b> <IconButton size="small" onClick={() => handleSetServiceCustomer(null)}><CloseIcon style={{fontSize: 18}} /></IconButton></Typography>
                    <Typography>Boat: <b>{customer.boat_name}</b></Typography>
                    <Typography>Account type: <b>{customer.account_type}</b></Typography>
                    <Typography>Balance: <Typography color={(parseFloat(customer.balance) <= 0 && customer.id_customer_account_type === 1) ? 'error' : 'default'} component="span"><b>{localeCurrency(customer.balance, customer.currency)}</b></Typography></Typography>
                  </React.Fragment>
                }
              </Collapse>

              {/* SELECT START */}
              <Collapse in={(service !== null && service.id_service === null)}>
                <ServiceUtilitySelectStart handleSetStartTime={handleSetServiceStartTime} />
              </Collapse>
            </div>
          }

          


          {(service !== null && typeof service.id_session !== 'undefined' && service.id_session !== null) && 
            <ServiceUtilitySessionOverview customer={customer} service={service} berth={berth} />
          }

        {(pedestal !== null || utility !== null) && 
          <div className={classes.utilityOverview}>
            <div className={classes.pedestalImage}>
              {pedestal !== null && <img src={getPedestalImage(pedestal.id_pedestal_type)} style={{height: '100%', width: 'auto', maxWidth: '100%'}} alt={pedestal.pedestal_type} />}
            </div>
            {utility !== null && 
              <div className={classes.pedestalUtilityDetails}>
                <UtilityOverview utility={utility} />
              </div>
            }
            {utility === null && 
              <div className={classes.pedestalUtilityDetails}>
                <Typography variant="h6" color="textSecondary">Select a utility above</Typography>
              </div>
            }
          </div>
        }

        <div style={{flex: 1}}></div>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: serviceStatus() === 'ONGOING' ? 'space-between' : 'flex-end', paddingTop: 20}}>
          {serviceStatus() === 'ONGOING' && <Button onClick={() => setConfirmBill(true)} className={classes.buttonBill}><CreditCardIcon style={{marginRight: 10}} /> Bill current usage</Button>}
          {serviceStatus() === 'ONGOING' && <ProgressiveButton waiting={loading} variant="contained" onClick={handleStopService}>&nbsp;&nbsp;&nbsp;&nbsp;Remove Service&nbsp;&nbsp;&nbsp;&nbsp;</ProgressiveButton>}
          {serviceStatus() === 'SELECTED' && <ProgressiveButton waiting={loading} variant="contained" disabled={!validService()} onClick={handleStartService}>&nbsp;&nbsp;&nbsp;&nbsp;Assign Service&nbsp;&nbsp;&nbsp;&nbsp;</ProgressiveButton>}
          {serviceStatus() === 'NOT_SELECTED' && <ProgressiveButton waiting={loading} variant="contained" disabled={!validSelection()} onClick={handleSelectService}>&nbsp;&nbsp;&nbsp;&nbsp;Select&nbsp;&nbsp;&nbsp;&nbsp;</ProgressiveButton>}
        </div>

        
        {confirmBill && <ServiceUtilityBillCurrentUsage id_service={service.id_service} handleClose={() => setConfirmBill(false)} />}

      </div>
  );
}

export default ServiceUtility;
