import React, { useState, useEffect, useContext, useRef } from 'react';
import {useTheme, makeStyles, Typography, IconButton, CircularProgress, ButtonGroup, Button, Collapse} from '@material-ui/core';
import Images from '../assets/imgs/index';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import { keyValueArray } from '../global/helpers';

const useStyles = makeStyles((theme) => ({
    uploadWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        maxWidth: 700
    },
    uploadImage: {
        width: 300,
        height: 200,
        position: 'relative',
        backgroundColor: theme.palette.grey[200],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginBottom: 20
    },
    uploadHover: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        backgroundColor: (0,0,0,0),
        top: 0,
        left: 0
    },
    uploadIcon: {
        width: 40,
        height: 40,
        padding: 4,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 8
    },
    uploadInfo: {
        margin: 20
    },
    confirmBox: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        padding: 10,
        marginTop: 10
    },
    dropHighlight: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff'
    },
    dragHighlight: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        width: 'auto',
        height: 'auto',
        maxWidth: '96%',
        maxHeight: '96%'
    }    
}));

const ProfileUploadLogo = props => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const [ dropHighlight, setDropHighlight ] = useState(false)
    const [ fileUpload, setFileUpload ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ logo, setLogo ] = useState(null);

    const dropArea = useRef(null);
    const fileInput = useRef(null);



    useEffect(() => {

        const preventDefaults = (e) => {
            e.preventDefault()
            e.stopPropagation()
        }
        
        const dropHighlight = e => {
            preventDefaults(e);
            setDropHighlight(true);
        }
        
        const dropLeave = e => {
            preventDefaults(e);
            setDropHighlight(false);
        }

        dropArea.current.addEventListener('dragenter', dropHighlight, false)
        dropArea.current.addEventListener('dragover', dropHighlight, false)
        dropArea.current.addEventListener('dragleave', dropLeave, false)
        dropArea.current.addEventListener('drop', e => {
            dropLeave(e);
            setFileUpload(e.dataTransfer.files[0]);
        }, false)

    }, [])

    useEffect(() => {
        console.log(fileUpload);
    }, [fileUpload])

    useEffect(() => {

        if(typeof context.profile.data !== null && context.profile.data.logo_attachment_name !== null){
            setLoading(true);

            API.req(`/organisation/logo/${context.profile.data.id_organisation}`, 'GET').then(
                res => {
                    setLoading(false);
                    setLogo(res.result);
                },
                err => {
                    setLoading(false);
                    context.showSnack({message: typeof err === 'string' ? err : 'Could not fetch your logo!'}, 'error')
                    console.log(err);
                }
            )
        }

    }, [context.profile])

    const handleSelectFile = e => {
        if(typeof fileInput.current !== 'undefined')
            fileInput.current.click();
    }

    const handleFileChange = e => {
        setFileUpload(e.target.files[0]);
    }

    const handleUpload = () => {
        setLoading(true);

        API.fileUpload(`/organisation/logo/${context.profile.data.id_organisation}`, {attachment: fileUpload}).then(
            res => {
                setLoading(false);
                setFileUpload(null);
                setLogo(res.result);
                context.showSnack({message: 'Your logo has been updated'}, 'success')
                console.log(res);
            },
            err => {
                setLoading(false);
                context.showSnack({message: typeof err === 'string' ? err : 'Something went wrong!'}, 'error')
                console.log(err);
            }
        )
    }

  

  return (
      
    <div className={classes.uploadWrapper}>
        <div className={classes.uploadImage} onClick={handleSelectFile}>
            {logo && <img src={logo} className={classes.logo} />}
            {(!dropHighlight && !logo) && 
                <div className={classes.dragHighlight}>
                    <IconButton className={classes.uploadIcon} disabled={loading}>
                        {!loading && <img src={Images.Icons_Add_Icon} alt="Add your logo" />}
                        {loading && <CircularProgress size={24} />}
                    </IconButton>
                </div>
            }
            {dropHighlight && 
                <div className={classes.dropHighlight}>
                    <VerticalAlignBottomIcon size="small" /> 
                    <Typography variant="button" variant="h5">Drop</Typography>
                </div>
            }
            <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInput}
            />
            <div className={classes.uploadHover} ref={dropArea}></div>
        </div>

        <div className={classes.uploadInfo}>
            <div>
                <Typography variant="subtitle2">Upload your logo</Typography>
                <Typography>We'll use your logo in any automated emails.</Typography>
                <Typography variant="caption">Max size 2mb</Typography>
            </div>
            {fileUpload === null && <Button onClick={handleSelectFile} variant="contained" style={{marginTop: 10}}>Select New Image</Button>}

            <Collapse in={fileUpload !== null}>
                <div className={classes.confirmBox}>
                    <div className="flex-row">
                        <div>
                            <Typography variant="subtitle2">Confirm Upload</Typography>
                            <Typography variant="caption" component="div">{fileUpload !== null && fileUpload.name}</Typography>
                        </div>
                        <IconButton onClick={() => setFileUpload(null)} size="small"><CloseIcon fontSize="small" /></IconButton>
                    </div>
                    <div className="flex-row" style={{marginTop: 10}}>
                        <ProgressButton waiting={loading} color="default" variant="contained" onClick={handleUpload}><CheckIcon fontSize="small" /></ProgressButton>
                    </div>
                </div>
            </Collapse>
        </div>
    </div>

  );
}

export default ProfileUploadLogo;
