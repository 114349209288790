import React, { useContext, useEffect, useState } from 'react';
import {Button, Divider, makeStyles, Typography, useTheme} from '@material-ui/core';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import globalContext from '../context/globalContext';
import SideSlidePanel from '../components/sideSlidePanel';
import ProfileEditCompanyDetails from '../content/profileEditCompanyDetails';
import ProfileEditBankDetails from '../content/profileEditBankDetails';
import ProfileContactAdd from '../content/profileContactAdd';
import ProfileUploadLogo from '../content/profileUploadLogo';
import ProfileTaxRateAdd from '../content/profileTaxRateAdd';
import ProfileTaxRateEdit from '../content/profileTaxRateEdit';
import { localeCurrency } from '../global/helpers';
import ProfileContactEdit from '../content/profileContactEdit';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  contactWrapper: {
    width: '100%',
    maxWidth: 1200,
    margin: '30px 0'
  },
  companyDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    maxWidth: 1200,
    marginTop: 40
  },
  detailList: {
    display: 'flex',
    flexDirection: 'column',
    border:  `1px solid ${theme.palette.grey[300]}`,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    marginTop: 20,
  },
  detailListRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  detailListHeader: {
    flex: 1,
    padding: '20px 20px 4px 20px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  detailListValue: {
    flex: 1,
    padding: '20px 40px 4px 40px',
  },
  bottomButton: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0
  },
  taxRatesWrapper: {
    width: '100%',
    maxWidth: 500,
    margin: '30px 0'
  },
}))

const GroupProfile = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ showPanel, setShowPanel ] = useState(null); 
  const [ showPanelData, setShowPanelData ] = useState(null); 

  const contactHeadCells = ['name', 'position', 'email', 'phone'];
  const taxRateHeadCells = ['service_type', 'tax_rate', 'tax_rate_label'];

  const handleShowPanel = (panel, data = null) => {
    setShowPanel(panel);
    setShowPanelData(data);
  }

  useEffect(() => {
    console.log(context.profile.data)
  }, [])

  return (
    <div className={classes.container}>

      <PageToolbar label="Profile"></PageToolbar>

      {context.profile.status === 'OK' &&
        <React.Fragment>

          <ProfileUploadLogo />

          <div className={classes.contactWrapper}>
            <Typography variant="h6" color="primary">Technical Contact</Typography>
            <Typography>Let us know who we should contact regarding technical and / or site management issues.</Typography>
            <AutoTable rows={context.profile.data.contacts_technical} orderBy="name" headCells={contactHeadCells} handleRowClick={row => handleShowPanel('edit_technical', row)} />
            <Button variant="contained" classes={{contained: classes.bottomButton}} onClick={() => handleShowPanel('add_technical')}>Add</Button>
          </div>

          <div className={classes.contactWrapper}>
            <Typography variant="h6" color="primary">Accounts Contact</Typography>
            <Typography>Let us know who we should contact regarding billing, reconciliation and bank payment issues.</Typography>
            <AutoTable rows={context.profile.data.contacts_accounts} orderBy="name" headCells={contactHeadCells} handleRowClick={row => handleShowPanel('edit_accounts', row)} />
            <Button variant="contained" classes={{contained: classes.bottomButton}} onClick={() => handleShowPanel('add_accounts')}>Add</Button>
          </div>


          <div className={classes.companyDetailsWrapper}>
            
            <div style={{marginBottom: 20}}>
              <Typography variant="h6" color="primary">General Settings</Typography>
              <Typography noWrap={false}>Settings and company information that will be used to <br />tailor your customer experience</Typography>

              <div className={classes.detailList}>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader}>Company Name</div>
                  <div className={classes.detailListValue}>{context.profile.data.organisation_name}</div>
                </div>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader}>VAT / Tax Number</div>
                  <div className={classes.detailListValue}>{context.profile.data.tax_number}</div>
                </div>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader}>Address</div>
                  <div className={classes.detailListValue}>{context.profile.data.organisation_address}</div>
                </div>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader}>Email</div>
                  <div className={classes.detailListValue}>{context.profile.data.organisation_email}</div>
                </div>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader} style={{paddingBottom: 20}}>Phone Number</div>
                  <div className={classes.detailListValue} style={{paddingBottom: 20}}>{context.profile.data.organisation_phone}</div>
                </div>
              </div>
              <Button variant="contained" classes={{contained: classes.bottomButton}} onClick={() => handleShowPanel('company')}>Edit</Button>
            </div>

            <div style={{width: 100}}></div>
            
            <div style={{marginBottom: 20}}>
              <Typography variant="h6" color="primary">Bank &amp; Payment Details</Typography>
              <Typography noWrap={false}>Please provide details of the BACS account that you<br />would like displayed on any emails.</Typography>

              <div className={classes.detailList}>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader}>Account Name</div>
                  <div className={classes.detailListValue}>{context.profile.data.bank_account_name}</div>
                </div>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader}>Account / IBAN Number</div>
                  <div className={classes.detailListValue}>{context.profile.data.bank_account_no}</div>
                </div>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader}>Sort / SWIFT or BIC Code</div>
                  <div className={classes.detailListValue}>{context.profile.data.bank_account_sort}</div>
                </div>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader}>Show Bank Info On Billing Emails</div>
                  <div className={classes.detailListValue}>{context.profile.data.bank_account_on_email ? 'Yes' : 'No'}</div>
                </div>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader}><b>Small Balance Write Off</b></div>
                  <div className={classes.detailListValue}><Divider style={{marginTop: 14}}/></div>
                </div>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader}>Enabled</div>
                  <div className={classes.detailListValue}>{context.profile.data.sbwo_apply ? 'Yes' : 'No'}</div>
                </div>
                <div className={classes.detailListRow}>
                  <div className={classes.detailListHeader} style={{paddingBottom: 20}}>Amount</div>
                  <div className={classes.detailListValue} style={{paddingBottom: 20}}>{context.profile.data.sbwo_apply ? localeCurrency(context.profile.data.sbwo_amount, context.profile.data.currency) : 'N/A'}</div>
                </div>
              </div>
              <Button variant="contained" classes={{contained: classes.bottomButton}} onClick={() => handleShowPanel('bank')}>Edit</Button>
            </div>

          </div>

          <div className={classes.taxRatesWrapper}>
            <Typography variant="h6" color="primary">Tax Rates</Typography>
            <Typography>If any tax applies to your services you can add in the rates here and they will be applied to the service transactions.</Typography>
            <AutoTable 
              rows={context.profile.data.tax_rates} 
              orderBy="service_type" 
              headCells={taxRateHeadCells}
              cellReplacementProps={{
                'service_type': (val, id) => val === null ? 'Default' : val,
                'tax_rate': (val, id) => `${parseFloat(val*100).toFixed(2)}%`,
              }}
              handleRowClick={(row) => handleShowPanel('edit_taxrate', row)}
            />
            <Button variant="contained" classes={{contained: classes.bottomButton}} onClick={() => handleShowPanel('add_taxrate')}>Add</Button>
          </div>

          <SideSlidePanel title="Add a Technical Contact" side="left" show={showPanel === 'add_technical'} handleClose={() => handleShowPanel(null)}><ProfileContactAdd type="contacts_technical" handleClose={() => handleShowPanel(null)} /></SideSlidePanel>
          <SideSlidePanel title="Add an Accounts Contact" side="left" show={showPanel === 'add_accounts'} handleClose={() => handleShowPanel(null)}><ProfileContactAdd type="contacts_accounts" handleClose={() => handleShowPanel(null)} /></SideSlidePanel>
          
          <SideSlidePanel title="Edit a Technical Contact" side="left" show={showPanel === 'edit_technical'} handleClose={() => handleShowPanel(null)}><ProfileContactEdit type="contacts_technical" handleClose={() => handleShowPanel(null)} form={showPanelData} /></SideSlidePanel>
          <SideSlidePanel title="Edit an Accounts Contact" side="left" show={showPanel === 'edit_accounts'} handleClose={() => handleShowPanel(null)}><ProfileContactEdit type="contacts_accounts" handleClose={() => handleShowPanel(null)} form={showPanelData} /></SideSlidePanel>

          <SideSlidePanel title="Edit General Settings" side="left" show={showPanel === 'company'} handleClose={() => handleShowPanel(null)}><ProfileEditCompanyDetails data={context.profile.data} handleClose={() => handleShowPanel(null)} /></SideSlidePanel>
          <SideSlidePanel title="Edit Bank Details" side="left" show={showPanel === 'bank'} handleClose={() => handleShowPanel(null)}><ProfileEditBankDetails data={context.profile.data} handleClose={() => handleShowPanel(null)} /></SideSlidePanel>
          <SideSlidePanel title="Add a Tax Rate" side="left" show={showPanel === 'add_taxrate'} handleClose={() => handleShowPanel(null)}><ProfileTaxRateAdd handleClose={() => handleShowPanel(null)} /></SideSlidePanel>
          <SideSlidePanel title="Edit a Tax Rate" side="left" show={showPanel === 'edit_taxrate'} handleClose={() => handleShowPanel(null)}><ProfileTaxRateEdit form={showPanelData} handleClose={() => handleShowPanel(null)} /></SideSlidePanel>
          
          </React.Fragment>
        }

    </div>
  );
}

export default GroupProfile;
