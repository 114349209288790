import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, Button} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import Images from '../assets/imgs/';
import BerthManagePedestals from './berthManagePedestals';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import { keyValueArray } from '../global/helpers';
import API from '../global/api';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  boatImage: {
    width: 260,
    height: 130,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginBottom: 60
  },
}));

const BerthEdit = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [ mooringTypes, setMooringTypes ] = useState([]);

  const [ form, setForm ] = useState(props.data);
  const [ pedestals, setPedestals ] = useState([]);
  const [ pedestalsRemove, setPedestalsRemove ] = useState([]);
  const [ pedestalsAdd, setPedestalsAdd ] = useState([]);

  const [ validation, setValidation ] = useState(makeValidation([
    {
      name: 'berth_reference',
      display_name: 'Location',
      required: true
    },
    {
      name: 'id_mooring_type',
      display_name: 'Mooring Type',
      required: true
    },
    {
      name: 'loa_min',
      display_name: 'LOA Min',
      required: true
    },
    {
      name: 'loa_max',
      display_name: 'LOA Max',
      required: true
    },
    {
      name: 'loa_measurement',
      display_name: 'LOA Measurement',
      required: true
    },
    {
      name: 'map_reference',
      display_name: 'Map Reference',
      required: true
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    setError(null);
    setLoading(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){


      //
      // Execute an api request and get the returned result
      //
      API.req(`/berth/${context.profile.data.id_organisation}`, 'PUT', form).then(
        async res => {

          context.berthUpdate(res.result);

          for(let a=0;a<pedestalsAdd.length;a++){
            const id_pedestal = parseInt(pedestalsAdd[a]);
            try {
              const add = await API.req(`/berth/pedestal/${context.profile.data.id_organisation}`, 'POST', {id_berth: form.id_berth, id_pedestal: id_pedestal});
              context.berthPedestalAdd(add.result);
            } catch (error) {
              context.showSnack({message: `An error occured assigning a pedestal to this berth`}, 'error')
            }
          }

          for(let r=0;r<pedestalsRemove.length;r++){
            const id_pedestal = parseInt(pedestalsRemove[r]);
            try {
              const rem = await API.req(`/berth/pedestal/${context.profile.data.id_organisation}`, 'DELETE', {id_berth: form.id_berth, id_pedestal: id_pedestal});
              context.berthPedestalRemove({id_berth: form.id_berth, id_pedestal: id_pedestal});
            } catch (error) {
              context.showSnack({message: `An error occured removing a pedestal from this berth`}, 'error')
            }
          }

          setLoading(false);

          if(typeof props.handleClose === 'function')
            props.handleClose();

          context.showSnack({message: 'Berth updated!'}, 'success')

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )

    }
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  useEffect(() => {
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.berth_mooring_types !== 'undefined')
      setMooringTypes(keyValueArray(context.meta.data.berth_mooring_types, 'id_mooring_type', 'mooring_type'));

  }, [context.meta])

  useEffect(() => {
    if(props.data !== null && typeof props.data.id_berth !== 'undefined'){
      const berth_pedestals = context.berthPedestals.data.filter(bp => parseInt(bp.id_berth) === parseInt(props.data.id_berth))
      setPedestals(berth_pedestals);
    }
  }, [props.data])


  const handleAddPedestal = (id_pedestal) => {
    console.log(id_pedestal);
    let newPedAdd = JSON.parse(JSON.stringify(pedestalsAdd));
    let newPedRem = JSON.parse(JSON.stringify(pedestalsRemove));

    if(pedestals.findIndex(bp => parseInt(bp.id_pedestal) === parseInt(id_pedestal)) === -1 && newPedAdd.indexOf(id_pedestal) === -1)
      newPedAdd.push(id_pedestal);
    
    if(newPedRem.indexOf(id_pedestal) > -1)
      newPedRem.splice(newPedRem.findIndex(bp => bp.id_pedestal), 1);

    setPedestalsAdd(newPedAdd);
    setPedestalsRemove(newPedRem);
  }


  const handleRemovePedestal = (id_pedestal) => {
    let newPedAdd = JSON.parse(JSON.stringify(pedestalsAdd));
    let newPedRem = JSON.parse(JSON.stringify(pedestalsRemove));

    if(pedestals.findIndex(bp => parseInt(bp.id_pedestal) === parseInt(id_pedestal)) > -1 && newPedRem.indexOf(id_pedestal) === -1)
      newPedRem.push(id_pedestal);
    
    if(newPedAdd.indexOf(id_pedestal) > -1)
      newPedAdd.splice(newPedAdd.findIndex(bp => bp.id_pedestal), 1);

    setPedestalsAdd(newPedAdd);
    setPedestalsRemove(newPedRem);
  }

  return (
      <div className={classes.detailsWrapper}>

      {error && <Alert severity="error">{error}</Alert>}

        <div className={classes.form}>
          
          <div className="flex-row justify-center">
            <div className={classes.boatImage}>
              <img src={Images.Boat_Icons_Blue} style={{width: '100%', height: 'auto'}} alt="Berth" />
            </div>
          </div>

          <div className="flex-row">
            <InputField name='berth_reference' validation={validation.berth_reference} value={form.berth_reference} handleChange={handleInputChange} hoverLabel={true} />
            <InputField name='id_mooring_type' validation={validation.id_mooring_type} value={form.id_mooring_type} handleChange={handleInputChange} hoverLabel={true} type="select" items={mooringTypes} />
          </div>

          <div className="flex-row">
            <InputField name='loa_min' validation={validation.loa_min} value={form.loa_min} handleChange={handleInputChange} hoverLabel={true} />
            <InputField name='loa_max' validation={validation.loa_max} value={form.loa_max} handleChange={handleInputChange} hoverLabel={true} />
            <InputField name='loa_measurement' validation={validation.loa_measurement} value={form.loa_measurement} handleChange={handleInputChange} hoverLabel={true} type="select" items={['Metres', 'Feet']} />
          </div>

          <div className="flex-row">
            <InputField name='map_reference' validation={validation.map_reference} value={form.map_reference} handleChange={handleInputChange} hoverLabel={true} />
            <div className="flex"></div>
          </div>

          <BerthManagePedestals data={pedestals} adding={pedestalsAdd} removing={pedestalsRemove} handleAddPedestal={handleAddPedestal} handleRemovePedestal={handleRemovePedestal} />

        </div>

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Save &amp; Close</ProgressButton>
        </div>

      </div>
  );
}

export default BerthEdit;
