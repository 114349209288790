import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, FormControlLabel, Divider, Typography} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import { keyValueArray, localeCurrency } from '../global/helpers';
import BooleanSwitch from '../components/booleanSwitch';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  }
}));

const ProfileEditBankDetails = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ form, setForm ] = useState(props.data);
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [ paymentFrequencies, setPaymentFrequencies ] = useState([]);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'bank_account_name',
      display_name: 'Account Name',
      required: false
    },
    {
      name: 'bank_account_no',
      display_name: 'Account / IBAN Number',
      required: false
    },
    {
      name: 'bank_account_sort',
      display_name: 'Sort / SWIFT or BIC Code',
      required: false
    },
    {
      name: 'bank_account_on_email',
      display_name: 'Show Bank Info On Billing Emails',
      required: false
    },
    {
      name: 'sbwo_apply',
      display_name: 'Apply Automatic Small Balance Write Off',
      required: false
    },
    {
      name: 'sbwo_amount',
      display_name: 'Up to amount',
      required: false
    },
    {
      name: 'periodic_billing',
      display_name: 'Periodic Billing',
      required: false
    },
    {
      name: 'periodic_billing_type',
      display_name: 'Billing Interval',
      required: false
    },
    {
      name: 'periodic_billing_day',
      display_name: 'Billing Day',
      required: false
    }
  ]));

  const periodic_billing_types = [
    {key: 2, value: 'Weekly'}, 
    {key: 3, value: 'Monthly'}
  ]

  const periodic_billing_days = [
    {key: 1, value: 'Monday'}, 
    {key: 2, value: 'Tuesday'}, 
    {key: 3, value: 'Wednesday'}, 
    {key: 4, value: 'Thursday'}, 
    {key: 5, value: 'Friday'}, 
    {key: 6, value: 'Saturday'}, 
    {key: 0, value: 'Sunday'}
  ]

  const periodic_billing_dates = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28];

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  

  const handleSwitchChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.checked ? 1 : 0;
    setForm(newForm);
  }


  const handleSubmit = () => {

    setError(null);
    setLoading(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){

      //
      // Execute an api request and get the returned result
      //
      API.req(`/organisation/${context.profile.data.id_organisation}`, 'PUT', form).then(
        res => {
          setLoading(false);

          if(res.success === true)
            context.profileUpdate(res.result);
            
          if(typeof props.handleClose === 'function')
            props.handleClose();

          context.showSnack({message: 'Bank information saved!'}, 'success')

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )

    }
  }

  useEffect(() => {
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.organisation_payment_frequencies !== 'undefined')
      setPaymentFrequencies(keyValueArray(context.meta.data.organisation_payment_frequencies, 'id_payment_frequency', 'payment_frequency'));

  }, [context.meta])

  useEffect(() => {
    console.log(form);
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  return (
      <div className={classes.detailsWrapper}>

        {error && <Alert severity="error">{error}</Alert>}

        <div className={classes.form}>

          <div className="flex-row">
            <InputField name='bank_account_name' validation={validation.bank_account_name} value={form.bank_account_name} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='bank_account_no' validation={validation.bank_account_no} value={form.bank_account_no} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='bank_account_sort' validation={validation.bank_account_sort} value={form.bank_account_sort} handleChange={handleInputChange} hoverLabel={true} />
          </div>
        
          <div className="flex-row align-center" style={{marginBottom: 10}}>
            <div className="flex">
              <FormControlLabel
                labelPlacement="end"
                label="Show Bank Info On Billing Emails"
                control={(
                  <BooleanSwitch checked={parseInt(form.bank_account_on_email) === 1} handleSwitchChange={handleSwitchChange} name='bank_account_on_email'  />
                )}>
              </FormControlLabel>
            </div>
          </div>

          <Divider style={{marginBottom: 20}} />

          <div style={{marginBottom: 10}}>
            <Typography variant="h6">Small Balance Write Off</Typography>
            <Typography variant="caption">This setting will automatically write off customer balances if their balance dips below {localeCurrency(0, form.currency)} up to a value of your choice.</Typography>
          </div>
        

          <div className="flex-row align-center" style={{marginBottom: 10}}>
            <div className="flex">
              <FormControlLabel
                labelPlacement="end"
                label="Switch On" 
                control={(
                  <BooleanSwitch checked={parseInt(form.sbwo_apply) === 1} handleSwitchChange={handleSwitchChange} name='sbwo_apply'  />
                )}>
              </FormControlLabel>
            </div>
            <div className="flex"></div>
          </div>
        

          <div className="flex-row align-center">
            {form.sbwo_apply === 1 && <InputField name='sbwo_amount' placeholder='0.00' validation={validation.sbwo_amount} value={form.sbwo_amount} handleChange={handleInputChange} hoverLabel={true} />}
            <div className="flex"></div>
          </div>

          {(form.sbwo_apply === 1 && parseFloat(form.sbwo_amount) > -0.01) &&
          <div className="flex-row align-center">
            <Typography variant="caption">Balances between {localeCurrency(0, form.currency)} and {localeCurrency((0-parseFloat(form.sbwo_amount)), form.currency)} will be automatically written off.</Typography>
          </div>
          }



          

          <Divider style={{marginBottom: 20}} />

          <div style={{marginBottom: 10}}>
            <Typography variant="h6">Periodic Billing</Typography>
            <Typography variant="caption">This setting will automatically run through your ongoing services and process usage against the customer account.</Typography>
          </div>


          <div className="flex-row align-center" style={{marginBottom: 10}}>
            <div className="flex">
              <FormControlLabel
                labelPlacement="end"
                label="Switch On" 
                control={(
                  <BooleanSwitch checked={parseInt(form.periodic_billing) === 1} handleSwitchChange={handleSwitchChange} name='periodic_billing'  />
                )}>
              </FormControlLabel>
            </div>
            <div className="flex"></div>
          </div>

          <div className="flex-row align-center">
            {form.periodic_billing === 1 && <InputField name='periodic_billing_type' type='select' items={periodic_billing_types} validation={validation.periodic_billing_type} value={form.periodic_billing_type} handleChange={handleInputChange} hoverLabel={true} />}
            {form.periodic_billing === 1 && <InputField name='periodic_billing_day' type='select' items={form.periodic_billing_type === 2 ? periodic_billing_days : periodic_billing_dates} validation={validation.periodic_billing_day} value={form.periodic_billing_day} handleChange={handleInputChange} hoverLabel={true} />}
          </div>



        </div>

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Save &amp; Close</ProgressButton>
        </div>

      </div>
  );
}

export default ProfileEditBankDetails;
