import React from 'react';
import {makeStyles, useTheme, Typography, IconButton} from '@material-ui/core';
import Images from '../assets/imgs/index.js';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  pedestal: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '3px 10px',
    borderRadius: 20,
    backgroundColor: theme.palette.grey[200],
    flex: 1,
    minWidth: 200
  },
  pedestalIcon: {
    height: 80,
    width: 'auto',
    marginRight: 10
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10
  }
}))

const PedestalSnippet = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const getImage = () => {
    switch(props.pedestal.pedestal_type){
      case 'Quantum':
        return Images.Pedestal_Icons_Quantum;
      case 'Classic':
        return Images.Pedestal_Icons_Classic;
      case 'MegaMaster':
        return Images.Pedestal_Icons_MegaMaster;
      case 'Fin':
        return Images.Pedestal_Icons_Fin;
      default:
        return null;
    }
  }

  const handleRemove = () => {
    if(typeof props.handleRemove === 'function')
      props.handleRemove();
  }

  return (
    <div className={classes.pedestal}>
      <IconButton onClick={handleRemove} className={classes.closeIcon} size='small'><CloseIcon style={{fontSize: 18}} /></IconButton>
      <div className={classes.pedestalIcon}><img src={getImage()} style={{height: '100%', width: 'auto'}} alt="Quantum" /></div>
      <div>
        <Typography style={{marginRight: 20}}>{props.pedestal.pedestal_reference}</Typography>
      </div>
    </div>
  );
}

export default PedestalSnippet;