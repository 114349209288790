import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import Signin from './context/signin/signin';
import Main from './context/main';

import Home from './routes/home';
import NotFound from './routes/notFound';
import ServicesAssigned from './routes/servicesAssigned';
import Utilities from './routes/utilities';
import ServicesUpcoming from './routes/servicesUpcoming';
import Customers from './routes/customers';
import CustomersBilling from './routes/customersBilling';
import Tariffs from './routes/tariffs';
import GroupProfile from './routes/profile';
import ReportUsage from './routes/reportUsage';
import ReportTransactions from './routes/reportTransactions';
import ReportRevenue from './routes/reportRevenue';
import Berths from './routes/berths';
import Pedestals from './routes/pedestals';
import UtilitiesOverview from './routes/utilitiesOverview';
import UserInvites from './routes/userInvites';
import Register from './context/signin/register';
import InviteAccept from './routes/inviteAccept';
import ResetPassword from './context/signin/resetPassword';

import { prefsLoad } from './actions/prefsActions';


const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    overflow: 'auto'
  }
}))


const App = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const isAuthorised = (sector, component) => {
    // if(props.reduxUser.profile.role != 1 && props.reduxUser.profile.system_sectors.findIndex(s => s.system_sector_handle == sector) == -1)
    //   return NotAuthorised;

    return component;
  }

  useEffect(() => {
    props.prefsLoad();
  }, [])

  return (
          
    <Router>
      <div className={classes.root}>
        {props.user.status !== 'in' && 
        
        <Switch>
            <Route path={"/invitation/:token"} exact component={Signin} ></Route>
            <Route path={"/signin/:token"} exact component={Signin} ></Route>
            <Route path={"/reset_password/:token"} exact component={ResetPassword} ></Route>
            <Route component={Signin} />
        </Switch>
        
        }

        {props.user.status === 'in' &&
        
        <SnackbarProvider
          maxSnack={3} 
          anchorOrigin={{vertical: 'top', horizontal: 'right',}}
        >
            <Main>

              <Switch>
                  <Route path={"/"} exact component={Home} ></Route>

                  <Route path={"/services/utilities"} exact component={isAuthorised('services', Utilities)} ></Route>
                  <Route path={"/services/assigned"} exact component={isAuthorised('services', ServicesAssigned)} ></Route>
                  <Route path={"/services/upcoming"} exact component={isAuthorised('services', ServicesUpcoming)} ></Route>
                  <Route path={"/services/berths"} exact component={isAuthorised('services', Berths)} ></Route>
                  <Route path={"/services/pedestals"} exact component={isAuthorised('services', Pedestals)} ></Route>
                  <Route path={"/services/utilities_overview"} exact component={isAuthorised('services', UtilitiesOverview)} ></Route>

                  <Route path={"/customers"} exact component={isAuthorised('customers', Customers)} ></Route>
                  <Route path={"/customers/billing"} exact component={isAuthorised('customers', CustomersBilling)} ></Route>

                  <Route path={"/profile"} exact component={isAuthorised('profile', GroupProfile)} ></Route>
                  <Route path={"/profile/tariffs"} exact component={isAuthorised('profile', Tariffs)} ></Route>

                  <Route path={"/reports/usage"} exact component={isAuthorised('reports', ReportUsage)} ></Route>
                  <Route path={"/reports/transactions"} exact component={isAuthorised('services', ReportTransactions)} ></Route>
                  <Route path={"/reports/revenue"} exact component={isAuthorised('services', ReportRevenue)} ></Route>

                  <Route path={"/users/invites"} exact component={isAuthorised('users', UserInvites)} ></Route>
                  
                  <Route path={"/invitation/:token"} exact component={isAuthorised('services', InviteAccept)} ></Route>

                  <Route component={NotFound} />
              </Switch>

            </Main>
          </SnackbarProvider>
        }
      </div>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    prefsLoad: () => {
      dispatch(prefsLoad());
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
