import React, { useContext, useEffect, useState } from 'react';
import {useTheme, makeStyles, Typography, Divider, FormControlLabel, Collapse} from '@material-ui/core';
import BooleanSwitch from '../components/booleanSwitch';
import Alert from '@material-ui/lab/Alert';
import { localeCurrency } from '../global/helpers';

import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import ProgressButton from '../components/progressButton';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40,
    paddingTop: 40
  },
  label: {
    marginLeft: 0
  }
}));

const CustomerPaymentClear = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const {customer} = props;

  const [form, setForm] = useState({
    clear_full: true,
    amount: '0.00',
    send_receipt: false,
    your_ref: ''
  })
  
  const [ loading, setLoading ] = useState(false);
  const [error, setError] = useState(null);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'amount',
      display_name: 'Amount paid',
      required: false
    },
    {
      name: 'clear_full',
      required: false
    },
    {
      name: 'send_receipt',
      required: false
    },
    {
      name: 'your_ref',
      display_name: 'Your Reference',
      required: false
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSwitchChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.checked;
    setForm(newForm);
  }

  const amountPaid = () => {
    if(form.clear_full)
      return Math.abs(customer.balance);
    else
      return parseFloat(form.amount);
  }

  const handleSubmit = () => {

    setError(null);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){

      
      setLoading(true);

      const transaction = {
        id_customer: customer.id_customer,
        id_transaction_type: 4,
        transaction_amount: form.clear_full ? Math.abs(customer.balance) : form.amount,
        transaction_currency: customer.currency,
        your_ref: form.your_ref === '' ? null : form.your_ref
      }

      //
      // Execute an api request and get the returned result
      //
      API.req(`/customer/transaction/${context.profile.data.id_organisation}`, 'POST', transaction).then(
        async res => {
    
          if(res.success === true){
            context.customerUpdateBalance(res.result);
            context.showSnack({message: 'Payment cleared!'}, 'success')

            if(form.send_receipt){

              API.req(`/customer/billing/receipt/${context.profile.data.id_organisation}`, 'POST', {
                id_customer: res.result.id_customer,
                id_transaction: res.result.id_transaction
              }).then(
                async res => {
            
                  setLoading(false);
                  if(res.success === true)
                    context.showSnack({message: 'Receipt sent to customer!'}, 'success')
                  else
                    context.showSnack({message: 'Unable to send receipt!'}, 'error')

                  if(typeof props.handleClose === 'function')
                    props.handleClose();
        
                },
                err => {
                    setLoading(false);
                    context.showSnack({message: 'Unable to send receipt!'}, 'error')

                    if(typeof props.handleClose === 'function')
                      props.handleClose();
                }
              )

            } else {
              setLoading(false);
              if(typeof props.handleClose === 'function')
                props.handleClose();
            }

          } else {
            setLoading(false);
            setError('Unable to clear payment')
          }

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )
    }

  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  return (
    <div className={classes.detailsWrapper}>
      {!customer && <Alert severity="error">Customer not found</Alert>}
      {error && <Alert severity="error">{error}</Alert>}

      {customer &&
        <div>
            <Typography>Customer: <b>{customer.first_name} {customer.last_name}</b></Typography>
            <Typography>Email: <b>{customer.email}</b></Typography>
            <Typography>Boat: <b>{customer.boat_name}</b></Typography>
            <Typography color="primary">Amount Due: <b>{localeCurrency(Math.abs(customer.balance), customer.currency)} ({customer.currency})</b></Typography>

            <Divider style={{marginTop: 20, marginBottom: 20}} />

            <div className='flex-row'>
              <FormControlLabel
                labelPlacement="end"
                label="Clear the full amount?" 
                className={classes.label}
                control={(
                  <BooleanSwitch checked={form.clear_full} handleSwitchChange={handleSwitchChange} name='clear_full'  />
                )}>
              </FormControlLabel>
            </div>

            <div className='flex-row' style={{marginTop: 20}}>
              <InputField name='your_ref' validation={validation.your_ref} value={form.your_ref} handleChange={handleInputChange} hoverLabel={true} />
            </div>

            <Collapse in={!form.clear_full}>
              <div className='flex-row align-center'>
                <InputField name='amount' validation={validation.amount} value={form.amount} handleChange={handleInputChange} hoverLabel={true} />
                <div className='flex'><Typography variant="h6">({customer.currency})</Typography></div>
              </div>
            </Collapse>

            <div className='flex-row' style={{marginBottom: 20}}>
              <FormControlLabel
                  labelPlacement="end"
                  label="Email a receipt?" 
                  className={classes.label}
                  control={(
                    <BooleanSwitch checked={form.send_receipt} handleSwitchChange={handleSwitchChange} name='send_receipt'  />
                  )}>
              </FormControlLabel>
            </div>
            <Collapse className='flex-row' in={form.send_receipt}>
              <Typography>A receipt will be sent to <b>{customer.email}</b> for the amount of <b>{localeCurrency(amountPaid(), customer.currency)} ({customer.currency})</b></Typography>
            </Collapse>


        </div>
      }

      <div className='flex'></div>

      <div className='flex-row-end'>
        <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Confirm &amp; Send</ProgressButton>
      </div>

    </div>

  )

}

export default CustomerPaymentClear;