import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, Button, Divider, FormControlLabel, Typography, IconButton, ButtonGroup, Tooltip} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import BooleanSwitch from '../components/booleanSwitch';
import { localeCurrency, keyValueArray } from '../global/helpers';
import Images from '../assets/imgs/index';
import CustomerTransactions from './customerTransactions';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import StackSlidePanel from '../components/stackSlidePanel';
import CustomerLogs from './customerLogs';
import CustomerRfid from './customerRFID';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import CustomerLinkApp from './customerLinkApp';
import CustomerMoreMenu from './customerMoreMenu';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  addCredit: {
    flex: 1,
    backgroundColor: theme.palette.grey[200],
    padding: '2px 8px',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  iconButton: {
    width: 'auto',
    height: 22
  },
  iconButtonLg: {
    width: 'auto',
    height: 28
  }
}));

const CustomerManage = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [customerDeleted, setCustomerDeleted] = useState(false);

  const [ show, setShow ] = useState('form');
  const [ form, setForm ] = useState(props.data);
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [ paymentFrequencies, setPaymentFrequencies ] = useState([]);
  const [ accountTypes, setAccountTypes ] = useState([]);
  const [ discountGroups, setDiscountGroups ] = useState([]);

  const [ addCredit, setAddCredit ] = useState('0.00');
  const [ sendReceipt, setSendReceipt ] = useState(false);
  const [ addingCredit, setAddingCredit ] = useState(null);

  const [ validation, setValidation ] = useState(makeValidation([
    {
      name: 'your_ref',
      display_name: 'Your customer reference',
      required: false
    },
    {
      name: 'first_name',
      display_name: 'First Name',
      required: true
    },
    {
      name: 'last_name',
      display_name: 'Last Name',
      required: true
    },
    {
      name: 'email',
      display_name: 'Email',
      required: false
    },
    {
      name: 'phone',
      display_name: 'Phone',
      required: false
    },
    {
      name: 'boat_name',
      display_name: 'Boat Name',
      required: false
    },
    {
      name: 'id_customer_account_type',
      display_name: 'Account Type',
      required: false
    },
    {
      name: 'id_customer_payment_frequency',
      display_name: 'Billing Frequency',
      required: false
    },
    {
      name: 'id_discount_group',
      display_name: 'Discount Group',
      required: false
    }
  ]));

  const [creditValidation, setCreditValidation] = useState(makeValidation([
    {
      name: 'amount',
      display_name: 'Amount',
      required: true
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSwitchChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.checked ? 1 : 0;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    setError(null);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){

      
      setLoading(true);

      //
      // Execute an api request and get the returned result
      //
      API.req(`/customer/${context.profile.data.id_organisation}`, 'PUT', form).then(
        async res => {
    
          if(res.success === true)
            context.customerUpdate(res.result);

          
          if(addingCredit !== null){

            API.req(`/customer/transaction/${context.profile.data.id_organisation}`, 'POST', {
              id_customer: form.id_customer,
              id_transaction_type: 1,
              transaction_amount: addingCredit,
              transaction_currency: form.currency
            }).then(
              async res => {

                if(res.success === true){
                  context.customerUpdateBalance(res.result);
                  context.showSnack({message: 'Customer updated!'}, 'success')


                  if(sendReceipt){

                    API.req(`/customer/billing/receipt/${context.profile.data.id_organisation}`, 'POST', {
                      id_customer: res.result.id_customer,
                      id_transaction: res.result.id_transaction
                    }).then(
                      async res => {
                  
                        setLoading(false);
                        if(res.success === true)
                          context.showSnack({message: 'Receipt sent to customer!'}, 'success')
                        else
                          context.showSnack({message: 'Unable to send receipt!'}, 'error')
      
                        context.toggleGlobalPanel('manage_customer', false);
              
                      },
                      err => {
                          setLoading(false);
                          context.showSnack({message: 'Unable to send receipt!'}, 'error')
      
                          context.toggleGlobalPanel('manage_customer', false);
                      }
                    )
      
                  } else {
                    setLoading(false);
                    context.toggleGlobalPanel('manage_customer', false);
                  }


                } else {
                  setLoading(false);
                  setError('Unable to add credit')
                }

              },
              err => {
                  setLoading(false);
                  setError(typeof err === 'string' ? err : JSON.stringify(err))
              }
            )


          } else {
            setLoading(false);
            context.toggleGlobalPanel('manage_customer', false);
            context.showSnack({message: 'Customer updated!'}, 'success')
          }
            
          

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )
    }

  }

  const handleChangeAddCredit = e => {
    setAddCredit(e.target.value);
  }

  const handleAddCredit = () => {
    const valid = isValidForm({amount: addCredit}, creditValidation);
    setCreditValidation(valid.validation);
    if(valid.isValid){
      setAddingCredit(addCredit);
      setAddCredit('0.00');
    }
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  useEffect(() => {
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.customer_payment_frequencies !== 'undefined')
      setPaymentFrequencies(keyValueArray(context.meta.data.customer_payment_frequencies, 'id_customer_payment_frequency', 'customer_payment_frequency'));
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.customer_account_types !== 'undefined')
      setAccountTypes(keyValueArray(context.meta.data.customer_account_types, 'id_customer_account_type', 'customer_account_type'));

  }, [context.meta])

  useEffect(() => {
    
    if(typeof context.discountGroups.data !== 'undefined')
      setDiscountGroups([
        {
          key: null,
          value: '-- None'
        },
        ...keyValueArray(context.discountGroups.data.filter(g => g.deleted === null), 'id_discount_group', 'discount_name')
      ]);

  }, [context.discountGroups])

  useEffect(() => {

    const findCustomer = context.customers.data.find(c => c.id_customer === form.id_customer);
    if(typeof findCustomer === 'undefined')
      setCustomerDeleted(true);
    else
      setCustomerDeleted(false);

  }, [context.customers])

  return (
    <React.Fragment>
      <div className={classes.detailsWrapper}>
        {error && <Alert severity="error">{error}</Alert>}
        {customerDeleted && <Alert severity="error" style={{marginBottom: 20}}>This customer has been removed</Alert>}

        <React.Fragment> 
          <div className={classes.form}>
            <div className="flex-row align-center pb">
              <div className="flex"></div>
              <div className="flex flex-row-end">
                {!customerDeleted && 
                <ButtonGroup color="primary">
                  <Tooltip title="Transactions" aria-label="Transactions"><Button onClick={() => setShow('transactions')}><img src={Images.Icons_History_Icon} className={classes.iconButton} alt="Transactions"/></Button></Tooltip>
                  <Tooltip title="Logs" aria-label="Logs"><Button onClick={() => setShow('logs')}><img src={Images.Icons_Notifications_Icon} className={classes.iconButtonLg} alt="Logs" /></Button></Tooltip>
                  {Boolean(context.profile.data.rfid_enabled) && <Tooltip title="RFID Tags" aria-label="RFID Tags"><Button onClick={() => setShow('rfid')}><img src={Images.Icons_RFID_Icon} className={classes.iconButtonLg} alt="RFID Cards" /></Button></Tooltip>}
                  {Boolean(context.profile.data.dock_app_enabled) && <Tooltip title="Customer Dock Link" aria-label="Dock Link"><Button onClick={() => setShow('link')}><AllInclusiveIcon /></Button></Tooltip>}
                </ButtonGroup>
                }
              </div>
            </div>
            <div className="flex-row align-center">
              <InputField name='your_ref' validation={validation.your_ref} value={form.your_ref} handleChange={handleInputChange} hoverLabel={true} />
              <div className="flex">
                <FormControlLabel
                  labelPlacement="start"
                  label="Account active" 
                  control={(
                    <BooleanSwitch checked={parseInt(form.id_customer_status) === 1} handleSwitchChange={handleSwitchChange} name='id_customer_status'  />
                  )}>
                </FormControlLabel>
              </div>
            </div>
            <div className="flex-row">
              <InputField name='first_name' validation={validation.first_name} value={form.first_name} handleChange={handleInputChange} hoverLabel={true} />
              <InputField name='last_name' validation={validation.last_name} value={form.last_name} handleChange={handleInputChange} hoverLabel={true} />
            </div>
            <InputField name='email' validation={validation.email} value={form.email} handleChange={handleInputChange} hoverLabel={true} />
            <div className="flex-row">
              <InputField name='phone' validation={validation.phone} value={form.phone} handleChange={handleInputChange} hoverLabel={true} />
              <div className="flex"></div>
            </div>
            <InputField name='boat_name' validation={validation.boat_name} value={form.boat_name} handleChange={handleInputChange} hoverLabel={true} />
            <Divider style={{marginTop: 20, marginBottom: 20}} />
            <div className="flex-row align-center">
              <InputField type='select' name='id_discount_group' validation={validation.id_discount_group} value={form.id_discount_group} handleChange={handleInputChange} hoverLabel={true} items={discountGroups} />
            </div>
            <div className="flex-row">
              <InputField type='select' name='id_customer_account_type' validation={validation.id_customer_account_type} value={form.id_customer_account_type} handleChange={handleInputChange} hoverLabel={true} items={accountTypes} />
              {form.id_customer_account_type === 2 && 
              <InputField type='select' name='id_customer_payment_frequency' validation={validation.id_customer_payment_frequency} value={form.id_customer_payment_frequency} handleChange={handleInputChange} hoverLabel={true} items={paymentFrequencies} />
              }
            </div>
            
            <Divider style={{marginTop: 20, marginBottom: 20}} />

            <Typography variant="subtitle1" color="primary">Notifications</Typography>
            
            <div className="flex-row align-center mb mt">
              <div className="flex">
                <FormControlLabel
                  labelPlacement="end"
                  label="Breaker trip" 
                  control={(
                    <BooleanSwitch checked={parseInt(form.notify_tripped_power) === 1} handleSwitchChange={handleSwitchChange} name='notify_tripped_power'  />
                  )}>
                </FormControlLabel>
              </div>
              <div className="flex">
                <FormControlLabel
                  labelPlacement="end"
                  label="Cable disconnect" 
                  control={(
                    <BooleanSwitch checked={parseInt(form.notify_unplugged_socket) === 1} handleSwitchChange={handleSwitchChange} name='notify_unplugged_socket'  />
                  )}>
                </FormControlLabel>
              </div>
            </div>

            <Divider style={{marginTop: 20, marginBottom: 20}}  />


            <Typography variant="subtitle1" color="primary" style={{marginBottom: 10}}>Add credit</Typography>

            <div className="flex-row align-center">
              <InputField name='amount' validation={creditValidation.amount} value={addCredit} handleChange={handleChangeAddCredit} hoverLabel={true} />
              <div className="flex"><Button variant="contained" onClick={handleAddCredit} disabled={customerDeleted}>Add</Button></div>
            </div>

            {addingCredit !== null &&
            <div className="flex-row">
              <div className={classes.addCredit}>
                <Typography variant="caption">{localeCurrency(addingCredit, form.currency)} to be added.</Typography>
                <IconButton size="small" onClick={() => setAddingCredit(null)}><img src={Images.Icons_Bin_Icon} alt="Remove credit" width={24} style={{fill: 'purple'}} /></IconButton>
              </div>
            </div>}
            {addingCredit !== null &&
            <React.Fragment>
              <div style={{textAlign: 'center', marginTop: 20}}>
                <Typography variant="subtitle2">You are making a change to the current balance of {localeCurrency(form.balance, form.currency)}</Typography>
                <Typography variant="h5">New credit balance: <b>{localeCurrency(parseFloat(form.balance) + parseFloat(addingCredit), form.currency)}</b></Typography>
              </div>
              
              <div className="flex-row justify-center">
                <FormControlLabel
                  labelPlacement="start"
                  label="Email a receipt" 
                  control={(
                    <BooleanSwitch checked={sendReceipt} handleSwitchChange={e => setSendReceipt(e.target.checked)} name='send_receipt'  />
                  )}>
                </FormControlLabel>
              </div>
            </React.Fragment>
            }

          </div>

          <div className="flex"></div>

          <div className="flex-row" style={{marginTop: 20}}>

            <CustomerMoreMenu customer={form} />

            <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit} disabled={customerDeleted}>Save &amp; Close</ProgressButton>
          </div>
        </React.Fragment>
      </div>

      <StackSlidePanel show={show === 'transactions'} handleClose={() => setShow('form')} title='Transaction history'><CustomerTransactions customer={form} handleBack={() => setShow('form')} /></StackSlidePanel>
      <StackSlidePanel show={show === 'logs'} handleClose={() => setShow('form')} title='Customer logs'><CustomerLogs customer={form} handleBack={() => setShow('form')} /></StackSlidePanel>
      <StackSlidePanel show={show === 'rfid' && Boolean(context.profile.data.rfid_enabled)} handleClose={() => setShow('form')} title='Customer RFID Cards'><CustomerRfid customer={form} handleBack={() => setShow('form')} /></StackSlidePanel>
      <StackSlidePanel show={show === 'link' && Boolean(context.profile.data.dock_app_enabled)} handleClose={() => setShow('form')} title='Customer Dock'><CustomerLinkApp customer={props.data} handleBack={() => setShow('form')} /></StackSlidePanel>
    </React.Fragment>
  );
}

export default CustomerManage;
