import Api from '../global/api';

export function bookingsLoad () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'BOOKINGS_LOAD',
            status: 'LOADING',
            payload: null
        })

        

        const data = require('../content/test-data/bookings.json');
        let local_data = await Api.getLocal('bookings');
        if(local_data === null){
            Api.setLocal('bookings', data);
            local_data = data;
        }


        dispatch({
            type: 'BOOKINGS_LOAD',
            status: 'OK',
            payload: local_data
        })

    }
}

export function bookingAdd (payload) {
    return dispatch => {

        // Loading
        dispatch({
            type: 'BOOKING_ADD',
            status: 'OK',
            payload: payload
        })

        return 'success';

    }
}

export function bookingUpdate (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'BOOKING_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}