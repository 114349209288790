const prefsReducer = (state = {
    status: 'LOADING',
    msg: '',
    data: null
}, action) => {
    const current_data = JSON.parse(JSON.stringify(state));
    const status = typeof action.status !== 'undefined' ? action.status.toUpperCase(): null;

    switch(action.type){
        case "PREFS_LOAD":
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: status === 'OK'?action.payload:current_data
            }
            break;
        case "PREFS_UPDATE":                
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: status === 'OK'?action.payload:current_data
            }
            break;
        default:
    }
    return state;
}

export default prefsReducer;