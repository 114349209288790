import { CircularProgress, Collapse, List, ListItem, ListItemText, Typography, useTheme, Popover, makeStyles } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react'
import InputField from '../components/inputField';
import globalContext from '../context/globalContext'
import {makeValidation, isValidForm} from '../global/formHelper';

const useStyles = makeStyles((theme) => ({
    hoverCollapse: {
        position: 'absolute', 
        zIndex:888, 
        width: 300,
        maxHeight: 300, 
        backgroundColor: '#ffffff',
        overflowY: 'scroll',
        boxShadow: '0 6px 6px rgba(0,0,0,.3)'
    },
    collapse: {
        maxHeight: 150, 
        overflowY: 'scroll'
    }
}))

const SearchGlobal = props => {

    const context = useContext(globalContext);
    const theme = useTheme();
    const classes = useStyles(theme);

    const [ loading , setLoading ] = useState(false);
    const [ showResults, setShowResults ] = useState(false);
    const [ results, setResults ] = useState([]);

    const [form, setForm] = useState({
        search: ''
    });

    const [validation, setValidation] = useState(makeValidation([
        {
            name: 'search',
            display_name: typeof props.label !== 'undefined' ? props.label : 'Find a service',
            required: false
        }
    ]));

    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleInputClear = () => {
        let newForm = {...form};
        newForm.search = '';
        setForm(newForm);
    }

    const handleSubmit = async () => {

        setLoading(false);

        if(form.search === ''){
            setResults([]);
            setShowResults(false);
        } else {
            
            const valid = isValidForm(form, validation);
            setValidation(valid.validation);
            if(valid.isValid){
                setLoading(true);

                try {

                    let arr = [];
                    context.berths.data.forEach(b => {
                        const idx = b.berth_reference.toString().toUpperCase().indexOf(form.search.toUpperCase());
                        if(idx > -1)
                            arr.push({
                                idx,
                                id: b.id_berth,
                                type: 'BERTH',
                                reference: `Berth ${b.berth_reference}`,
                                description: `${b.mooring_type} ${b.loa_min}-${b.loa_max} ${b.loa_measurement}`
                            })
                    })

                    context.pedestals.data.forEach(p => {
                        const idx = p.pedestal_reference.toString().toUpperCase().indexOf(form.search.toUpperCase());
                        if(idx > -1)
                            arr.push({
                                idx,
                                id: p.id_pedestal,
                                type: 'PEDESTAL',
                                reference: `Pedestal ${p.pedestal_reference}`,
                                description: p.pedestal_type
                            })
                    })

                    context.utilities.data.forEach(u => {
                        const idx = u.identity !== null ? u.identity.toUpperCase().indexOf(form.search.toUpperCase()) : u.id_utility.toString().toUpperCase().indexOf(form.search.toUpperCase());

                        if(idx > -1)
                            arr.push({
                                idx,
                                id: u.id_utility,
                                type: 'UTILITY',
                                reference: `Utility ${u.identity !== null ? u.identity : '#'+u.id_utility}`,
                                description: `${u.utility_type} - ${u.utility_description}`
                            })
                    })

                    arr = arr.sort((a, b) => {
                        return a.idx < b.idx ? -1 : 1;
                    })

                    setResults(arr);

                } catch (error) {

                    setResults(null);
                    console.log(error);
                    
                } finally {

                    setShowResults(true);
                    setLoading(false);

                }
            }

        }

    }

    const handleSelect = item => {
        if(typeof props.handleSelect === 'function')
            props.handleSelect(item);
    }


    useEffect(() => {
        handleSubmit();
    }, [form])


    return (
        <div>
            <InputField 
                name='search' 
                validation={validation.search} 
                value={form.search} 
                handleChange={handleInputChange} 
                handleClear={handleInputClear}
                hoverLabel={true} 
                placeholder='Enter Berth, Pedestal or Utility ID'
                loading={loading}
                variant={typeof props.variant !== 'undefined' ? props.variant : 'filled'} 
                size={typeof props.size !== 'undefined' ? props.size : 'medium'} 
            />
            
            <Collapse in={showResults} className={props.hover ? classes.hoverCollapse : classes.collapse}>
                {loading && <CircularProgress size="18" color="primary" />}
                {(results.length === 0 && loading === false) && <Typography variant="caption" color="textSecondary">No results found</Typography>}
                {(results.length > 0 && loading === false) && 
                    <List dense={true}>
                        {results.map((r, idx) => (
                            <ListItem button key={idx} onClick={() => handleSelect(r)}><ListItemText primary={r.reference} secondary={r.description} /></ListItem>
                        ))}
                    </List>
                }
            </Collapse>
        </div>
    )

}

export default SearchGlobal