import React, {useState} from 'react';
import { useTheme, makeStyles, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import CustomerDelete from './customerDelete';

const useStyles = makeStyles(theme => ({
    menuIconError: {
        color: theme.palette.grey[400],
        '&:hover': {
            color: theme.palette.error.main,
        }
    }
}));

const CustomerMoreMenu = props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [confirmDelete, showConfirmDelete] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Button className={classes.menuIconError} onClick={() => showConfirmDelete(true)}><DeleteIcon style={{marginRight: 10}} /> Delete customer</Button>

            {/* <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}><DeleteIcon className={classes.menuIconError} /> Delete customer</MenuItem>
              </Menu>
            </div> */}

            {confirmDelete && <CustomerDelete id_customer={props.customer.id_customer} handleClose={() => showConfirmDelete(null)} handleSuccess={() => showConfirmDelete(null)}  />}

        </React.Fragment>
    )
}
export default CustomerMoreMenu;