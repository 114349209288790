import React, { useContext, useEffect, useState } from 'react';
import {useTheme, makeStyles, IconButton} from '@material-ui/core';
import AutoTable from '../components/autoTable';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import globalContext from '../context/globalContext';
import CustomerRfidAdd from '../content/customerRFIDAdd';
import CloseIcon from '@material-ui/icons/Close';
import CustomerRfidDelete from './customerRFIDDelete';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40,
    paddingTop: 20
  }
}));

const CustomerRfid = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext)

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cards, setCards] = useState([])

  const [removeId, setRemoveId] = useState(null);

  const headCells = ['id_tag', 'pin', 'enabled', 'remove']


  const handleAdd = (result) => {
    let newCards = JSON.parse(JSON.stringify(cards));
    newCards.push(result);
    setCards(newCards);
  }

  

  const loadCards = async () => {

    setLoading(true);
    setError(null);

    API.req(`/customer/rfids/${context.profile.data.id_organisation}`, 'GET', {id_customer: props.customer.id_customer}).then(
      async res => {

        if(res.success === true){
          setLoading(false);
          setError(null);
          setCards(res.result);
        } else {
          setLoading(false);
          setError(res.msg);
          setCards([]);
        }

      },
      err => {
          setLoading(false);
          setError(typeof err === 'string' ? err : JSON.stringify(err))
          setCards([]);
      }
    )

  }

  useEffect(() => {

    loadCards();

  }, [props.customer])


  const handleRemoveSuccess = () => {
    setRemoveId(null);
    loadCards();
  }


  

  return (
      <div className={classes.detailsWrapper}>

        {error && <Alert severity="error">{error}</Alert>}

        <AutoTable 
          loading={loading} 
          headCells={headCells}
          idCol='id_customer_rfid'
          rows={cards}
          orderBy="enabled"
          cellReplacementProps={{
            'enabled': (val, id) => val === 1 ? 'True' : 'False',
            'remove': (val, id) => (<IconButton onClick={() => setRemoveId(id)} size="small"><CloseIcon style={{fontSize: 18}} /></IconButton>)
          }}
         />

         
        <CustomerRfidAdd customer={props.customer} handleSuccess={handleAdd} />
        {removeId !== null && <CustomerRfidDelete id_customer_rfid={removeId} handleClose={() => setRemoveId(null)} handleSuccess={handleRemoveSuccess} />}
      </div>
  );
}

export default CustomerRfid;
