import React, { useState, useEffect } from 'react';
import {useTheme, makeStyles, IconButton, Typography, Box} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  conatinerOut: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 999,
    backgroundColor: '#fff',
    overflowY: 'auto',
    overflowX: 'auto',
    transform: 'translateX(100%)',
    visibility: 'hidden',
    right: 0,
    top: 0,
    inset: 0,
    transition: 'all .3s ease',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerIn: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    maxWidth: 600,
    zIndex: 999,
    backgroundColor: '#fff',
    overflowY: 'auto',
    overflowX: 'hidden',
    transform: 'translateX(0)',
    right: 0,
    top: 0,
    transition: 'all .3s ease',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inner: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '86%',
    paddingTop: 80
  },
  close: {
    position: 'absolute',
    top: 20,
    left: 20
  }
}));


const StackSlidePanel = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const [ show, setShow ] = useState(false)
  const [ render, setRender ] = useState(false);

  useEffect(() => {
    setShow(props.show);
    setRender(props.show);
  }, [props.show])

  const handleAnimationEnd = () => {
    if(show === false){
      setRender(false);
      if(typeof props.handleClose === 'function')
        props.handleClose();
    }
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <Box 
      className={show ? classes.containerIn : classes.conatinerOut} 
      onTransitionEnd={handleAnimationEnd}
    >
        <IconButton onClick={handleClose} className={classes.close}><ArrowBackIcon /></IconButton>
        {render && 
        <div className={classes.inner}>
          {props.title && <Typography variant='h4' color="primary">{props.title}</Typography>}
          {props.children}
        </div>
        }
    </Box>
  );
}

export default StackSlidePanel;
