import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, Typography, FormControlLabel} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import BooleanSwitch from '../components/booleanSwitch';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  }
}));

const ProfileEditCompanyDetails = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ form, setForm ] = useState(props.data);
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'organisation_name',
      display_name: 'Company Name',
      required: true
    },
    {
      name: 'tax_number',
      display_name: 'VAT / Tax Number',
      required: false
    },
    {
      name: 'organisation_address',
      display_name: 'Address',
      required: false
    },
    {
      name: 'organisation_email',
      display_name: 'Email',
      required: false
    },
    {
      name: 'organisation_phone',
      display_name: 'Phone Number',
      required: false
    },
    {
      name: 'app_user_session_capacity',
      display_name: 'Max Active Services',
      required: false,
      additional: [
        {
          type: 'ABOVE',
          value: 0,
          error: 'Must be a number above 0'
        }
      ]
    },
    {
      name: 'app_user_usage_timeout_mins',
      display_name: 'No Usage Timeout (Mins)',
      required: false,
      additional: [
        {
          type: 'ABOVE',
          value: -1,
          error: 'Must be 0 or above, set as 0 to turn off this feature'
        }
      ]
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }
  
  const handleSwitchChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.checked ? 1 : 0;
    setForm(newForm);
  }

  const handleSubmit = () => {

    try {

      setError(null);
      setLoading(true);
  
      const valid = isValidForm(form, validation);
      setValidation(valid.validation);
      
      if(valid.isValid){

        //
        // Execute an api request and get the returned result
        //
        API.req(`/organisation/${context.profile.data.id_organisation}`, 'PUT', form).then(
          res => {
            setLoading(false);

            if(res.success === true)
              context.profileUpdate(res.result);
              
            if(typeof props.handleClose === 'function')
              props.handleClose();

            context.showSnack({message: 'Profile saved!'}, 'success')

          },
          err => {
              setError(typeof err === 'string' ? err : JSON.stringify(err))
          }
        )

      } else
        setError('Please check all fields are correct');
      
    } catch (error) {

      setError(typeof error === 'string' ? error : JSON.stringify(error))
      
    } finally {

      setLoading(false);

    }
    
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  return (
      <div className={classes.detailsWrapper}>

        {error && <div className="pb"><Alert severity="error">{error}</Alert></div>}

        <div className={classes.form}>

          <div className="flex-row">
            <InputField name='organisation_name' validation={validation.organisation_name} value={form.organisation_name} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='tax_number' validation={validation.tax_number} value={form.tax_number} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='organisation_address' validation={validation.organisation_address} value={form.organisation_address} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='organisation_email' validation={validation.organisation_email} value={form.organisation_email} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='organisation_phone' validation={validation.organisation_phone} value={form.organisation_phone} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <Typography variant="subtitle1" color="primary" style={{marginBottom: 10}}>Default customer settings</Typography>

          <div className="flex-row mb">
            <FormControlLabel
              labelPlacement="end"
              label="Breaker trip" 
              control={(
                <BooleanSwitch checked={parseInt(form.default_notify_tripped_power) === 1} handleSwitchChange={handleSwitchChange} name='default_notify_tripped_power'  />
              )}>
            </FormControlLabel>
          </div>

          <div className="flex-row mb">
            <FormControlLabel
              labelPlacement="end"
              label="Cable disconnect" 
              control={(
                <BooleanSwitch checked={parseInt(form.default_notify_unplugged_socket) === 1} handleSwitchChange={handleSwitchChange} name='default_notify_unplugged_socket'  />
              )}>
            </FormControlLabel>
          </div>


          <Typography variant="subtitle1" color="primary" style={{marginBottom: 10}}>App settings</Typography>

          <div className="flex-row">
            <InputField name='app_user_session_capacity' type="number" validation={validation.app_user_session_capacity} value={form.app_user_session_capacity} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          <div className="flex-row">
            <InputField name='app_user_usage_timeout_mins' type="number" validation={validation.app_user_usage_timeout_mins} value={form.app_user_usage_timeout_mins} handleChange={handleInputChange} hoverLabel={true} />
          </div>


        </div>

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Save &amp; Close</ProgressButton>
        </div>

      </div>
  );
}

export default ProfileEditCompanyDetails;
