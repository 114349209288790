import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, Button, Divider} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';
import { keyValueArray } from '../global/helpers';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  }
}));

const CustomerAdd = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const context = useContext(globalContext);

  const [form, setForm] = useState({
    your_ref: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    boat_name: '',
    id_customer_account_type: 1,
    credit: '0.00',
    id_customer_payment_frequency: 1,
    id_customer_status: 1,
    id_discount_group: null
  })
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [ paymentFrequencies, setPaymentFrequencies ] = useState([]);
  const [ accountTypes, setAccountTypes ] = useState([]);
  const [ discountGroups, setDiscountGroups ] = useState([]);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'your_ref',
      display_name: 'Your customer reference',
      required: false
    },
    {
      name: 'first_name',
      display_name: 'First Name',
      required: true
    },
    {
      name: 'last_name',
      display_name: 'Last Name',
      required: true
    },
    {
      name: 'email',
      display_name: 'Email',
      required: false
    },
    {
      name: 'phone',
      display_name: 'Phone',
      required: false
    },
    {
      name: 'boat_name',
      display_name: 'Boat Name',
      required: true
    },
    {
      name: 'id_customer_account_type',
      display_name: 'Account Type',
      required: true
    },
    {
      name: 'credit',
      display_name: 'Credit',
      required: true
    },
    {
      name: 'id_customer_payment_frequency',
      display_name: 'Billing Frequency',
      required: true
    },
    {
      name: 'id_discount_group',
      display_name: 'Discount Group',
      required: false
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    setError(null);
    setLoading(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){
      //
      // Execute an api request and get the returned result
      //
      API.req(`/customer/${context.profile.data.id_organisation}`, 'POST', form).then(
        async res => {
          setLoading(false);

          const result = await API.addToLocalArray('customers', res.result)

          if(result.success === true){
            context.customerAdd(res.result);

            if(typeof props.handleSuccess === 'function')
              props.handleSuccess(res.result);

            if(typeof props.global === 'undefined' || props.global === true)
              context.toggleGlobalPanel('add_customer', false);
          }

          context.showSnack({message: 'Customer created!'}, 'success')

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )
    }

  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  useEffect(() => {
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.customer_payment_frequencies !== 'undefined')
      setPaymentFrequencies(keyValueArray(context.meta.data.customer_payment_frequencies, 'id_customer_payment_frequency', 'customer_payment_frequency'));
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.customer_account_types !== 'undefined')
      setAccountTypes(keyValueArray(context.meta.data.customer_account_types, 'id_customer_account_type', 'customer_account_type'));

  }, [context.meta])

  useEffect(() => {
    
    if(typeof context.discountGroups.data !== 'undefined')
      setDiscountGroups([
        {
          key: null,
          value: '-- None'
        },
        ...keyValueArray(context.discountGroups.data.filter(g => g.deleted === null), 'id_discount_group', 'discount_name')
      ]);

  }, [context.discountGroups])

  return (

      <div className={classes.detailsWrapper}>
        {error && <Alert severity="error">{error}</Alert>}

        <div className={classes.form}>
          <div className="flex-row">
            <InputField name='your_ref' validation={validation.your_ref} value={form.your_ref} handleChange={handleInputChange} hoverLabel={true} />
            <div className="flex"></div>
          </div>
          <div className="flex-row">
            <InputField name='first_name' validation={validation.first_name} value={form.first_name} handleChange={handleInputChange} hoverLabel={true} />
            <InputField name='last_name' validation={validation.last_name} value={form.last_name} handleChange={handleInputChange} hoverLabel={true} />
          </div>
          <InputField name='email' validation={validation.email} value={form.email} handleChange={handleInputChange} hoverLabel={true} />
          <div className="flex-row">
            <InputField name='phone' validation={validation.phone} value={form.phone} handleChange={handleInputChange} hoverLabel={true} />
            <div className="flex"></div>
          </div>
          <InputField name='boat_name' validation={validation.boat_name} value={form.boat_name} handleChange={handleInputChange} hoverLabel={true} />
          <Divider style={{marginTop: 20, marginBottom: 20}} />
          <div className="flex-row">
            <InputField type='select' name='id_discount_group' validation={validation.id_discount_group} value={form.id_discount_group} handleChange={handleInputChange} hoverLabel={true} items={discountGroups} />
          </div>
          <div className="flex-row">
            <InputField type='select' name='id_customer_account_type' validation={validation.id_customer_account_type} value={form.id_customer_account_type} handleChange={handleInputChange} hoverLabel={true} items={accountTypes} />
            {form.id_customer_account_type === 2 &&
              <InputField type='select' name='id_customer_payment_frequency' validation={validation.id_customer_payment_frequency} value={form.id_customer_payment_frequency} handleChange={handleInputChange} hoverLabel={true} items={paymentFrequencies} />
            }
          </div>
          <div className="flex-row">
            <InputField name='credit' validation={validation.credit} value={form.credit} handleChange={handleInputChange} hoverLabel={true} />
            <div className="flex"></div>
          </div>
        </div>

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Create</ProgressButton>
        </div>
      </div>

  );
}

export default CustomerAdd;
