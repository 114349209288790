import React, { useState, useContext } from 'react';
import {makeStyles, useTheme, Chip} from '@material-ui/core';
import clsx from 'clsx';
import globalContext from '../context/globalContext';

const useStyles = makeStyles(theme => ({
    chip: {
        height: 24,
        paddingRight: 10,
        paddingLeft: 10,
        width: 100
    },
    vacant: {
        backgroundColor: theme.palette.vacant.main,
        color: theme.palette.vacant.contrastText
    },
    assigned: {
        backgroundColor: theme.palette.assigned.main,
        color: theme.palette.assigned.contrastText
    },
    reserved: {
        backgroundColor: theme.palette.reserved.main,
        color: theme.palette.reserved.contrastText
    },
    fault: {
        backgroundColor: theme.palette.fault.main,
        color: theme.palette.fault.contrastText
    },
    offline: {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.grey[400].contrastText
    }
}))

const StatusChip = props => {

    const theme = useTheme();
    const classes = useStyles(theme);


    //   THIS IS A TEMP SOLUTION UNTIL AND API IS POWERING THE UTILITIES STATUSES
    //   CONTEXT SHOULD NOT BE ACCESSABLE FROM A DUMB COMPONENT
    const context = useContext(globalContext);
    const getServiceStatus = () => {
        if(typeof props.id !== 'undefined'){
            const service = context.services.data.find(s => parseInt(s.id_utility) === parseInt(props.id) && s.end_time === null);
            if(typeof service !== 'undefined')
                return clsx(classes.chip, classes.assigned)
        }

        return getClass();
    }

    const getServiceLabel = () => {
        if(typeof props.id !== 'undefined'){
            const service = context.services.data.find(s => parseInt(s.id_utility) === parseInt(props.id) && s.end_time === null);
            if(typeof service !== 'undefined')
                return 'Assigned'
        }

        return getLabel();
    }
    //   END OF TEMP SOLUTION REPLACE THE CHIP CLASSNAME WITH getClass()

    const getClass = () => {
        switch(props.status.toUpperCase()){
            case 'FAULT':
                return clsx(classes.chip, classes.fault);
            case 'ASSIGNED':
                return clsx(classes.chip, classes.assigned);
            case 'OFFLINE':
                return clsx(classes.chip, classes.offline);
            case 'RESERVED':
                return clsx(classes.chip, classes.reserved);
            default:
                return clsx(classes.chip, classes.vacant);
        }
    }

    const getLabel = () => {
        switch(props.status.toUpperCase()){
            case 'FAULT':
                return 'Fault';
            case 'ASSIGNED':
                return 'Assigned';
            case 'OFFLINE':
                return 'Offline';
            default:
                return 'Vacant';
        }
    }

    return (
        <Chip label={getLabel()} className={getClass()} />
    );
}

export default StatusChip;
