import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import globalContext from '../context/globalContext';
import ProgressButton from '../components/progressButton';
import Alert from '@material-ui/lab/Alert';
import API from '../global/api';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const ProfileContactDelete = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext)

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const handleRemove = async () => {

        try {
            setLoading(true);
            setError(null);

            //
            // Execute an api request and get the returned result
            //
            API.req(`/organisation/${context.profile.data.id_organisation}/contact`, 'DELETE', {id_organisation_contact: props.id_organisation_contact}).then(
                async res => {

                    if(typeof props.handleSuccess === 'function')
                        props.handleSuccess(res.result);

                    let newProfile = JSON.parse(JSON.stringify(context.profile.data));
                    const idx = newProfile[props.type].findIndex(c => c.id_organisation_contact === props.id_organisation_contact);
                    if(idx > -1)
                        newProfile[props.type].splice(idx, 1);;
                    context.profileUpdate(newProfile);
                    
                    context.showSnack({message: 'Contact removed!'}, 'success')

                },
                err => {
                    setError(typeof err === 'string' ? err : JSON.stringify(err))
                }
            )
        
        } catch (err) {
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        } finally {
            setLoading(false);
        }


    }

    const handleClose = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="remove-rfid" open={true} maxWidth="xs">
            <DialogTitle id="remove-rfid-title">Delete contact</DialogTitle>
            <DialogContent>
                {error && <Alert severity="error">{error}</Alert>}
                
                <Typography variant="body2">Are you sure you want to delete this contact?</Typography>
                
                <div className="flex-row space-between mt">
                    <Button onClick={handleClose}>Cancel</Button>
                    <ProgressButton waiting={loading} color="secondary" variant="contained" onClick={handleRemove}>Delete</ProgressButton>
                </div>
            </DialogContent>
        </Dialog>
    )
}
export default ProfileContactDelete;