import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, Collapse, List, ListItem, ListItemText} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import {localeCurrency, keyValueArray} from '../global/helpers';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';

const default_utility = {
  id_utility: 0,
  id_pedestal: 0,
  pedestal_reference: '',
  utility_type: 'Electricity',
  network_status: 'Online',
  utility_description: '',
  plug: 'Out',
  breaker: 'Healthy',
  id_tariff: 0,
  service_status: 'Vacant'
}

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  utilityImage: {
    width: 300,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
}));

const UtilityAdd = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const [ serviceTypes, setServiceTypes ] = useState([]);
  const [ tariffs, setTariffs ] = useState([]);
  const [ pedestals, setPedestals ] = useState([]);

  const [ descriptions, setDescriptions ] = useState([]);

  const childMode = typeof props.id_pedestal !== 'undefined' ? true : false;

  const [ form, setForm ] = useState({
    id_pedestal: props.id_pedestal || '',
    id_service_type: 1,
    utility_description: '',
    id_tariff: ''
  });

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'id_pedestal',
      display_name: 'Pedestal',
      required: true
    },
    {
      name: 'id_service_type',
      display_name: 'Utility Type',
      required: true
    },
    {
      name: 'utility_description',
      display_name: 'Description',
      required: false
    },
    {
      name: 'id_tariff',
      display_name: 'Tariff',
      required: true
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSetDescription = str => {
    let newForm = {...form};
    newForm.utility_description = str;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    setError(null);
    setLoading(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){


      //
      // Execute an api request and get the returned result
      //
      API.req(`/utility/${context.profile.data.id_organisation}`, 'POST', form).then(
        async res => {
          setLoading(false);

          await API.addToLocalArray('utilities', res.result);
          context.utilityAdd(res.result);

          if(typeof props.handleClose === 'function' && childMode)
            props.handleClose();

          context.showSnack({message: 'Utility created!'}, 'success')

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )

    }
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  useEffect(() => {
    let tariffs = [];
    context.tariffs.data.filter(t => t.deleted === null).forEach(t => {
      tariffs.push({
        key: t.id_tariff,
        value: `${t.tariff_name} ${localeCurrency(parseFloat(t.rate), t.currency)} / ${t.unit}`
      })
    })
    setTariffs(tariffs);
  }, [context.tariffs])

  useEffect(() => {
    let pedestals = [];
    context.pedestals.data.forEach(p => {
      pedestals.push({
        key: p.id_pedestal,
        value: p.pedestal_reference
      })
    })
    setPedestals(pedestals);
  }, [context.pedestals])

  useEffect(() => {
    
    if(typeof context.meta.data !== 'undefined' && typeof context.meta.data.service_types !== 'undefined')
      setServiceTypes(keyValueArray(context.meta.data.service_types, 'id_service_type', 'service_type'));

  }, [context.meta])

  useEffect(() => {
    
    let newDescriptions = [];
    context.utilities.data.map(u => {
      if(newDescriptions.indexOf(u.utility_description) === -1)
        newDescriptions.push(u.utility_description);
    })
    setDescriptions(newDescriptions);

  }, [context.utilities])

  return (
      <div className={classes.detailsWrapper}>
        {error && <Alert severity="error">{error}</Alert>}

        <div className={classes.form}>

          {!childMode && 
            <div className="flex-row">
              <InputField name='id_pedestal' validation={validation.id_pedestal} value={form.id_pedestal} handleChange={handleInputChange} hoverLabel={true} type="select" items={pedestals} />
            </div>
          }

          <div className="flex-row">
            <InputField name='id_service_type' validation={validation.id_service_type} value={form.id_service_type} handleChange={handleInputChange} hoverLabel={true} type="select" items={serviceTypes} />
          </div>

          <div className="flex-row">
            <InputField name='utility_description' validation={validation.utility_description} value={form.utility_description} handleChange={handleInputChange} hoverLabel={true} />
          </div>

          
          <Collapse in={form.utility_description.length === 0} style={{maxHeight: 150, overflowY: 'scroll'}}>
            <List dense={true}>
                {descriptions.map((d, idx) => (
                    <ListItem button key={idx} onClick={() => handleSetDescription(d)}><ListItemText primary={d} /></ListItem>
                ))}
            </List>
          </Collapse>

          <div className="flex-row">
            <InputField name='id_tariff' validation={validation.id_tariff} value={form.id_tariff} handleChange={handleInputChange} hoverLabel={true} type="select" items={tariffs} />
          </div>
        </div>

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Add</ProgressButton>
        </div>

      </div>
  );
}

export default UtilityAdd;
