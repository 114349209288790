import React, { useState, useEffect, useContext } from 'react';
import {useTheme, makeStyles, Button, Typography} from '@material-ui/core';
import { makeValidation, validateForm, isValidForm} from '../global/formHelper';
import InputField from '../components/inputField';
import PedestalSnippet from '../components/pedestalSnippet';
import globalContext from '../context/globalContext';

const useStyles = makeStyles((theme) => ({
  pedestals: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 15
  }
}));

const BerthManagePedestals = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ pedestals, setPedestals ] = useState([]);

  const [ form, setForm ] = useState({
    'add_pedestal': ''
  });

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'add_pedestal',
      display_name: 'Add Pedestal',
      required: true
    }
  ]));

  const getAvailablePedestals = () => {
    let peds = [];
    context.pedestals.data.forEach(p => {
      if(props.data.indexOf(p.id_pedestal) === -1)
        peds.push({
          key: p.id_pedestal,
          value: p.pedestal_reference
        })
    })
    return peds;
  }

  const handleAddPedestal = () => {
    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid && typeof props.handleAddPedestal === 'function')
      props.handleAddPedestal(form.add_pedestal);
  }
  
  const handleInputChange = e => {
    let newForm = JSON.parse(JSON.stringify(form));
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleRemovePedestal = (id) => {
    if(typeof props.handleRemovePedestal === 'function')
      props.handleRemovePedestal(id);
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])


  useEffect(() => {
    let peds = [];
    props.data.forEach(bp => {
      if(props.removing.indexOf(bp.id_pedestal) === -1){
        const p = context.pedestals.data.find(p => parseInt(p.id_pedestal) === parseInt(bp.id_pedestal));
        if(typeof p !== 'undefined')
          peds.push(p);
      }
    })

    props.adding.forEach(id_pedestal => {
      const p = context.pedestals.data.find(p => parseInt(p.id_pedestal) === parseInt(id_pedestal));
      if(typeof p !== 'undefined')
        peds.push(p);
    })
    
    setPedestals(peds);
  }, [props.data, props.adding, props.removing])


  return (
    <div className={classes.pedestals}>
      <Typography style={{marginBottom: 10}}>Available pedestals:</Typography>
      
      <div className="flex-row align-center">
        <InputField name='add_pedestal' validation={validation.add_pedestal} value={form.add_pedestal} handleChange={handleInputChange} hoverLabel={true} type="select" items={getAvailablePedestals()}/>
        <Button variant="contained" onClick={handleAddPedestal}>Add</Button>
      </div>

      <div className={classes.list}>
        {pedestals.map((p, idx) => (
          <PedestalSnippet pedestal={p} key={idx} handleRemove={() => handleRemovePedestal(p.id_pedestal)} />
        ))}
      </div>
    </div>
  );
}

export default BerthManagePedestals;
