import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, Link, Button, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
    message: {
        position: 'relative',
    },
    table: {
        tableLayout: 'fixed',
        width: '100%'
    },
    cellKey: {
        verticalAlign: 'top',
        borderBottom: '1px solid #cc0000',
        padding: '4px 6px 4px 0px',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        overflow: 'hidden',
        width: '100px'
    },
    cellValue: {
        verticalAlign: 'top',
        borderBottom: '1px solid #cc0000',
        padding: '2px 6px 2px 0px',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        overflow: 'hidden'
    },
    pre: {
        wordWrap: 'break-word',
        whiteSpace: 'break-spaces'
    }
}));

const HelpfulAlert = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [showMore, setShowMore] = useState(false);

    const severity = props.severity || 'ERROR';

    const getTitle = () => {
        switch(severity.toUpperCase()){
            case 'ERROR':
                if(typeof props.message.code !== 'undefined')
                    return `${props.message.code} ${(typeof props.message.reference !== 'undefined' && props.message.reference !== null) ? ' - reference: ' + props.message.reference.toUpperCase() : ''}`;
                
                if(typeof props.message.msg !== 'undefined')
                    return typeof props.message.msg === 'string' ? props.message.msg : JSON.stringify(props.message.msg);

                return typeof props.message === 'string' ? props.message : JSON.stringify(props.message);
                break;
            case 'SUCCESS':
            case 'WARNING':
            case 'INFO':
                return typeof props.message === 'string' ? props.message : JSON.stringify(props.message);
                break;
        }
    }

    const getMessage = () => {
        switch(severity.toUpperCase()){
            case 'ERROR':
                if(typeof props.message.code !== 'undefined' && typeof props.message.msg !== 'undefined')
                    return typeof props.message.msg === 'string' ? props.message.msg : createErrorTable(props.message.msg);
                return null;
                break;
            case 'SUCCESS':
            case 'WARNING':
            case 'INFO':
                return null;
                break;
        }
    }

    const createErrorTable = (msg) => {
        return (
            <Table size="small" padding="normal" classes={{root: classes.table}}>
                <TableBody>
                    {Object.keys(msg).map((key, idx) => (
                        <TableRow key={idx}>
                            <TableCell component="th" scope="row" classes={{root: classes.cellKey}}>{key}:</TableCell>
                            <TableCell align="left" classes={{root: classes.cellValue}}>{typeof msg[key] === 'string' ? msg[key] : <pre className={classes.pre}>{JSON.stringify(msg[key], null, " ")}</pre>}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    const hasMore = () => {
        return (typeof props.message.code !== 'undefined' && typeof props.message.msg !== 'undefined' && props.message.msg !== null);
    }

    return (
        <React.Fragment>
            <Alert 
                severity={severity} 
                style={{marginBottom: 20}}
                action={(!showMore && hasMore()) ? <Button onClick={() => setShowMore(true)}>More&hellip;</Button> : ''}
                classes={{
                    message: classes.message
                }}
            >
                <AlertTitle>{getTitle()}</AlertTitle>
                {(showMore && hasMore()) && getMessage()}
            </Alert>
        </React.Fragment>
    )
}
export default HelpfulAlert;