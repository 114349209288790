import { makeStyles, Typography, useTheme, Button, IconButton } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import InputField from '../components/inputField';
import globalContext from '../context/globalContext';
import { makeValidation } from '../global/formHelper';
import Images from '../assets/imgs/';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        padding: 20,
        backgroundColor: theme.palette.grey[100],
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 3
    },
    berthButton: {
        backgroundColor: '#ffffff',
        fontSize: '1rem',
        fontWeight: 400
    },
    buttonBoat: {
        width: 38,
        height: 38
    }
}))

const ServiceSelectBerth = props => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);


    const [allBerths, setAllBerths] = useState([]);
    const [availableBerths, setAvailableBerths] = useState([]);
    const [selectFromAll, setSelectFromAll] = useState(false);
    const [berth, setBerth] = useState(null);

    const [validation, setValidation] = useState(makeValidation([{
        name: 'berth',
        display_name: 'All berths',
        required: false
    }]))

    const isOccupied = (id_berth) => {
        return context.occupancies.data.findIndex(o => parseInt(o.id_berth) === parseInt(id_berth) && o.end_time === null) > -1
    }

    const handleSelectChange = e => {
        const val = typeof e.target !== 'undefined' ? e.target.value : e;
        setBerth(val);
        if(typeof props.handleSelect === 'function'){
            const selectedBerth = context.berths.data.find(b => b.id_berth === val);
            if(typeof selectedBerth !== 'undefined')
                props.handleSelect(selectedBerth);
        }
    }

    const handleClose = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }

    useEffect(() => {
        const loadBerths = () => {
            let newBerths = [];
            let newAvailableBerths = [];
            context.berths.data.forEach(b => {
                newBerths.push({
                    key: b.id_berth,
                    value: b.berth_reference
                })

                if(typeof props.pedestal !== 'undefined' && props.pedestal !== null){
                    const avbs = context.berthPedestals.data.filter(bp => parseInt(bp.id_pedestal) === parseInt(props.pedestal.id_pedestal));
                    if(avbs.findIndex(avb => parseInt(avb.id_berth) === parseInt(b.id_berth)) > -1)
                        newAvailableBerths.push({
                            key: b.id_berth,
                            value: b.berth_reference
                        })
                }
            });
            setAllBerths(newBerths);
            setAvailableBerths(newAvailableBerths);
            if(newAvailableBerths.length === 0)
                setSelectFromAll(true);
            else
                setSelectFromAll(false);
        }
        loadBerths();
    }, [props.pedestal])
    
    return (
        <div className={classes.wrapper}>
            <div className="flex-row">
                <Typography variant="subtitle1">Select a berth</Typography>
                <IconButton size="small" onClick={handleClose}><CloseIcon style={{fontSize: 18}} /></IconButton>
            </div>
            <div className="flex-row-start" style={{paddingTop: 10, paddingBottom: 5}}>
                {availableBerths.map((avb, idx) => (
                    <Button key={idx} onClick={() => handleSelectChange(avb.key)} variant="outlined" className={classes.berthButton}>
                        {avb.value}<img src={isOccupied(avb.key) ? Images.Icons_Marina_Icon : Images.Icons_Marina_Grey_Icon} className={classes.buttonBoat} alt="Berth" />
                    </Button>
                ))}
            </div>
            {!selectFromAll &&<div className="flex-row-end"><Button onClick={() => setSelectFromAll(true)} style={{textDecoration: 'underline'}}>Show all</Button></div>}
            {selectFromAll &&
                <div style={{marginTop: 20}}>
                    <InputField 
                        name='berth'  
                        validation={validation.berth}
                        value={berth}
                        handleChange={handleSelectChange} 
                        hoverLabel={true} 
                        placeholder='Select berth'
                        type="select"
                        items={allBerths}
                    />
                </div>
            }
        </div>
    )

}

export default ServiceSelectBerth;