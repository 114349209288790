import React, { useContext, useState, useEffect } from 'react';
import {makeStyles, useTheme, Button} from '@material-ui/core';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import globalContext from '../context/globalContext';
import SideSlidePanel from '../components/sideSlidePanel';
import TariffAdd from '../content/tariffAdd';
import TariffEdit from '../content/tariffEdit';
import API from '../global/api';
import { SQLtoUTCDate } from '../global/dateTimeHelpers';
import UserInviteAdd from '../content/userInviteAdd';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const UserInvites = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ data, setData ] = useState([]);
  const [ showAdd, setShowAdd ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);

  const headCells = ['invite_name', 'invite_email', 'organisation_name', 'username', 'sent_date'];


  useEffect(() => {

    const loadInvitations = () => {

      setLoading(true);
      setError(null);

      API.req(`/user/invites`, 'GET').then(
        async res => {

          if(res.success === true){
            setLoading(false);
            setError(null);
            setData(res.result);
          } else {
            setLoading(false);
            setError(res.msg);
            setData([]);
          }

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
            setData([]);
        }
      )

    }
    loadInvitations();

  }, [context.profile])


  const handleSuccess = (invite) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData.push(invite);
    setData(newData);
    setShowAdd(false);
  }


  return (
    <div className={classes.container}>

      <PageToolbar label="User Invites">
        <div style={{flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20}}>
          <div>
            <Button variant="contained" color="default" style={{alignSelf: 'flex-start'}} onClick={() => setShowAdd(true)}>Add New</Button>
          </div>
          <div></div>
        </div>
      </PageToolbar>

      <div style={{width: '100%', maxWidth: 1200, marginTop: 10}}>
        <AutoTable 
        loading={loading}
        rows={data} 
        orderBy="id_invite" 
        idCol="id_invite"
        headCells={headCells} 
        cellReplacementProps={{
          'sent_date': (val, id) => SQLtoUTCDate(val)
        }} />
      </div>

      <SideSlidePanel title="Invite Back Office User" side="left" show={showAdd} handleClose={() => {setShowAdd(false)}}><UserInviteAdd handleClose={() => {setShowAdd(false)}} handleSuccess={handleSuccess} /></SideSlidePanel>

    </div>
  );
}

export default UserInvites;
