import React, { useContext } from 'react';
import {makeStyles, useTheme, Typography} from '@material-ui/core';
import Images from '../assets/imgs/index.js';
import StatusChip from './statusChip';
import globalContext from '../context/globalContext.js';
import { fixedLengthNumber, localeCurrency } from '../global/helpers';
import { SQLtoUTCDate } from '../global/dateTimeHelpers.js';

const useStyles = makeStyles(theme => ({
  utility: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '6px'
  },
  pedestalUtilityName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  utilityIcon: {
    height: 32,
    width: 'auto',
    marginRight: 10
  },
  reservation: {
    // backgroundColor: theme.palette.reserved.main, 
    // padding: '3px 8px',
    // borderRadius: 3,
    color: theme.palette.grey[900]
  }
}))

const UtilityOverview = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);


  const getTariff = () => {
    const tariff = context.tariffs.data.find(t => parseInt(t.id_tariff) === parseInt(props.utility.id_tariff));
    return typeof tariff !== 'undefined' ? `${tariff.tariff_name} - (${localeCurrency(parseFloat(tariff.rate), tariff.currency)})` : ''
  }


  const getPedestal = () => {
    const pedestal = context.pedestals.data.find(p => parseInt(p.id_pedestal) === parseInt(props.utility.id_pedestal));
    return typeof pedestal !== 'undefined' ? pedestal.pedestal_reference : ''
  }

  const getReservation = u => {
    const findScheduledServices = context.services.data.filter(s => s.id_utility === u.id_utility && new Date(s.start_time) > new Date());
    return findScheduledServices.length > 0 ? findScheduledServices[0].start_time : null
  }
  const reservation = getReservation(props.utility);

  const getService = u => {
    const findService = context.services.data.find(s => parseInt(s.id_utility) === parseInt(u.id_utility) && s.end_time === null && s.id_session !== null);
    return findService;
  }
  const service = getService(props.utility);
  

  const getStatus = () => {

    let state = ''
    if(props.utility.network_status === 'online' && props.utility.breakerOk !== false)
      state = 'Healthy';
    else
      state = 'Fault';

    if(props.utility.service_status === 'assigned')
      state = state + ' / Assigned'

    if(props.utility.service_status === 'vacant')
      state = state + ' / Vacant'

    if(props.utility.breakerOk === false)
      state = state + ' / Breaker Tripped';
    
    return state;

  }


  return (
    <div className={classes.utility}>
      <div className={classes.pedestalUtilityName}>
        <div className={classes.utilityIcon}><img src={props.utility.utility_type === 'Water' ? Images.Icons_Water_Icon : Images.Icons_Electric_Icon} height="32" width="auto" alt={props.utility.utility_type}/></div>
          <Typography style={{marginRight: 20}}><b>{props.utility.identity !== null ? props.utility.identity : '#' + fixedLengthNumber(props.utility.id_utility,4)}</b></Typography>
      </div>
      <div style={{marginBottom: 10}}>
        <StatusChip status={props.utility.service_status} id={props.utility.id_utility} />
      </div>
      {reservation && <Typography variant="caption" className={classes.reservation}>Assigned from: <b>{SQLtoUTCDate(reservation)}</b></Typography>}
      <Typography>Pedestal: <b>{getPedestal()}</b></Typography>
      <Typography>Utility ID: <b>{'#' + fixedLengthNumber(props.utility.id_utility,4)}</b></Typography>
      <Typography>Utility Type: <b>{props.utility.utility_type.toUpperCase() === 'WATER' ? 'Water' : 'Electricity'}</b></Typography>
      <Typography style={{marginTop: 20}}>Status: <b>{getStatus()}</b></Typography>
      <Typography>Type: <b>{props.utility.utility_description}</b></Typography>
      {(props.utility.utility_type.toUpperCase() !== 'WATER' && props.utility.connected !== null) && <Typography>Plugged in: <b>{props.utility.connected ? 'Yes' : 'No'}</b></Typography>}
      <Typography color={props.utility.fault !== null ? 'error' : 'default'}>Fault: <b>{props.utility.fault}</b></Typography>
      <Typography>Tariff: <b>{getTariff()}</b></Typography>
    </div>
  );
}

export default UtilityOverview;