import Api from '../global/api';  
const serviceReducer = (state = {
    status: 'LOADING',
    msg: '',
    data: []
}, action) => {
    let current_data = JSON.parse(JSON.stringify(state.data));
    const status = typeof action.status !== 'undefined' ? action.status.toUpperCase(): null;
    const idx = status === 'OK' ? current_data.findIndex(c => c.id_service === action.payload.id_service) : -1;

    switch(action.type){
        case "SERVICES_LOAD":
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: status === 'OK'?action.payload:current_data
            }
            break;
        case "SERVICE_ADD":
            if(status === 'OK' && idx > -1)
                current_data[idx] = action.payload;
            else 
                current_data.push(action.payload);

            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: current_data
            }
            break;
        case "SERVICE_UPDATE":
            if(status === 'OK' && idx > -1)
                current_data[idx] = action.payload;
            else 
                current_data.push(action.payload);
                
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: current_data
            }
            Api.setLocal('services', current_data);
            break;
        default:
    }
    return state;
}

export default serviceReducer;