import React, { useContext, useState } from 'react';
import {makeStyles, useTheme, Button} from '@material-ui/core';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import globalContext from '../context/globalContext';
import SideSlidePanel from '../components/sideSlidePanel';
import TariffAdd from '../content/tariffAdd';
import TariffEdit from '../content/tariffEdit';
import { localeCurrency } from '../global/helpers';
import { SQLtoUTCDate } from '../global/dateTimeHelpers';
import DiscountGroupAdd from '../content/discountGroupAdd';
import DiscountGroupEdit from '../content/discountGroupEdit';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const Tariffs = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ showAdd, setShowAdd ] = useState(false);
  const [ edit, setEdit ] = useState(null);

  const [ showAddDiscountGroup, setShowAddDiscountGroup ] = useState(false);
  const [ editDiscountGroup, setEditDiscountGroup ] = useState(null);

  const headCells = ['tariff_name', 'service_type', 'tariff_description', 'rate', 'currency', 'unit', 'last_change'];
  const headCellsDiscountGroups = ['discount_name', 'discount_rate'];

  const handleEdit = row => {
    setEdit(row);
  }
  
  const handleEditDiscountGroup = row => {
    setEditDiscountGroup(row);
  }

  const getCurrency = (id) => {
    const record = context.tariffs.data.find(t => t.id_tariff === id);
    if(typeof record !== 'undefined')
      return record.currency;
    else
      return context.profile.data.currency;
  }

  return (
    <div className={classes.container}>

      <PageToolbar label="Tariffs">
        <div style={{flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20}}>
          <div>
            <Button variant="contained" color="default" style={{alignSelf: 'flex-start'}} onClick={() => setShowAdd(true)}>Add New</Button>
          </div>
          <div></div>
        </div>
      </PageToolbar>

      <div style={{width: '100%', maxWidth: 1200, marginTop: 10, marginBottom: 40}}>
        <AutoTable 
        rows={context.tariffs.data.filter(t => t.deleted === null)} 
        orderBy="tariff_name" 
        idCol="id_tariff"
        headCells={headCells} 
        handleRowClick={handleEdit}
        cellReplacementProps={{
          'rate': (val, id) => localeCurrency(val, getCurrency(id)),
          'last_change': (val, id) => (
            SQLtoUTCDate(val)
          ),
        }} />
      </div>

      <PageToolbar label="Discount Groups">
        <div style={{flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20}}>
          <div>
            <Button variant="contained" color="default" style={{alignSelf: 'flex-start'}} onClick={() => setShowAddDiscountGroup(true)}>Add New</Button>
          </div>
          <div></div>
        </div>
      </PageToolbar>

      <div style={{width: '100%', maxWidth: 600, marginTop: 10}}>
        <AutoTable 
        rows={context.discountGroups.data.filter(g => g.deleted === null)} 
        orderBy="discount_name" 
        idCol="id_discount_group"
        headCells={headCellsDiscountGroups} 
        handleRowClick={handleEditDiscountGroup}
        cellReplacementProps={{
          'discount_rate': (val, id) => `${parseFloat(val*100)}%`,
        }} />
      </div>

      <SideSlidePanel title="Add Tariff" side="left" show={showAdd} handleClose={() => {setShowAdd(false)}}><TariffAdd handleClose={() => {setShowAdd(false)}} /></SideSlidePanel>
      <SideSlidePanel title="Edit Tariff" side="right" show={edit !== null} handleClose={() => {setEdit(null)}}><TariffEdit handleClose={() => {setEdit(null)}} data={edit} /></SideSlidePanel>

      <SideSlidePanel title="Add Discount Group" side="left" show={showAddDiscountGroup} handleClose={() => {setShowAddDiscountGroup(false)}}><DiscountGroupAdd handleClose={() => {setShowAddDiscountGroup(false)}} /></SideSlidePanel>
      <SideSlidePanel title="Edit Discount Group" side="right" show={editDiscountGroup !== null} handleClose={() => {setEditDiscountGroup(null)}}><DiscountGroupEdit handleClose={() => {setEditDiscountGroup(null)}} form={editDiscountGroup} /></SideSlidePanel>
    </div>
  );
}

export default Tariffs;
