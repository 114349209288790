import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import globalContext from '../context/globalContext';
import ProgressButton from '../components/progressButton';
import Alert from '@material-ui/lab/Alert';
import API from '../global/api';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const CustomerRfidDelete = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext)

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const handleRemove = async () => {

        try {
            setLoading(true);
            setError(null);

            //
            // Execute an api request and get the returned result
            //
            API.req(`/customer/rfid/${context.profile.data.id_organisation}`, 'DELETE', {id_customer_rfid: props.id_customer_rfid}).then(
                async res => {

                    if(typeof props.handleSuccess === 'function')
                        props.handleSuccess(res.result);

                    context.showSnack({message: 'RFID removed!'}, 'success')

                },
                err => {
                    setError(typeof err === 'string' ? err : JSON.stringify(err))
                }
            )
        
        } catch (err) {
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        } finally {
            setLoading(false);
        }


    }

    const handleClose = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="remove-rfid" open={true} maxWidth="xs">
            <DialogTitle id="remove-rfid-title">Remove RFID</DialogTitle>
            <DialogContent>
                {error && <Alert severity="error">{error}</Alert>}
                
                <Typography variant="body2">Removing an RFID card will make it immediately unusable and cannot be reversed.</Typography>
                
                <div className="flex-row space-between mt">
                    <Button onClick={handleClose}>Cancel</Button>
                    <ProgressButton waiting={loading} color="secondary" variant="contained" onClick={handleRemove}>Remove</ProgressButton>
                </div>
            </DialogContent>
        </Dialog>
    )
}
export default CustomerRfidDelete;