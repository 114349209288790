import React, { useEffect, useState } from 'react';
import { useTheme, TableHead, TableRow, TableCell, TableSortLabel, Typography, Chip, Button, TableContainer, Table, TableBody, TablePagination, Paper, makeStyles, CircularProgress } from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import { multiSort, cleanHeader} from '../global/helpers';

const useStyles = makeStyles((theme) => ({
    white: {
        color: '#fff',
        '&$active': {
            color: 'white',
        },
    },
    tableWrapper: {
        marginTop: 20
    },
    nothingWrapper: {
        display: 'flex', 
        height: '100%', 
        minHeight: 60,
        width: '100%', 
        justifyContent: 'center', 
        alignItems: 'center',
        backgroundColor: theme.palette.grey[200]
    }
}))


const EnhancedTableHead = props => {

    const theme = useTheme();
    const classes = useStyles(theme);

    const { orderBy, onRequestSort, headers } = props;
    const createSortHandler = property => event => {
    onRequestSort(event, property);
    };

    const getOrderBy = property => {
        const idx = orderBy.findIndex(o => o.orderBy === property);
        if(idx > -1)
            return orderBy[idx].order;
        
        return false;
    }

    return (
    <TableHead>
        <TableRow>
        {headers.map(header => (
            <TableCell
            key={header}
            align={'left'}
            sortDirection={getOrderBy(header)}
            >
            <TableSortLabel
                active={getOrderBy(header) !== false}
                direction={getOrderBy(header) || 'asc'}
                onClick={createSortHandler(header)}
                classes={{
                    active: classes.white
                }}
            >
                {cleanHeader(header)}
            </TableSortLabel>
            </TableCell>
        ))}
        </TableRow>
    </TableHead>
    );
}
  
  
const OrderChips = props => {

    const { orderBy, handleDelete, handleDeleteAll } = props;

    return (
        <div style={{paddingBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Typography variant="body2">Sort: </Typography>
        {orderBy.map(o => (
            <Chip
                key={o.orderBy}
                size="small"
                icon={o.order === 'desc' ? <ArrowDownIcon /> : <ArrowUpIcon />}
                label={cleanHeader(o.orderBy)}
                style={{marginLeft: 5, marginRight: 5}}
                onDelete={() => handleDelete(o.orderBy)}
            />
        ))}
        {orderBy.length > 1 && <Button color="secondary" onClick={handleDeleteAll}>Clear</Button>}
        </div>
    );
}


const AutoTable = props => {

    const theme = useTheme();
    const classes = useStyles(theme);
    
    const loading = props.loading || false;

    const [rows, setRows] = useState(props.rows || []);
    const [headCells, setHeadCells] = useState(typeof props.headCells !== 'undefined' ? props.headCells : props.rows.length > 0 ? Object.keys(props.rows[0]) : []);

    const [display, setDisplay] = useState([...headCells]);
    const [table, setTable] = useState({
        page: 0,
        rowsPerPage: 25,
        order: 'desc',
        orderBy: [
            {
                order: 'desc',
                orderBy: props.orderBy
            }
        ],
        idCol: props.idCol || null
    });

    const isClickable = typeof props.handleRowClick === 'function' ? true : false;

    const handleChangePage = (event, newPage) => {
        setTable({
            ...table,
            page: newPage
        })
    };

    const handleChangeRowsPerPage = event => {
        setTable({
            ...table,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        });
    };

    const handleRequestSort = (event, property) => {

        var newOrder = JSON.parse(JSON.stringify(table.orderBy));

        const idx = newOrder.findIndex(o => o.orderBy === property);
        if(idx === -1){
            newOrder.push({
                orderBy: property,
                order: 'desc'
            })
        } else {
            const isAsc = newOrder[idx].order === 'asc';
            if(isAsc){
                // Remove from order
                newOrder[idx].order = 'desc'
            } else {
                newOrder[idx].order = 'asc'
            }
        }
        
        setTable({
            ...table,
            orderBy: newOrder
        });
    };

    const handleDeleteSort = (property) => {

        var newOrder = JSON.parse(JSON.stringify(table.orderBy));

        const idx = newOrder.findIndex(o => o.orderBy === property);
        if(idx > -1){
            newOrder.splice(idx, 1);
        }
        
        setTable({
            ...table,
            orderBy: newOrder
        });

    };

    const handleDeleteAllSort = (property) => {

        setTable({
            ...table,
            orderBy: []
        });

    };

    // useEffect(() => saveDisplayColumns(display), [display]);

    // const saveDisplayColumns = (display) => {
    //     let name = 'ord_col';
    //     localStorage.setItem(name, JSON.stringify(display));
    // }

    useEffect(() => {
        setRows(props.rows);
        if(typeof props.rows[0] !== 'undefined' && headCells.length === 0)
            setHeadCells(Object.keys(props.rows[0]));
    }, [props.rows])

    useEffect(() => {
        if(display.length === 0)
            setDisplay([...headCells]);
    }, [headCells])


    const sanitizeValue = (val, col) => {

        switch(col){
            case 'id_order':
                return 'VE'+val;
            case 'order_value':
            case 'outstanding_balance':
                return val != null?'£'+val.toLocaleString('en-GB'):'';
            case 'teckpro_order':
                return val === 1?'YES':'NO';
            default:
                return val; 
        }

    }

    const handleRowClick = row => {
        if(typeof props.handleRowClick === 'function')
            props.handleRowClick(row);
    }


    const cellReplacementProp = (row, col, idx) => {

        const id = table.idCol !== null ? row[table.idCol] : idx;

        if(typeof props.cellReplacementProps !== 'object')
            return sanitizeValue(row[col], col);
        
        const cellProp = props.cellReplacementProps[col];
        if(typeof cellProp === 'undefined')
            return sanitizeValue(row[col], col);
        else
            return typeof cellProp === 'function'? cellProp(row[col], id) : cellProp;

    }

    
    return (
        <div className={classes.tableWrapper}>
            {(table.orderBy.length > 0 && rows.length > 0) && <OrderChips orderBy={table.orderBy} handleDelete={handleDeleteSort} handleDeleteAll={handleDeleteAllSort}/>}

            <Paper elevation={0}>
                {headCells.length > 0 && 
                <TableContainer>
                    <Table>
                        <EnhancedTableHead
                            headers={display}
                            order={table.order}
                            orderBy={table.orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                        {multiSort(rows, table.orderBy).slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage).map((row, idx) => (
                            <TableRow hover={isClickable} key={idx} style={isClickable ? {cursor: 'pointer'} : {}} onClick={() => handleRowClick(row)}>
                                {display.map((x, cidx) => (
                                    <TableCell key={cidx}>{cellReplacementProp(row, x, idx)}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
                {rows.length > 25 && 
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={table.rowsPerPage}
                        page={table.page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                />}
                
                {rows.length <= 0 && 
                    <div className={classes.nothingWrapper}>
                        {loading && <CircularProgress size={18} />}
                        {!loading && <Typography color="textSecondary" variant="body1">No records</Typography>}
                    </div>
                }
            </Paper>
        </div>
    )

}

export default AutoTable;