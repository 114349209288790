import React, {useState, useContext, useEffect} from 'react';
import {useTheme, makeStyles, Typography, CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { localeCurrency } from '../global/helpers';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import API from '../global/api';
import { timeSince, SQLtoUTCDateObj } from '../global/dateTimeHelpers';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40,
    paddingTop: 40
  }
}));

const CustomerPaymentReminder = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const {customer} = props

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [logs, setLogs] = useState(null);

  const handleSend = () => {
    setLoading(true);
    setError(null);

    //
    // Execute an api request and get the returned result
    //
    API.req(`/customer/billing/reminder/${context.profile.data.id_organisation}`, 'POST', {id_customer: customer.id_customer}).then(
      async res => {

        setLoading(false);
        context.showSnack({message: 'Payment reminder sent!'}, 'success')

        if(typeof props.handleClose === 'function')
          props.handleClose();

      },
      err => {
          setLoading(false);
          setError(typeof err === 'string' ? err : JSON.stringify(err))
      }
    )
  }

  useEffect(() => {

    if(typeof props.customer.id_customer !== 'undefined')
      setLogs(null);
      API.req(`/customer/logs/${context.profile.data.id_organisation}`, 'GET', {id_customer: props.customer.id_customer}).then(
        async res => {

          if(res.success === true){
            const paymentReminders = res.result.filter(l => parseInt(l.id_customer_log_type) === 1)
            setLogs(paymentReminders);
          } else
            setLogs([]);

        },
        err => {
          setError('There was an error loading the customer logs');
          setLogs([]);
        }
      )

  }, [props.customer])

  return (
      <div className={classes.detailsWrapper}>

        {!customer && <Alert severity="error">Customer not found</Alert>}
        {error && <Alert severity="error">{error}</Alert>}

        {customer && 
          <React.Fragment>
            <Typography>Customer: <b>{customer.first_name} {customer.last_name}</b></Typography>
            <Typography>Email: <b>{customer.email}</b></Typography>
            <Typography>Boat: <b>{customer.boat_name}</b></Typography>
            <Typography color="primary">Amount Due: <b>{localeCurrency(Math.abs(customer.balance), customer.currency)} ({customer.currency})</b></Typography>
            <Typography style={{marginTop: 15}}>Last reminder sent: <b>{logs === null && <CircularProgress size={14} />}{(logs !== null && logs.length > 0) && timeSince(SQLtoUTCDateObj(logs[0].date))}</b></Typography>
          </React.Fragment>
        }

        <div className="flex"></div>

        <div className="flex-row-end">
          <ProgressButton waiting={loading} variant="contained" onClick={handleSend}>Confirm &amp; Send</ProgressButton>
        </div>
      </div>
  );
}

export default CustomerPaymentReminder;