import React, { useContext, useState, useEffect } from 'react';
import {useTheme, makeStyles, Typography, Button, Collapse} from '@material-ui/core';
import globalContext from '../context/globalContext';
import Images from '../assets/imgs/';
import Alert from '@material-ui/lab/Alert';
import API from '../global/api';
import ServiceSelectBerth from './serviceSelectBerth';
import ServiceSelectCustomer from './serviceSelectCustomer';
import ProgressButton from '../components/progressButton';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  boatImage: {
    width: 260,
    height: 130,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginBottom: 60
  },
}));


const ServiceBerth = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [berth, setBerth] = useState(null);
  const [ occupancy, setOccupancy ] = useState(null);
  const [ customer, setCustomer ] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  const startNewOccupancy = () => {
    setOccupancy({
      id_occupancy: null,
      id_berth: berth !== null ? berth.id_berth : null,
      id_customer: null,
      start_time: null,
      end_time: null
    })
  }


  const occpancyStatus = () => {
    if(occupancy !== null && occupancy.id_occupancy !== null)
      return 'ONGOING';
    
    if(occupancy !== null && occupancy.id_customer !== null)
      return 'SELECTED';
    
    return 'NOT_SELECTED';
  }

  const handleSetOccpancyCustomer = item => {
    let newOccupancy = JSON.parse(JSON.stringify(occupancy));
    if(item !== null)
      newOccupancy.id_customer = item.id;
    else
      newOccupancy.id_customer = null;
    setOccupancy(newOccupancy);
  }

  const validOccpancy = () => {
    if(occupancy !== null && occupancy.id_occupancy === null && occupancy.id_customer !== null && occupancy.id_berth !== null)
      return true;
    
    return false;
  }


  const handleStartOccupancy = async () => {

    setError(null);
    if(validOccpancy()){
      setLoading(true);


      // Create a new occupancy

      API.req(`/occupancy/start/${context.profile.data.id_organisation}`, 'POST', occupancy).then(
        async res => {

          API.addToLocalArray('occupancies', res.result)
          context.occupancyAdd(res.result);
          setOccupancy(res.result);
          if(typeof props.handleClose === 'function')
            props.handleClose();
          context.showSnack({message: 'Berth occupied!'}, 'success')
          setLoading(false);

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
        }
      )

    }
  }


  const handleStopOccupancy = async () => {


    setError(null);
    setLoading(true);



    API.req(`/occupancy/end/${context.profile.data.id_organisation}`, 'PUT', occupancy).then(
      async res => {

        context.occupancyUpdate(res.result);
        context.showSnack({message: 'Berth unoccupied!'}, 'success')
        setLoading(false);
        startNewOccupancy();

      },
      err => {
          setLoading(false);
          setError(typeof err === 'string' ? err : JSON.stringify(err))
      }
    )


  }


  useEffect(() => {
    
    const loadBerth = () => {
      setLoading(true);
      setError(false);
      try {
        let findBerth = context.berths.data.find(p => p.map_reference === props.data.id);
        if(typeof findBerth === 'undefined')
          findBerth = context.berths.data.find(p => parseInt(p.id_berth) === parseInt(props.data.id));

        if(typeof findBerth !== 'undefined')
          setBerth(findBerth);
        else {
          setBerth(null);
          setError('Berth not found');
        }
      } catch (error) {
        console.log(error);
        setBerth(null);
      } finally{
        setLoading(false);
      }
    }
    loadBerth();

  }, [props.data])


  useEffect(() => {

    if(berth !== null && berth.id_berth !== null){

      const loadOccupancy = () => {
        const findOccupancy = context.occupancies.data.find(o => parseInt(o.id_berth) === parseInt(berth.id_berth) && o.end_time === null);
        console.log(findOccupancy);
        if(typeof findOccupancy !== 'undefined')
          setOccupancy(findOccupancy);
        else
          startNewOccupancy()
      }
      loadOccupancy();
    }

  }, [berth])


  useEffect(() => {

    if(occupancy !== null && occupancy.id_customer !== null){
      const loadCustomer = () => {
        const findCustomer = context.customers.data.find(c => parseInt(c.id_customer) === parseInt(occupancy.id_customer));
        if(typeof findCustomer !== 'undefined')
          setCustomer(findCustomer);
      }
      loadCustomer();
    } else {
      setCustomer(null);
    }

  }, [occupancy])

  return (
    
    <div className={classes.detailsWrapper}>

      {(!loading && error) && 
        <Alert severity="error">{error}</Alert>
      }

      
      <div className={classes.form}>
          
        <div className="flex-row justify-center">
          <div className={classes.boatImage}>
            <img src={(occupancy !== null && occupancy.id_occupancy !== null) ? Images.Boat_Icons_Blue : Images.Boat_Icons_Grey} style={{width: '100%', height: 'auto'}} alt="Berth" />
          </div>
        </div>

        <Collapse in={berth === null}><ServiceSelectBerth handleSelect={setBerth} /></Collapse>

        {(berth !== null) && 
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>
              <Typography variant="h5" color="primary">Location: <b>{berth && berth.berth_reference}</b></Typography>
              <Typography color="textSecondary">Mooring Type: {berth.mooring_type}</Typography>
              <Typography style={{marginBottom: 20}} color="textSecondary">Length Over All: <b>&lt; {berth.loa_max} {berth.loa_measurement}</b></Typography>


              <Collapse in={customer !== null}>
                <Typography variant="subtitle2" color="primary">{(occupancy !== null && occupancy.id_occupancy !== null) ? 'Currently occupied by' : 'Set occupancy'}</Typography>
                <Typography>Customer: <b>{customer && customer.first_name} {customer && customer.last_name}</b></Typography>
                <Typography>Boat: <b>{customer && customer.boat_name}</b></Typography>
                <Typography>Account type: <b>{customer && customer.account_type}</b></Typography>
                {(occupancy !== null && occupancy.start_time !== null) && <Typography variant="caption" color="textSecondary">Occupied since: <b>{new Date(occupancy.start_time).toLocaleDateString(navigator.language)} {new Date(occupancy.start_time).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}</b></Typography>}
              </Collapse>

            </div>
          </div>
        }

        <Collapse in={customer === null && berth !== null}><ServiceSelectCustomer handleSelect={handleSetOccpancyCustomer} /></Collapse>
      </div>

      <div style={{flex: 1}}></div>

      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
        {occpancyStatus() === 'ONGOING' && <ProgressButton waiting={loading} variant="contained" onClick={handleStopOccupancy}>&nbsp;&nbsp;&nbsp;&nbsp;Release Berth&nbsp;&nbsp;&nbsp;&nbsp;</ProgressButton>}
        {occpancyStatus() === 'SELECTED' && <ProgressButton waiting={loading} variant="contained" disabled={!validOccpancy()} onClick={handleStartOccupancy}>&nbsp;&nbsp;&nbsp;&nbsp;Occupy Berth&nbsp;&nbsp;&nbsp;&nbsp;</ProgressButton>}
      </div>

    </div>

  );
}

export default ServiceBerth;
