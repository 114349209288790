import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme, Button, Typography, IconButton} from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import globalContext from '../context/globalContext';
import SideSlidePanel from '../components/sideSlidePanel';
import { localeCurrency } from '../global/helpers';
import images from '../assets/imgs';
import StatusChip from '../components/statusChip';
import BooleanFilterDialog from '../components/booleanFilterDialog';
import UtilityModify from '../content/utilityModify';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  vacant: {
    backgroundColor: theme.palette.vacant.main,
    color: theme.palette.vacant.contrastText
  },
  assigned: {
    backgroundColor: theme.palette.assigned.main,
    color: theme.palette.assigned.contrastText
  },
  fault: {
    backgroundColor: theme.palette.fault.main,
    color: theme.palette.fault.contrastText
  }
}))

const UtilitiesOverview = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const context = useContext(globalContext);

  const [ data, setData ] = useState([]);
  const [ edit, setEdit ] = useState(null);

  const headCells = [
    'identity',
    'utility_type',
    'utility_description',
    'service_status',
    'network_status',
    'pedestal_reference',
    'tariff'
  ]

  const [filters, setFilters] = useState({
    vacant: true,
    assigned: true,
    fault: true
  })
  const [showFilter, setShowFilter] = useState(false);

  const handleFilterClose = (newFilters) => {
    setFilters(newFilters);
    setShowFilter(false);
  }

  const handleRowClick = row => {
    setEdit(row);
  }

  useEffect(() => {
    let newData = [];
    context.utilities.data.forEach(u => {
      if((u.service_status === 'vacant' && filters.vacant === false) || (u.service_status === 'assigned' && filters.assigned === false) || (u.service_status === 'fault' && filters.fault === false))
        return;  

      const tariff = context.tariffs.data.find(t => parseInt(t.id_tariff) === parseInt(u.id_tariff));
      const pedestal = context.pedestals.data.find(p => parseInt(p.id_pedestal) === parseInt(u.id_pedestal));
      newData.push({
        ...u,
        tariff: typeof tariff !== 'undefined' ? `${tariff.tariff_name} - (${localeCurrency(parseFloat(tariff.rate), tariff.currency)})` : '',
        pedestal_reference: typeof pedestal !== 'undefined' ? pedestal.pedestal_reference : ''
      })
    })
    setData(newData);
  }, [context.utilities, filters])

  return (
    <div className={classes.container}>

      <PageToolbar label="Utilities Overview"><IconButton onClick={() => setShowFilter(true)}><TuneIcon /></IconButton></PageToolbar>


      <AutoTable 
        rows={data} 
        orderBy="identity" 
        idCol="id_utility" 
        handleRowClick={handleRowClick} 
        headCells={headCells}
        cellReplacementProps={{
          'utility_type': (val, id) => (
            <div className="flex-row-start gap-10"><img src={val === 'Water' ? images.Icons_Water_Icon : images.Icons_Electric_Icon} style={{height: 24}} alt={val} />{val}</div>
          ),
          'service_status': (val, id) => (
            <StatusChip status={val} id={id} />
          )
        }}
        loading={context.utilities.status === 'LOADING'}
      />

      <BooleanFilterDialog show={showFilter} handleClose={handleFilterClose} items={filters} title={<Typography variant="h5" className="flex-row-start"><TuneIcon />Filter</Typography>} />
      <SideSlidePanel title="Utility" side="right" show={edit !== null} handleClose={() => {setEdit(null)}}><UtilityModify utility={edit} handleClose={() => {setEdit(null)}} /></SideSlidePanel>
    </div>
  );
}

export default UtilitiesOverview;
