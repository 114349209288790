import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme, Button} from '@material-ui/core';
import PageToolbar from '../components/pageToolbar';
import AutoTable from '../components/autoTable';
import globalContext from '../context/globalContext';
import UtilityAdd from '../content/utilityAdd';
import SideSlidePanel from '../components/sideSlidePanel';
import UtilityEdit from '../content/utilityEdit';
import { localeCurrency } from '../global/helpers';
import images from '../assets/imgs';
import StatusChip from '../components/statusChip';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  vacant: {
    backgroundColor: theme.palette.vacant.main,
    color: theme.palette.vacant.contrastText
  },
  assigned: {
    backgroundColor: theme.palette.assigned.main,
    color: theme.palette.assigned.contrastText
  },
  fault: {
    backgroundColor: theme.palette.fault.main,
    color: theme.palette.fault.contrastText
  }
}))

const Utilities = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const context = useContext(globalContext);

  const [ data, setData ] = useState([]);
  const [ showAdd, setShowAdd ] = useState(false);
  const [ edit, setEdit ] = useState(null);

  const headCells = [
    'id_utility',
    'identity',
    'pedestal_reference',
    'utility_type',
    'utility_description',
    'network_status',
    'service_status',
    'tariff'
  ]

  const handleRowClick = row => {
    setEdit(row);
  }

  useEffect(() => {
    let newData = [];
    context.utilities.data.forEach(u => {
      const tariff = context.tariffs.data.find(t => parseInt(t.id_tariff) === parseInt(u.id_tariff));
      const pedestal = context.pedestals.data.find(p => parseInt(p.id_pedestal) === parseInt(u.id_pedestal));
      newData.push({
        ...u,
        tariff: typeof tariff !== 'undefined' ? `${tariff.tariff_name} - (${localeCurrency(parseFloat(tariff.rate), tariff.currency)})` : '',
        pedestal_reference: typeof pedestal !== 'undefined' ? pedestal.pedestal_reference : ''
      })
    })
    setData(newData);
  }, [context.utilities])

  return (
    <div className={classes.container}>

      <PageToolbar label="Utilities">
        <div style={{flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20}}>
          <div>
            <Button variant="contained" color="default" style={{alignSelf: 'flex-start'}} onClick={() => setShowAdd(true)}>Add Utility</Button>
          </div>
        </div>
      </PageToolbar>


      <AutoTable 
        rows={data} 
        orderBy="id_utility" 
        idCol="id_utility" 
        handleRowClick={handleRowClick} 
        headCells={headCells}
        cellReplacementProps={{
          'utility_type': (val, id) => (
            <div className="flex-row-start gap-10"><img src={val === 'Water' ? images.Icons_Water_Icon : images.Icons_Electric_Icon} style={{height: 24}} alt={val} />{val}</div>
          ),
          'service_status': (val, id) => (
            <StatusChip status={val} id={id} />
          )
        }}
        loading={context.utilities.status === 'LOADING'}
      />

      
      <SideSlidePanel title="Add Utility" side="left" show={showAdd} handleClose={() => {setShowAdd(false)}}><UtilityAdd handleClose={() => {setShowAdd(false)}} /></SideSlidePanel>
      <SideSlidePanel title="Edit Utility" side="right" show={edit !== null} handleClose={() => {setEdit(null)}}><UtilityEdit data={edit} handleClose={() => {setEdit(null)}} /></SideSlidePanel>
      

    </div>
  );
}

export default Utilities;
