import React, { useContext } from 'react';
import {makeStyles, useTheme, Typography, Tooltip} from '@material-ui/core';
import Images from '../assets/imgs/index.js';
import StatusChip from './statusChip';
import globalContext from '../context/globalContext.js';
import { fixedLengthNumber } from '../global/helpers';
import clsx from 'clsx';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PowerIcon from '@material-ui/icons/Power';
import images from '../assets/imgs/index.js';

const useStyles = makeStyles(theme => ({
  utility: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '3px 10px ',
    cursor: 'pointer',
    gap: '20px',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  selected: {
    backgroundColor: theme.palette.grey[200]
  },
  utilityIcon: {
    height: 32,
    width: 'auto'
  },
  icon: {
    fontSize: 22,
    color: '#dddddd'
  },
  iconOn: {
    color: theme.palette.success.main
  },
  reserved: {
    color: theme.palette.reserved.main
  },
  imageIcon: {
    height: 22,
    width: 'auto'
  },
}))

const UtilitySnippet = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const handleClick = () => {
      if(typeof props.handleClick === 'function')
        props.handleClick(props.utility);
  }

  const isReserved = u => {
    const findScheduledServices = context.services.data.filter(s => s.id_utility === u.id_utility && new Date(s.start_time) > new Date());
    return findScheduledServices.length > 0 ? true : false
  }

  
  const service = context.services.data.find(s => parseInt(s.id_utility) === parseInt(props.utility.id_utility) && s.end_time === null && s.id_session !== null);
  

  return (
    <div className={clsx([classes.utility, props.isSelected ? classes.selected : {}])} onClick={handleClick}>
      {props.utility.utility_type && <div className={classes.utilityIcon}><img src={props.utility.utility_type.toUpperCase() === 'WATER' ? Images.Icons_Water_Icon : Images.Icons_Electric_Icon} height="32" width="auto" alt={props.utility.utility_type} /></div>}
      <Typography style={{flex: .8}}>{props.utility.identity !== null ? props.utility.identity : '#' + fixedLengthNumber(props.utility.id_utility,4)}</Typography>
      <Tooltip title={isReserved(props.utility) ? 'Service Reserved' : 'No reservation'} ><AccessTimeIcon className={clsx([classes.icon, isReserved(props.utility) && classes.reserved])} /></Tooltip>
      <StatusChip status={props.utility.service_status} id={props.utility.id_utility} />
      <div className="flex flex-row-start gap-10">

          {(props.utility.utility_type.toUpperCase() === 'ELECTRICITY' && (typeof service === 'undefined' || Boolean(service.paused))) && <Tooltip title="Service Off"><img src={images.Icons_Electric_Fill_Grey_Icon} alt="Service Off" className={classes.imageIcon} /></Tooltip>}
          {(props.utility.utility_type.toUpperCase() === 'ELECTRICITY' && (typeof service !== 'undefined' && !Boolean(service.paused))) && <Tooltip title="Service On"><img src={images.Icons_Electric_Fill_Green_Icon} alt="Service On" className={classes.imageIcon} /></Tooltip>}

          {(props.utility.utility_type.toUpperCase() === 'WATER' && (typeof service === 'undefined' || Boolean(service.paused))) && <Tooltip title="Service Off"><img src={images.Icons_Water_Fill_Grey_Icon} alt="Service Off" className={classes.imageIcon} /></Tooltip>}
          {(props.utility.utility_type.toUpperCase() === 'WATER' && (typeof service !== 'undefined' && !Boolean(service.paused))) && <Tooltip title="Service On"><img src={images.Icons_Water_Fill_Green_Icon} alt="Service On" className={classes.imageIcon} /></Tooltip>}
          
          {(props.utility.utility_type.toUpperCase() === 'ELECTRICITY' && props.utility.connected !== null) && <Tooltip title={`${!props.utility.connected ? 'Not ' : ''}Plugged in`}><PowerIcon className={clsx([classes.icon, props.utility.connected && classes.iconOn])}/></Tooltip>}

          {(props.utility.utility_type.toUpperCase() === 'ELECTRICITY' && props.utility.breakerOk !== null && props.utility.breakerOk) && <Tooltip title="Breaker OK"><img src={images.Icons_ElectricOff_Grey_Icon} alt="" className={classes.imageIcon} /></Tooltip>}
          {(props.utility.utility_type.toUpperCase() === 'ELECTRICITY' && props.utility.breakerOk !== null && !props.utility.breakerOk) && <Tooltip title="Breaker Tripped"><img src={images.Icons_ElectricOff_Red_Icon} alt="" className={classes.imageIcon} /></Tooltip>}


      </div>
    </div>
  );
}

export default UtilitySnippet;