import Api from '../global/api';

export function profileLoad () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'PROFILE_LOAD',
            status: 'LOADING',
            payload: null
        })

        let active_profile = await Api.getLocal('_bvap');
        if(active_profile !== null){
            Api.req(`/organisation/${active_profile}`, 'GET').then(
                res => {

                    Api.setLocal('profile', res.result);

                    dispatch({
                        type: 'PROFILE_LOAD',
                        status: 'OK',
                        payload: res.result
                    })
                },
                err => {
                    console.log('An error occured fetching profile')
                    // Login error
                    dispatch({
                        type: 'PROFILE_LOAD',
                        status: 'ERROR',
                        payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your profile'
                    })
                }
            )
        }
        

    }
}

export function profileUpdate (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'PROFILE_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}