import React, { useContext, useEffect, useState } from 'react';
import globalContext from '../context/globalContext';
import Skeleton from '@material-ui/lab/Skeleton';
import UtilitySnippet from '../components/utilitySnippet';

const ServicePedestalUtilities = props => {

    const context = useContext(globalContext);
    const [loading, setLoading] = useState(false);
    const [utilities, setUtilities] = useState([]);

    useEffect(() => {

        const loadUtilities = () => {
            setLoading(true);
            try {
                let findUtilities = context.utilities.data.filter(u => parseInt(u.id_pedestal) === parseInt(props.id_pedestal));
                setUtilities(JSON.parse(JSON.stringify(findUtilities)));
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        loadUtilities();

    }, [props.id_pedestal, context.utilities])


    const handleSelectUtility = u => {
        if(typeof props.handleSelectUtility === 'function')
            props.handleSelectUtility(u);
    } 

    const isSelected = u => {
        if(typeof props.selected !== 'undefined' && props.selected === u.id_utility)
            return true;

        return false;
    }

    return (
        <React.Fragment>
            {loading && 
                <React.Fragment>
                    {[1,2,3,4].map((i) => (
                        <Skeleton key={i} animation="wave" variant="rect" height={30} style={{marginBottom: 10}} />
                    ))}
                </React.Fragment>
            }
            {!loading &&
                utilities.map((u, idx) => (
                    <UtilitySnippet utility={u} key={idx} handleClick={handleSelectUtility} isSelected={isSelected(u)} />
                ))
            }
        </React.Fragment>
    )
}

export default ServicePedestalUtilities;