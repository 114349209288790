import { Button, CircularProgress, Collapse, List, ListItem, ListItemText, Typography, useTheme } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react'
import InputField from '../components/inputField';
import globalContext from '../context/globalContext'
import {makeValidation, isValidForm} from '../global/formHelper';
import SideSlidePanel from '../components/sideSlidePanel';
import CustomerAdd from './customerAdd';

const ServiceSelectCustomer = props => {

    const context = useContext(globalContext);
    const theme = useTheme();

    const [ loading , setLoading ] = useState(false);
    const [ showResults, setShowResults ] = useState(false);
    const [ results, setResults ] = useState([]);

    const [ addCustomer, setAddCustomer ] = useState(false);

    const [form, setForm] = useState({
        search: ''
    });

    const [validation, setValidation] = useState(makeValidation([
        {
            name: 'search',
            display_name: 'Select a customer',
            required: false
        }
    ]));

    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleInputClear = () => {
        let newForm = {...form};
        newForm.search = '';
        setForm(newForm);
    }

    const handleSubmit = async () => {

        setLoading(false);

        if(form.search === ''){
            setResults([]);
            setShowResults(false);
        } else {
            
            const valid = isValidForm(form, validation);
            setValidation(valid.validation);
            if(valid.isValid){
                setLoading(true);

                try {

                    let arr = [];
                    context.customers.data.forEach(c => {
                        let idxs = []
                        idxs.push(c.id_customer.toString().indexOf(form.search));
                        idxs.push(c.your_ref.toString().indexOf(form.search));
                        idxs.push(`${c.first_name} ${c.last_name}`.toUpperCase().indexOf(form.search.toUpperCase()));
                        idxs.push(c.email.toUpperCase().indexOf(form.search.toUpperCase()));
                        idxs.push(c.boat_name.toUpperCase().indexOf(form.search.toUpperCase()));
                        idxs = idxs.filter(i => i > -1);
                        if(idxs.length > 0)
                            arr.push({
                                idx: Math.min(...idxs),
                                id: c.id_customer,
                                type: 'CUSTOMER',
                                reference: `${c.first_name} ${c.last_name}`,
                                description: c.boat_name
                            })
                    })

                    arr = arr.sort((a, b) => {
                        return a.idx < b.idx ? -1 : 1;
                    })

                    setResults(arr);

                } catch (error) {

                    setResults(null);
                    console.log(error);
                    
                } finally {

                    setShowResults(true);
                    setLoading(false);

                }
            }

        }

    }

    const handleSelect = item => {
        if(typeof props.handleSelect === 'function')
            props.handleSelect(item);
    }

    const handleAddNew = customer => {
        setAddCustomer(false);
        if(typeof props.handleSelect === 'function')
            props.handleSelect({id: customer.id_customer});
    }


    useEffect(() => {
        handleSubmit();
    }, [form])


    return (
        <div>
            <InputField 
                name='search' 
                validation={validation.search} 
                value={form.search} 
                handleChange={handleInputChange} 
                handleClear={handleInputClear}
                hoverLabel={true} 
                placeholder='Enter a customer name or boat name'
                loading={loading}
            />
            <Collapse in={showResults} style={{maxHeight: 150, overflowY: 'scroll'}}>
                {loading && <CircularProgress size="18" color="primary" />}
                {(results.length === 0 && loading === false) && <Typography variant="caption" color="textSecondary">No results found</Typography>}
                {(results.length > 0 && loading === false) && 
                    <List dense={true}>
                        {results.map((r, idx) => (
                            <ListItem button key={idx} onClick={() => handleSelect(r)}><ListItemText primary={r.reference} secondary={r.description} /></ListItem>
                        ))}
                    </List>
                }
            </Collapse>
            <div className="flex-row-end">
                <Button onClick={() => setAddCustomer(true)}>Add new</Button>
            </div>

            <SideSlidePanel show={addCustomer} handleClose={() => setAddCustomer(false)} title='Add a customer'><CustomerAdd handleSuccess={handleAddNew} global={false} /></SideSlidePanel>
        </div>
    )

}

export default ServiceSelectCustomer;