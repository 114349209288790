import React, { useEffect, useState } from 'react';
import BooleanSwitch from '../components/booleanSwitch';
import { Collapse, FormControl, FormControlLabel } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { toDateTimeNoSecs } from '../global/dateTimeHelpers';

const ServiceUtilitySelectStart = props => {

    const [ startNow, setStartNow ] = useState(true);
    const [ selectedDate, setSelectedDate ] = useState(new Date());

    const handleToggleStart = e => {
      setStartNow(e.target.checked);
      if(e.target.checked && typeof props.handleSetStartTime === 'function')
        props.handleSetStartTime(null);
    }

    const handleDateChange = (date) => {
      setSelectedDate(toDateTimeNoSecs(date));
      if(typeof props.handleSetStartTime === 'function')
        props.handleSetStartTime(toDateTimeNoSecs(date));
    };
    
    return (
        <div style={{marginTop: 20, marginBottom: 20}}>
          
            <FormControl component="fieldset">
              <FormControlLabel
                control={<BooleanSwitch checked={startNow} name="enableUtilities" handleSwitchChange={handleToggleStart} />}
                label="Assign the service immediately"
              />
            </FormControl>

            <Collapse in={!startNow}>
              <div className="flex-row">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Start Date"
                    format="dd/MM/yyyy"
                    inputVariant="filled"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Start Time"
                    inputVariant="filled"
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Collapse>
          </div>
    )

}

export default ServiceUtilitySelectStart;