import React, { useState, useEffect, useContext, useRef } from 'react';
import {useTheme, makeStyles, Button, Collapse} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm, resetValidation} from '../global/formHelper';
import globalContext from '../context/globalContext';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';

const useStyles = makeStyles((theme) => ({
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  bottomButton: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0
  },
}));

const CustomerRfidAdd = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ show, setShow ] = useState(false);

  const input = useRef(null);

  const [ form, setForm ] = useState({
    id_customer: props.customer.id_customer,
    id_tag: ''
  });

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'id_tag',
      display_name: 'Tag ID',
      required: true
    }
  ]));

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value.toUpperCase();
    setForm(newForm);
  }

  const resetForm = () => {
    setForm({
      id_customer: props.customer.id_customer,
      id_tag: ''
    })

    setValidation(resetValidation(validation))
  }

  const handleSubmit = async () => {

    setError(null);
    setLoading(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){


      //
      // Execute an api request and get the returned result
      //
      API.req(`/customer/rfid/${context.profile.data.id_organisation}`, 'POST', form).then(
        async res => {
          setLoading(false);

          if(typeof props.handleSuccess === 'function')
            props.handleSuccess(res.result);

          context.showSnack({message: 'RFID added!'}, 'success')
          resetForm();
          
          if(input.current !== null)
            input.current.focus();

        },
        err => {
            setLoading(false);
            setError(typeof err === 'string' ? err : JSON.stringify(err))
            resetForm();
        }
      )

    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter')
        handleSubmit();
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  const handleShowAdd = () => {
    setShow(true);
    setTimeout(() => {
      if(input.current !== null)
        input.current.focus();
    }, 200)
  }

  return (
    <React.Fragment>

        <div className={classes.form}>

          <div className="flex-row-end">
            <Button variant="contained" classes={{contained: classes.bottomButton}} onClick={handleShowAdd}>Add New Card</Button>
          </div>

          <Collapse in={show} style={{marginTop: 20}}>
            {error && <Alert severity="error">{error}</Alert>}
            <div className="flex-row align-center">
              <InputField inputRef={input} name='id_tag' validation={validation.id_tag} value={form.id_tag} handleChange={handleInputChange} hoverLabel={true} autoFocus={true} handleKeyDown={handleKeyDown} />
              <ProgressButton waiting={loading} variant="contained" onClick={handleSubmit}>Add</ProgressButton>
            </div>
          </Collapse>

        </div>

    </React.Fragment>
  );
}

export default CustomerRfidAdd;
