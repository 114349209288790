import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import './styles.css';
import {makeStyles, useTheme, Typography, Link, Button, CircularProgress} from '@material-ui/core';
import { login, userValidate, register } from '../../actions/userActions';
import { makeValidation, validateForm, isValidForm } from '../../global/formHelper';

import Images from '../../assets/imgs/index';
import InputField from '../../components/inputField';
import ProgressButton from '../../components/progressButton';

import background from "../../assets/imgs/Login_Screen.png";
import { useParams } from 'react-router';

import Register from './register';
import ForgotPassword from './forgotPassword';

const useStyles = makeStyles(theme => ({
  signinContainer: {
    width: '80%',
    maxWidth: 500,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '10%'
  },
  form: {
    marginBottom: 10
  },
  footer: {
    marginTop: 40
  },
  supportingLogo: {
    marginTop: '15%',
    width: '80%',
    maxWidth: 600,
    height: 'auto'
  },
  supportLogoImg: {
    width: '100%',
    height: 'auto'
  },
  verifyingLoader: {
    width: '80%',
    maxWidth: 600,
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  welcome: {
    fontWeight: 300,
    fontSize: '1.6rem',
    textAlign: 'center'
  },
  title: {
    fontWeight: 600,
    fontSize: '1.6rem',
    textAlign: 'center',
    marginBottom: 30
  },
  getStartedWrapper: {
    backgroundColor: theme.palette.success.dark,
    padding: theme.spacing(2),
    marginBottom: 20,
    borderRadius: 3
  },
  getStarted: {
    fontWeight: 400,
    fontSize: '1rem',
    textAlign: 'center',
    marginBottom: 0,
    color: theme.palette.success.contrastText
  }
}))


const Signin = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const {token} = useParams();

  const [mode, setMode] = useState('SIGNIN');

  const [form, setForm] = useState({
    username: '',
    password: '',
    token: token
  })

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'username',
      display_name: 'Username',
      required: true
    },
    {
      name: 'password',
      display_name: 'Password',
      required: true
    }
  ]));

  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  useEffect(() => {
    setWaiting((props.user.status === 'loading'));
  }, [props.user])

  useEffect(() => {
    props.userValidate();
  }, [])

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = () => {
    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid)
      props.login(form);
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter')
        handleSubmit();
  }

  const handleClickRegister = () => {
    if(mode === 'SIGNIN')
      setMode('REGISTER');
  }


  return (
    <div className="container">

      {/* The image that displays on larger screens */}
      <div className="panel-left" style={{backgroundColor: theme.palette.primary.main,  backgroundImage: `url(${background})` }}>
        <div className={classes.supportingLogo}><img className={classes.supportingLogoImg} src={Images.logo_white_with_sub} alt="BerthVend" /></div>
        {props.user.verifying && <div className={classes.verifyingLoader}><CircularProgress style={{color: '#ffffff'}} /></div>}
      </div>

      {/* The actual content */}
      {!props.user.verifying && <div className="panel-right">


        <div className={classes.signinContainer}>
          
          <div style={{flex: 1}}></div>
          
          {/* Sign in form */}
          <div>

            <Typography variant="h4" className={classes.welcome} gutterBottom={false}>Welcome to</Typography>
            <Typography variant="h4" className={classes.title} gutterBottom={true}>BerthVend Back-Office</Typography>

            {typeof form.token !== 'undefined' && 
              <div className={classes.getStartedWrapper}>
                <Typography variant="h6" gutterBottom={false} className={classes.getStarted}>To get started, sign in with an existing account or press create new.</Typography>
              </div>
            }
            <Typography variant='caption' color='error'>{props.user.msg}</Typography>

            {mode === 'SIGNIN' && 
              <React.Fragment>
                <div className={classes.form}>
                  <InputField name='username' size='large' validation={validation.username} value={form.username} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
                  <InputField name='password' size='large' type='password' validation={validation.password} value={form.password} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
                  <Typography variant="caption" align="right" display="block">Forgotten your password? <Link onClick={() => setMode('FORGOT')} style={{cursor: 'pointer'}}>Reset</Link></Typography>
                </div>

                <div className={classes.footer}>
                  <div className="flex-row">
                    {typeof form.token !== 'undefined' && <Button onClick={handleClickRegister} fullWidth color="default" variant="contained" size="large">Create New</Button>}
                    {mode === 'SIGNIN' && <ProgressButton variant="contained" size="large" color="primary" fullWidth waiting={waiting} onClick={handleSubmit} gutterBottom={true}>Sign in</ProgressButton>}
                  </div>
                  <Typography variant="caption" align="right" display="block"><Link href='mailto:support@berthvend.com'>App Support</Link></Typography>
                </div>
              </React.Fragment>
            }

            {mode === 'REGISTER' && 
              <Register handleBack={() => setMode('SIGNIN')} user={props.user} handleRegister={props.register} />
            }

            {mode === 'FORGOT' && 
              <ForgotPassword handleBack={() => setMode('SIGNIN')} />
            }

          </div>

          <div style={{flex: 1}}></div>
        </div>

      </div>}

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (fields) => {
            dispatch(login(fields));
        },
        userValidate: () => {
            dispatch(userValidate());
        },
        register: (fields) => {
          dispatch(register(fields));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
