import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, Button, CircularProgress } from '@material-ui/core';
import globalContext from '../context/globalContext';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {
        paddingTop: 15
    },
    linkLogo: {
        fontSize: 100,
        color: theme.palette.primary.main
    },
    iconApp: {
        fontSize: 22,
        marginRight: 5,
        marginLeft: 5,
        marginBottom: -5,
        color: theme.palette.primary.main
    }
}));

const CustomerLinkApp = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);


    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);

    const [customer, setCustomer] = useState(null);

    

    const handleNewCode = async () => {

        try {

            setError(null);
            setLoading(true);

            
            //
            // Execute an api request and get the returned result
            //
            const res = await API.req(`/customer/link/generate/${context.profile.data.id_organisation}`, 'POST', {id_customer: customer.id_customer})
            if(res.success){
                context.showSnack({message: 'New code generated!'}, 'success')
                context.customerUpdate(res.result);
                setCustomer(res.result);
            }
            
        } catch (error) {

            setError(error.msg);
            
        } finally {

            setLoading(false);
        }


    }

    

    const handleSendCode = async () => {

        try {

            setError(null);
            setSending(true);

            
            //
            // Execute an api request and get the returned result
            //
            const res = await API.req(`/customer/link/send/${context.profile.data.id_organisation}`, 'POST', {id_customer: customer.id_customer})
            if(res.success){
                context.showSnack({message: `Code sent to ${customer.email}!`}, 'success');
            }
            
        } catch (error) {

            setError(error.msg);
            
        } finally {

            setSending(false);
        }


    }

    useEffect(() => {
        if(typeof props.customer !== 'undefined' && props.customer !== null){
            const findCustomer = context.customers.data.find(c => c.id_customer === props.customer.id_customer);
            if(typeof findCustomer !== 'undefined' && findCustomer !== null)
                setCustomer(findCustomer);
        }  
    }, [props.customer])

    return (
        <React.Fragment>
            {customer && 
            <div className={classes.container}>
                <div className={classes.inner}>
                    {error && <Alert severity="error">{error}</Alert>}
                    <Typography variant="body2" className="mt">Invite your customers to manage their assigned services via the Customer Dock self-service web area. Customers can start and stop their utilities on demand as well as monitor their account balance.</Typography>
                    <div className="flex-col justify-center align-center pt pb">
                        <AllInclusiveIcon className={classes.linkLogo} />
                        <Typography variant="subtitle2">Your unique dock code is</Typography>
                        <Typography variant="h1">{customer.app_link_code}</Typography>
                        <Typography variant="body2" align="center">Go to the Customer Dock, enter the code above to monitor your marina account.</Typography>
                        <div className="flex-row gap-20 pt">
                            <Button variant="outlined" color="default" disabled={loading} onClick={handleNewCode}>Generate New Code {loading && <CircularProgress size={24} color="inherit" style={{marginLeft: 10}} />}</Button>
                            <Button variant="contained" color="default" disabled={sending} onClick={handleSendCode}>Email Code {sending && <CircularProgress size={24} color="inherit" style={{marginLeft: 10}} />}</Button>
                        </div>
                        <Typography variant="body2">Email invites will be sent to <b>{customer.email}</b></Typography>
                    </div>
                </div>
            </div>}
        </React.Fragment>
    )
}
export default CustomerLinkApp;